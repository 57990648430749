import { defineStore } from "pinia";
import {
  License,
  LicenseLoadingFlags,
  LicenseManagedBy,
  LicenseStatus,
} from "@/models/license-management";
import { ref, reactive } from "vue";
import { forwarderTruckersClient } from "@/services/client/configs/services";
import {
  isLicense,
  isLicenseOrderId,
  updateLicenses,
} from "@/utils/license-management/license-utils";
import { LicenseAssignRequestTOActionEnum } from "@/services/client/generated";

export const useLicenseManagementStore = defineStore(
  "licenseManagementStore",
  () => {
    const licenses = ref<License[] | null>(null);
    const loadingFlags = reactive<LicenseLoadingFlags>({
      fetchLicenses: false,
    });

    function clearLicenses() {
      licenses.value = null;
    }

    async function fetchLicenses(payload: {
      userId: string;
      forwarderId: number;
      searchTerm?: string;
      status?: LicenseStatus[];
      managedBy?: LicenseManagedBy[];
      page?: number;
      expiresBefore?: string;
    }) {
      try {
        if (payload.page === 0) licenses.value = null;
        loadingFlags.fetchLicenses = true;
        const response = await forwarderTruckersClient.getLicensesForForwarder(
          payload.userId,
          payload.forwarderId,
          {
            search: payload.searchTerm,
            expirationDate: payload.expiresBefore,
            licenseStatuses: payload.status,
            managedBy: payload.managedBy,
          },
          payload.page,
          50,
        );
        const result = response.data;
        if (payload.page && payload.page > 0) {
          licenses.value = [...(licenses.value || []), ...result];
        } else {
          licenses.value = result;
        }
        return result;
      } finally {
        loadingFlags.fetchLicenses = false;
      }
    }
    async function claimLicenses({
      key,
      forwarderId,
      userId,
      companyEmail,
    }: {
      key: string;
      userId: string;
      forwarderId: number;
      companyEmail: string;
    }) {
      if (isLicenseOrderId(key)) {
        const result = await forwarderTruckersClient.claimOrder(
          userId,
          forwarderId,
          {
            orderId: key,
            licenseCompanyEmail: companyEmail,
          },
        );
        return result.data;
      } else if (isLicense(key)) {
        const result = await forwarderTruckersClient.claimLicense(
          userId,
          forwarderId,
          {
            licenseKey: key,
            licenseCompanyEmail: companyEmail,
          },
        );
        return [result.data];
      }
    }

    async function triggerLicenseAction(payload: {
      userId: string;
      forwarderId: number;
      licenseId: number;
      truckerId?: string;
      action: LicenseAssignRequestTOActionEnum;
    }) {
      const response = await forwarderTruckersClient.changeLicenseStatus(
        payload.userId,
        payload.forwarderId,
        payload.licenseId,
        {
          licenseUserId: payload.truckerId,
          action: payload.action,
        },
      );

      licenses.value = updateLicenses(licenses.value, response.data) ?? null;
    }

    async function takeoverLicense(payload: {
      userId: string;
      forwarderId: number;
      licenseId: number;
    }) {
      const response = await forwarderTruckersClient.takeoverLicense(
        payload.userId,
        payload.forwarderId,
        payload.licenseId,
      );
      licenses.value = updateLicenses(licenses.value, response.data) ?? null;
    }

    async function paySetupFee(payload: {
      userId: string;
      forwarderId: number;
      licenseKey: string;
      successUrl: string;
    }) {
      const response = await forwarderTruckersClient.stripeSetupFeeCheckout(
        payload.userId,
        payload.forwarderId,
        {
          quantity: 1,
          licenseKey: payload.licenseKey,
          successUrl: payload.successUrl,
        },
      );

      return response.data;
    }

    async function stopRenewal(payload: {
      userId: string;
      forwarderId: number;
      licenseId: number;
    }) {
      const response = await forwarderTruckersClient.stopRenewalForLicense(
        payload.userId,
        payload.forwarderId,
        payload.licenseId,
      );
      licenses.value = updateLicenses(licenses.value, response.data) ?? null;
    }

    return {
      licenses,
      loadingFlags,
      fetchLicenses,
      claimLicenses,
      triggerLicenseAction,
      takeoverLicense,
      paySetupFee,
      stopRenewal,
      clearLicenses,
    };
  },
);

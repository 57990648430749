import {
  webappUserAdminClient,
  webappUserRoleDimensionClient,
} from "@/services/client/configs/services";
import {
  CreateWebappUserRequestTO,
  UpdateWebappUserRequestTO,
  UpdateWebappUserRequestTOFacilityOperatorRoleEnum,
  UpdateWebappUserRequestTOTrainOperatorRoleEnum,
  UpdateWebappUserRequestTOTruckingCompanyRoleEnum,
} from "@/services/client/generated";
import snackbarType from "@/store/interfaces/snackbar-type";
import SnackbarType from "@/store/interfaces/snackbar-type";
import { useAuthStore } from "@/store/useAuthStore";
import { useSnackbarStore } from "@/store/useSnackbarStore";
import { useWebAppUserStore } from "@/store/useWebAppUserStore";
import { getErrorByTypeOrDefault } from "@/utils/error-utils";
import { ref } from "vue";
import { useI18n } from "vue-i18n";

export function useWebappUserLogic() {
  const snackBarStore = useSnackbarStore();
  const authStore = useAuthStore();
  const { updateWebappUsers, updateWebappUser, addWebappUser, updateModules } =
    useWebAppUserStore();
  const { t } = useI18n();
  const loading = ref(false);

  const formattedTruckingCompanyRoles = [
    {
      text: "None",
      value: UpdateWebappUserRequestTOTruckingCompanyRoleEnum.None,
    },
    {
      text: "Read",
      value: UpdateWebappUserRequestTOTruckingCompanyRoleEnum.ForwarderRead,
    },
    {
      text: "Write",
      value: UpdateWebappUserRequestTOTruckingCompanyRoleEnum.ForwarderWrite,
    },
  ];

  const formattedFacilityOperatorRoles = [
    {
      text: "None",
      value: UpdateWebappUserRequestTOFacilityOperatorRoleEnum.None,
    },
    {
      text: "Read",
      value:
        UpdateWebappUserRequestTOFacilityOperatorRoleEnum.TerminalOperationsRead,
    },
    {
      text: "Write",
      value:
        UpdateWebappUserRequestTOFacilityOperatorRoleEnum.TerminalOperationsWrite,
    },
    {
      text: "Admin",
      value:
        UpdateWebappUserRequestTOFacilityOperatorRoleEnum.TerminalOperationsAdmin,
    },
  ];

  const formattedTrainOperatorRoles = [
    {
      text: "None",
      value: UpdateWebappUserRequestTOTrainOperatorRoleEnum.None,
    },
    {
      text: "Read",
      value: UpdateWebappUserRequestTOTrainOperatorRoleEnum.TrainOperatorRead,
    },
    {
      text: "Write",
      value: UpdateWebappUserRequestTOTrainOperatorRoleEnum.TrainOperatorWrite,
    },
  ];

  async function fetchWebappUsers() {
    const user = authStore.authUser;
    if (!user) {
      snackBarStore.showSnackbar({
        text: t("auth/user-not-found"),
        snackbarType: snackbarType.ERROR,
      });
      return;
    }

    try {
      loading.value = true;
      const { data: webappUsers } =
        await webappUserAdminClient.getAllWebappUsers(user.userId);

      updateWebappUsers(webappUsers);
    } catch (e) {
      const errorText = t(getErrorByTypeOrDefault(e));
      snackBarStore.showSnackbar({
        text: errorText,
        snackbarType: snackbarType.ERROR,
      });
      throw e;
    } finally {
      loading.value = false;
    }
  }

  async function createWebappUser(newUser: CreateWebappUserRequestTO) {
    const user = authStore.authUser;
    if (!user) {
      snackBarStore.showSnackbar({
        text: t("auth/user-not-found"),
        snackbarType: snackbarType.ERROR,
      });
      return;
    }

    try {
      loading.value = true;
      const { data: addedUser } = await webappUserAdminClient.createWebappUser(
        user.userId,
        newUser,
      );

      addWebappUser(addedUser);

      snackBarStore.showSnackbar({
        text: t("userManagement.webapp_created_successfully"),
        snackbarType: SnackbarType.SUCCESS,
      });
      return null;
    } catch (e) {
      const errorType = getErrorByTypeOrDefault(e);
      if (errorType === "user_already_exists") {
        return "user_already_exists";
      }

      const errorText = t(`userManagement.${errorType}`);
      snackBarStore.showSnackbar({
        text: errorText,
        snackbarType: SnackbarType.ERROR,
      });

      return errorType;
    } finally {
      loading.value = false;
    }
  }

  async function patchWebappUser(
    selectedUserId: string,
    updatedUser: UpdateWebappUserRequestTO,
  ) {
    const user = authStore.authUser;
    if (!user) {
      snackBarStore.showSnackbar({
        text: t("auth/user-not-found"),
        snackbarType: snackbarType.ERROR,
      });
      return;
    }

    try {
      loading.value = true;
      const { data: updatedUserResponse } =
        await webappUserAdminClient.putWebappUser(
          user.userId,
          selectedUserId,
          updatedUser,
        );

      updateWebappUser(updatedUserResponse);

      snackBarStore.showSnackbar({
        text: t("userManagement.user_updated_successfully"),
        snackbarType: snackbarType.SUCCESS,
      });

      return updatedUserResponse;
    } catch (e) {
      const errorText = t(getErrorByTypeOrDefault(e));
      snackBarStore.showSnackbar({
        text: errorText,
        snackbarType: snackbarType.ERROR,
      });
    } finally {
      loading.value = false;
    }
  }

  async function fetchModules() {
    const user = authStore.authUser;
    if (!user) {
      snackBarStore.showSnackbar({
        text: t("auth/user-not-found"),
        snackbarType: snackbarType.ERROR,
      });
      return;
    }

    try {
      loading.value = true;

      const { data: facilities } =
        await webappUserRoleDimensionClient.getAllFacilities(user.userId);
      const { data: facilityCompanies } =
        await webappUserRoleDimensionClient.getAllFacilityCompanies(
          user.userId,
        );
      const { data: trainOperators } =
        await webappUserRoleDimensionClient.getAllTrainOperators(user.userId);
      const { data: truckingCompanies } =
        await webappUserRoleDimensionClient.getAllTruckingCompanies(
          user.userId,
        );
      const { data: truckingSystems } =
        await webappUserRoleDimensionClient.getAllTruckingSystems(user.userId);

      updateModules(
        facilities,
        facilityCompanies,
        trainOperators,
        truckingCompanies,
        truckingSystems,
      );
    } catch (e) {
      const errorText = t(getErrorByTypeOrDefault(e));
      snackBarStore.showSnackbar({
        text: errorText,
        snackbarType: snackbarType.ERROR,
      });
    } finally {
      loading.value = false;
    }
  }

  return {
    fetchWebappUsers,
    fetchModules,
    createWebappUser,
    patchWebappUser,
    loading,
    formattedTruckingCompanyRoles,
    formattedFacilityOperatorRoles,
    formattedTrainOperatorRoles,
  };
}

<template>
  <VirtualTable
    :items="getOnlyRelevantChanges"
    :headers="headers"
    :row-props="getRowClass"
    :mode="'normal'"
    :height="computedHeight"
    fixed-header
  >
    <template #item.icon="{ value }">
      <v-icon :color="getIconColor(value.state)" :icon="getIcon(value.state)" />
    </template>

    <template #item.supervisor="{ value }">
      <span>
        {{
          value.supervisor
            ? `${value.supervisor.firstName} ${value.supervisor.lastName}`
            : "-"
        }}
      </span>
    </template>

    <template #item.creator="{ value }">
      <span>
        {{
          value.state === IspsTruckerChangeTOStateEnum.System
            ? t("userManagement.states.system")
            : `${value.creator.firstName} ${value.creator.lastName}` || "-"
        }}
      </span>
    </template>

    <template #item.requestedAt="{ value }">
      <span>
        {{ value.createdDate ? formatDateWithHour(value.createdDate) : "-" }}
      </span>
    </template>

    <template #item.completedAt="{ value }">
      <span>
        {{ value.completedAt ? formatDate(value.completedAt) : "-" }}
      </span>
    </template>

    <template #item.delete="{ value }">
      <v-tooltip
        v-if="value.delete"
        location="bottom"
        :text="t('userManagement.user_deleted')"
      >
        <template #activator="{ props: activatorProps }">
          <v-icon
            v-bind="activatorProps"
            color="error"
            icon="mdi-account-remove"
          />
        </template>
      </v-tooltip>
      <span v-else> - </span>
    </template>

    <template #item.terrorStatus="{ value }">
      <span>
        {{
          value.terrorStatus
            ? t(
                `userManagement.terror_state.${value.terrorStatus.toLowerCase()}`,
              )
            : "-"
        }}
      </span>
    </template>

    <template #item.documentStatus="{ value }">
      <span>
        {{
          value.documentStatus
            ? t(
                `userManagement.document_state.${value.documentStatus.toLowerCase()}`,
              )
            : "-"
        }}
      </span>
    </template>

    <template #item.fullName="{ value }">
      <span>{{ formatName(value) }}</span>
    </template>

    <template #item.state="{ value }">
      <span>
        {{
          value.state
            ? t(`userManagement.states.${value.state.toLowerCase()}`)
            : "-"
        }}
      </span>
    </template>

    <template #item.reset="{ value }">
      <v-icon v-if="value.reset" icon="mdi-check" />
      <span v-else>-</span>
    </template>

    <template #item.action="{ value }">
      <v-row v-if="hasChanges(value)">
        <v-col cols="6">
          <v-tooltip location="bottom">
            <template #activator="{ props: activatorProps }">
              <v-btn
                v-bind="activatorProps"
                color="success"
                variant="text"
                icon="mdi-account-check"
                @click="approveAction(value.id, true)"
              />
            </template>
            <span>{{ t("approve") }}</span>
          </v-tooltip>
        </v-col>
        <v-col cols="6">
          <v-tooltip location="bottom">
            <template #activator="{ props: activatorProps }">
              <v-btn
                v-bind="activatorProps"
                color="error"
                variant="text"
                icon="mdi-account-cancel"
                @click="approveAction(value.id, false)"
              />
            </template>
            <span>{{ t("deny") }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-tooltip v-else-if="sameUser(value)" location="bottom">
        <template #activator="{ props: activatorProps }">
          <v-icon
            v-bind="activatorProps"
            icon="mdi-information-box"
            color="yellow"
          />
        </template>
        <span>{{ t("userManagement.cannot_approve_own_changes") }}</span>
      </v-tooltip>
      <span v-else>-</span>
    </template>
  </VirtualTable>
</template>

<script lang="ts" setup>
import {
  IspsTruckerChangeTO,
  IspsTruckerChangeTOStateEnum,
} from "@/services/client/generated";
import VirtualTable from "../virtual-table/VirtualTable.vue";
import { isAdmin } from "@/utils/role-utils";
import { useAuthStore } from "@/store/useAuthStore";
import { useI18n } from "vue-i18n";
import { computed } from "vue";
import { formatDate, formatDateWithHour } from "@/utils/date-utils";

const props = defineProps<{ changes: IspsTruckerChangeTO[] }>();
const userStore = useAuthStore();
const { t } = useI18n();
const user = userStore.getUser();

const emit = defineEmits<{
  (e: "approve", payload: { approve: boolean; id: number }): void;
}>();

const headers = computed(() => [
  {
    title: "",
    value: "icon",
    align: "center" as "center" | "start" | "end",
  },
  {
    title: t("userManagement.change_id"),
    value: "id",
    align: "center" as "center" | "start" | "end",
    width: "130",
  },
  {
    title: t("userManagement.creator"),
    value: "creator",
    align: "center" as "center" | "start" | "end",
    width: "250",
  },
  {
    title: t("userManagement.requested_at"),
    value: "requestedAt",
    align: "center" as "center" | "start" | "end",
    width: "220",
  },
  {
    title: t("userManagement.approved_by"),
    value: "supervisor",
    align: "center" as "center" | "start" | "end",
    width: "300",
  },

  {
    title: t("userManagement.completed_at"),
    value: "completedAt",
    align: "center" as "center" | "start" | "end",
    width: "160",
  },
  {
    title: t("userManagement.deleted"),
    value: "delete",
    align: "center" as "center" | "start" | "end",
  },
  {
    title: t("userManagement.terror_status"),
    value: "terrorStatus",
    align: "center" as "center" | "start" | "end",
    width: "280",
  },
  {
    title: t("userManagement.document_status"),
    value: "documentStatus",
    align: "center" as "center" | "start" | "end",
    width: "250",
  },
  {
    title: t("full_name"),
    value: "fullName",
    align: "center" as "center" | "start" | "end",
    width: "250",
  },
  {
    title: t("userManagement.change_state"),
    value: "state",
    align: "center" as "center" | "start" | "end",
    width: "180",
  },
  {
    title: t("reset"),
    value: "reset",
    align: "center" as "center" | "start" | "end",
  },
  {
    title: t("actions"),
    value: "action",
    align: "center" as "center" | "start" | "end",
    width: "135",
  },
]);

const getOnlyRelevantChanges = computed(() =>
  props.changes
    .filter(e => ["PENDING", "SYSTEM", "APPROVED"].includes(e.state ?? ""))
    .sort((a, b) => {
      const dateA = new Date(a.createdDate || "");
      const dateB = new Date(b.createdDate || "");
      return dateA.getTime() - dateB.getTime();
    }),
);

const computedHeight = computed(() => {
  const rowHeight = 50;
  let height = 0;

  if (getOnlyRelevantChanges.value.length < 2) {
    height = 3 * rowHeight;
  } else if (getOnlyRelevantChanges.value.length < 5) {
    height = 5 * rowHeight;
  } else if (getOnlyRelevantChanges.value.length < 7) {
    height = 8 * rowHeight;
  } else if (getOnlyRelevantChanges.value.length <= 10) {
    height = 10 * rowHeight;
  } else {
    height = 15 * rowHeight + 50;
  }

  const maxHeight = 600;

  return Math.min(height, maxHeight);
});

const formatName = (value: IspsTruckerChangeTO) => {
  if (!value) return "-";
  if (value.firstName && value.lastName) {
    return `${value.firstName} ${value.lastName}`;
  } else if (value.firstName && !value.lastName) {
    return value.firstName;
  } else if (!value.firstName && value.lastName) {
    return value.lastName;
  } else {
    return "-";
  }
};

const hasChanges = (change: IspsTruckerChangeTO) => {
  return (
    isAdmin(user?.userData?.roles ?? []) &&
    change.state === "PENDING" &&
    change.creator?.id !== user?.userData?.id
  );
};

const sameUser = (change: IspsTruckerChangeTO) => {
  return (
    isAdmin(user?.userData?.roles ?? []) &&
    change.state === "PENDING" &&
    change.creator?.id === user?.userData?.id
  );
};

const approveAction = (id: number, approve: boolean) => {
  emit("approve", { approve, id });
};

const getIcon = (state: string) => {
  const iconMap: Record<string, string> = {
    PENDING: "mdi-clock-time-four",
    APPROVED: "mdi-check-circle",
    SYSTEM: "mdi-account-cog",
  };
  return iconMap[state] || "mdi-close-circle";
};

const getIconColor = (state: string) => {
  const colorMap: Record<string, string> = {
    PENDING: "yellow",
    APPROVED: "success",
    SYSTEM: "info",
  };
  return colorMap[state] || "grey";
};

const getRowClass = (item: { item: IspsTruckerChangeTO }) => {
  const change = item.item;
  const rowClass = {
    "bg-yellow-light": change.state === "PENDING",
  };
  return { class: rowClass };
};
</script>

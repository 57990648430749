import { TruckingCompanyResponseTO } from "@/services/client/generated";
import { defineStore } from "pinia";
import { computed, ref } from "vue";

export const useTruckingCompanyStore = defineStore(
  "truckingCompanyStore",
  () => {
    const truckingCompanies = ref<TruckingCompanyResponseTO[]>([]);

    const sortedTruckingCompanies = computed(() =>
      truckingCompanies.value.sort(
        (a, b) =>
          new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime(),
      ),
    );

    const updateTruckingCompanies = (
      newTruckingCompanies: TruckingCompanyResponseTO[],
    ) => {
      truckingCompanies.value = newTruckingCompanies;
    };

    const addTruckingCompany = (
      newTruckingCompany: TruckingCompanyResponseTO,
    ) => {
      truckingCompanies.value.push(newTruckingCompany);
    };

    const updateSpecificTruckingCompany = (
      updatedTruckingCompane: TruckingCompanyResponseTO,
    ) => {
      const index = truckingCompanies.value.findIndex(
        company => company.id === updatedTruckingCompane.id,
      );
      if (index !== -1) {
        truckingCompanies.value[index] = updatedTruckingCompane;
      }
    };

    return {
      sortedTruckingCompanies,
      updateTruckingCompanies,
      updateSpecificTruckingCompany,
      addTruckingCompany,
    };
  },
);

<template>
  <div v-if="user && originalUser">
    <TheMainHeader
      class="text-left pl-10 pt-10"
      :text="t('conroo_admin')"
      :dynamic-data="{
        id: `${originalUser.firstName} ${originalUser.lastName}`,
      }"
    >
      <span class="bg-greyish pa-2 rounded-lg">
        <v-icon icon="custom:UserFilled" :height="24" :width="24" />
      </span>
      <span class="text-h4 font-weight-medium text-primary ml-2">
        {{ originalUser.firstName }} {{ originalUser.lastName }}
      </span>
      <template #right-anchor>
        <div class="text-h4 text-primary pr-10">
          <v-btn
            class="mr-4"
            variant="text"
            append-icon="mdi-delete-outline"
            :disabled="!hasChanges || loading"
            @click="discardChanges"
          >
            {{ t("discard_changes") }}
          </v-btn>
          <v-btn
            append-icon="mdi-content-save-outline"
            variant="tonal"
            :disabled="!hasChanges || loading"
            @click="saveWebappUser"
          >
            {{ t("save_changes") }}
          </v-btn>
        </div>
      </template>
    </TheMainHeader>

    <div class="container py-16">
      <section class="mb-4">
        <GeneralInformationSection
          :loading="loading"
          :user="user"
          :original-user="originalUser"
          @update="updateWebappUser"
        />
      </section>

      <section class="mb-4">
        <RolesSection
          :loading="loading"
          :user="user"
          :original-user="originalUser"
          :trucking-company-ids="user?.truckingCompanyIds ?? []"
          :facility-company-ids="user?.facilityCompanyIds ?? []"
          :facility-ids="user?.facilityIds ?? []"
          :train-operator-ids="user?.trainOperatorIds ?? []"
          @update="updateWebappUser"
        />
      </section>

      <section class="mb-4">
        <LinkedTruckingCompaniesSection
          :loading="loading"
          :user="user"
          :original-user="originalUser"
          :all-trucking-companies="webappUserStore.allTruckingCompanies"
          @update="updateWebappUser"
        />
      </section>

      <section class="mb-4">
        <LinkedFacilitiesSection
          :loading="loading"
          :user="user"
          :original-user="originalUser"
          :all-facility-companies="webappUserStore.allFacilityCompanies"
          :all-facilities="webappUserStore.allFacilities"
          @update="updateWebappUser"
        />
      </section>

      <section class="mb-4">
        <LinkedTrainOperatorsSection
          :loading="loading"
          :user="user"
          :original-user="originalUser"
          :all-train-operators="webappUserStore.allTrainOperators"
          @update="updateWebappUser"
        />
      </section>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from "vue";
import TheMainHeader from "@/components/TheMainHeader.vue";
import { useRoute, useRouter } from "vue-router";
import GeneralInformationSection from "./sections/GeneralInformationSection.vue";
import RolesSection from "./sections/RolesSection.vue";
import LinkedTruckingCompaniesSection from "./sections/LinkedTruckingCompaniesSection.vue";
import LinkedFacilitiesSection from "./sections/LinkedFacilitiesSection.vue";
import LinkedTrainOperatorsSection from "./sections/LinkedTrainOperatorsSection.vue";
import { useI18n } from "vue-i18n";
import { useWebAppUserStore } from "@/store/useWebAppUserStore";
import {
  UpdateWebappUserRequestTO,
  UpdateWebappUserRequestTOFacilityOperatorRoleEnum,
  UpdateWebappUserRequestTOTrainOperatorRoleEnum,
  UpdateWebappUserRequestTOTruckingCompanyRoleEnum,
  WebappUserResponseTO,
} from "@/services/client/generated";
import { useWebappUserLogic } from "./webapp-user-logic";
import { isEqual } from "lodash";

const route = useRoute();
const router = useRouter();
const webappUserStore = useWebAppUserStore();
const {
  loading,
  formattedTruckingCompanyRoles,
  formattedFacilityOperatorRoles,
  formattedTrainOperatorRoles,
  patchWebappUser,
} = useWebappUserLogic();

const { t } = useI18n();
const user = ref<UpdateWebappUserRequestTO>();
const originalUser = ref<WebappUserResponseTO>();

const truckingRoleChecker = (
  role: UpdateWebappUserRequestTOTruckingCompanyRoleEnum,
) => formattedTruckingCompanyRoles.some(r => r.value === role);
const facilityRoleChecker = (
  role: UpdateWebappUserRequestTOFacilityOperatorRoleEnum,
) => formattedFacilityOperatorRoles.some(r => r.value === role);
const trainOperatorChecker = (
  role: UpdateWebappUserRequestTOTrainOperatorRoleEnum,
) => formattedTrainOperatorRoles.some(r => r.value === role);

const updateWebappUser = (updatedData: Partial<UpdateWebappUserRequestTO>) => {
  if (user.value) {
    const mergedUser = { ...user.value, ...updatedData };
    user.value = mergedUser;
  }
};

const hasChanged = <T,>(userValue: T[], originalValue: T[]) => {
  if (!userValue || !originalValue) return false;

  const uniqueUserValue = [...new Set(userValue)].sort();
  const uniqueOriginalValue = [...new Set(originalValue)].sort();
  return !isEqual(uniqueUserValue, uniqueOriginalValue);
};

const hasFieldChanges = (fields: (keyof UpdateWebappUserRequestTO)[]) => {
  return fields.some(field => {
    const userField = user.value![field];
    const originalField =
      originalUser.value![field as keyof WebappUserResponseTO];
    return userField !== originalField;
  });
};

const hasRoleChanges = () => {
  if (!originalUser.value) return false;
  const originalRoles = originalUser.value.roles ?? [];
  if (!user.value) return false;
  return [
    {
      userRole: user.value.truckingCompanyRole,
      originalRole: originalRoles.find(role =>
        formattedTruckingCompanyRoles.some(r => r.value === role),
      ),
    },
    {
      userRole: user.value.facilityOperatorRole,
      originalRole: originalRoles.find(role =>
        formattedFacilityOperatorRoles.some(r => r.value === role),
      ),
    },
    {
      userRole: user.value.trainOperatorRole,
      originalRole: originalRoles.find(role =>
        formattedTrainOperatorRoles.some(r => r.value === role),
      ),
    },
  ].some(({ userRole, originalRole }) => {
    if (userRole === UpdateWebappUserRequestTOTruckingCompanyRoleEnum.None) {
      return false;
    }

    return userRole !== originalRole;
  });
};

const hasChanges = computed(
  () =>
    hasFieldChanges(["firstName", "lastName"]) ||
    hasRoleChanges() ||
    hasChanged(
      user.value!.truckingCompanyIds ?? [],
      originalUser.value?.forwarders?.map(f => f.id) ?? [],
    ) ||
    hasChanged(
      user.value!.facilityCompanyIds ?? [],
      originalUser.value?.facilities?.map(f => f.company) ?? [],
    ) ||
    hasChanged(
      user.value!.facilityIds ?? [],
      originalUser.value?.facilities?.map(f => f.facilityId) ?? [],
    ) ||
    hasChanged(
      user.value!.trainOperatorIds ?? [],
      originalUser.value?.trainOperators?.map(t => t.id) ?? [],
    ),
);

const getRoleValue = <T,>(
  roleType: T,
  roles: T[],
  roleChecker: (role: T) => boolean,
): T => {
  return roles.find(role => roleChecker(role)) ?? roleType;
};

const updateOriginalUser = () => {
  originalUser.value = webappUserStore.sortedWebappUsers.find(
    user => user.id === route.params.id,
  );

  if (!originalUser.value) {
    router.push("/admin/webappUsers");
  } else {
    const { roles } = originalUser.value;

    const trainOperators = originalUser.value?.trainOperators ?? [];
    const truckingCompanies = originalUser.value?.forwarders ?? [];
    const facilityCompanies = originalUser.value?.facilities ?? [];

    const trainOperatorRole = trainOperators.length
      ? getRoleValue(
          UpdateWebappUserRequestTOTrainOperatorRoleEnum.None,
          roles as UpdateWebappUserRequestTOTrainOperatorRoleEnum[],
          trainOperatorChecker,
        )
      : UpdateWebappUserRequestTOTrainOperatorRoleEnum.None;

    const truckingCompanyRole = truckingCompanies.length
      ? getRoleValue(
          UpdateWebappUserRequestTOTruckingCompanyRoleEnum.None,
          roles as UpdateWebappUserRequestTOTruckingCompanyRoleEnum[],
          truckingRoleChecker,
        )
      : UpdateWebappUserRequestTOTruckingCompanyRoleEnum.None;

    const facilityOperatorRole = facilityCompanies
      ? getRoleValue(
          UpdateWebappUserRequestTOFacilityOperatorRoleEnum.None,
          roles as UpdateWebappUserRequestTOFacilityOperatorRoleEnum[],
          facilityRoleChecker,
        )
      : UpdateWebappUserRequestTOFacilityOperatorRoleEnum.None;

    user.value = {
      firstName: originalUser.value.firstName,
      lastName: originalUser.value.lastName,
      truckingCompanyRole: truckingCompanyRole,
      facilityOperatorRole: facilityOperatorRole,
      trainOperatorRole: trainOperatorRole,
      truckingCompanyIds: (originalUser.value?.forwarders ?? []).map(f => f.id),
      facilityCompanyIds: (originalUser.value?.facilities ?? []).map(
        f => f.company ?? 0,
      ),
      facilityIds: (originalUser.value?.facilities ?? []).map(
        f => f.facilityId,
      ),
      trainOperatorIds: trainOperators.map(t => t.id),
    };
  }
};

const saveWebappUser = async () => {
  if (user.value && originalUser.value && originalUser.value.id) {
    const updatedUser = await patchWebappUser(
      originalUser.value.id,
      user.value,
    );
    originalUser.value = updatedUser;
  }
};

function discardChanges() {
  if (user.value && originalUser.value) {
    const { roles } = originalUser.value;

    user.value = {
      firstName: originalUser.value.firstName,
      lastName: originalUser.value.lastName,
      truckingCompanyRole: getRoleValue(
        UpdateWebappUserRequestTOTruckingCompanyRoleEnum.None,
        roles as UpdateWebappUserRequestTOTruckingCompanyRoleEnum[],
        truckingRoleChecker,
      ),
      facilityOperatorRole: getRoleValue(
        UpdateWebappUserRequestTOFacilityOperatorRoleEnum.None,
        roles as UpdateWebappUserRequestTOFacilityOperatorRoleEnum[],
        facilityRoleChecker,
      ),
      trainOperatorRole: getRoleValue(
        UpdateWebappUserRequestTOTrainOperatorRoleEnum.None,
        roles as UpdateWebappUserRequestTOTrainOperatorRoleEnum[],
        trainOperatorChecker,
      ),
      truckingCompanyIds: (originalUser.value?.forwarders ?? []).map(f => f.id),
      facilityCompanyIds: (originalUser.value?.facilities ?? []).map(
        f => f.company ?? 0,
      ),
      facilityIds: (originalUser.value?.facilities ?? []).map(
        f => f.facilityId,
      ),
      trainOperatorIds: (originalUser.value?.trainOperators ?? []).map(
        t => t.id,
      ),
    };
  }
}

onMounted(() => {
  updateOriginalUser();
});
</script>

<style scoped lang="css">
.container {
  max-width: 70vw;
  margin: 0 auto;
}
</style>

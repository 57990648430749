<template>
  <v-row>
    <v-col cols="4" md="5">
      <div class="text-left">
        <h3>{{ t("userManagement.linkedTruckingCompanies.title") }}</h3>
        <p>
          {{ t("userManagement.linkedTruckingCompanies.description") }}
        </p>
      </div>
    </v-col>

    <v-col cols="8" md="7">
      <v-card class="pa-5" :loading="loading" :disabled="loading">
        <div class="d-flex font-weight-bold pb-3">
          {{
            t(
              "userManagement.linkedTruckingCompanies.add_remove_trucking_company",
            )
          }}
        </div>
        <div class="d-flex align-center justify-center py-2">
          <v-row>
            <template v-if="user.truckingCompanyIds?.length">
              <v-col
                v-for="company in sortedUserTruckingCompanyIds"
                :key="company"
                cols="12"
              >
                <v-text-field
                  :model-value="getCompanyNameById(company)"
                  class="cursor-default"
                  readonly
                  variant="outlined"
                  hide-details="auto"
                  append-inner-icon="mdi-minus-box-outline"
                  @click:appendInner="removeCompany(company)"
                />
              </v-col>
            </template>
            <v-col cols="12">
              <v-autocomplete
                ref="acCompanyRef"
                v-model="selectedTruckingCompany"
                :label="
                  t(
                    'userManagement.linkedTruckingCompanies.search_trucking_company',
                  )
                "
                variant="outlined"
                :items="filteredCompanies"
                item-title="name"
                item-value="id"
                prepend-inner-icon="mdi-magnify"
                hide-details="auto"
                :no-data-text="t('no_data_available')"
                @update:model-value="addCompany"
              />
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script lang="ts" setup>
import {
  DimensionTO,
  UpdateWebappUserRequestTO,
  WebappUserResponseTO,
} from "@/services/client/generated/api";
import { ref, computed, defineProps, toRefs } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  loading: boolean;
  user: UpdateWebappUserRequestTO;
  originalUser: WebappUserResponseTO;
  allTruckingCompanies: DimensionTO[];
}>();

const emit = defineEmits<{
  (event: "update", updatedData: Partial<UpdateWebappUserRequestTO>): void;
}>();

const { t } = useI18n();
const { originalUser, user } = toRefs(props);

if (!user.value.truckingCompanyIds) {
  user.value.truckingCompanyIds = (originalUser.value.forwarders ?? []).map(
    company => company.id,
  );
}

const selectedTruckingCompany = ref<string>();
const acCompanyRef = ref<HTMLElement | null>(null);
const blurEvent: Event = new Event("blur");

const filteredCompanies = computed(() =>
  props.allTruckingCompanies.filter(
    company =>
      company.id !== undefined &&
      (user.value.truckingCompanyIds ?? []).includes(company.id) === false,
  ),
);

const addCompany = (id: string) => {
  if (id == null) return;

  const companyToAdd = props.allTruckingCompanies.find(
    company => company.id === Number(id),
  );

  if (companyToAdd) {
    if (
      user.value.truckingCompanyIds &&
      companyToAdd.id !== undefined &&
      !user.value.truckingCompanyIds.includes(companyToAdd.id)
    ) {
      user.value.truckingCompanyIds.push(companyToAdd.id);
      emitUpdate();
    }
  }

  selectedTruckingCompany.value = undefined;
  acCompanyRef.value?.dispatchEvent(blurEvent);
};

const removeCompany = (id: number) => {
  if (user.value.truckingCompanyIds) {
    user.value.truckingCompanyIds = user.value.truckingCompanyIds.filter(
      companyId => companyId !== id,
    );
  }
  emitUpdate();
};

const getCompanyNameById = (id: number) => {
  const company = props.allTruckingCompanies.find(company => company.id === id);
  return company ? company.name : "";
};

const sortedUserTruckingCompanyIds = computed(() => {
  return [...(user.value.truckingCompanyIds ?? [])].sort((a, b) => a - b);
});

const emitUpdate = () => {
  emit("update", {
    truckingCompanyIds: user.value.truckingCompanyIds,
  });
};
</script>

<template>
  <v-row>
    <v-col cols="4" md="5">
      <div class="text-left">
        <h3>{{ t("truckingCompany.tier.title") }}</h3>
        <p>{{ t("truckingCompany.tier.description") }}</p>
      </div>
    </v-col>

    <v-col cols="8" md="7">
      <v-card :disabled="loading" :loading="loading" class="pa-5">
        <div class="d-flex align-center justify-center py-2">
          <v-row>
            <v-col cols="12">
              <v-select
                v-model="company.tier"
                :items="tiers"
                variant="outlined"
                hide-details="auto"
                aria-required="true"
                @update:model-value="handleTierChange"
              >
              </v-select>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import {
  TruckingCompanyResponseTO,
  TruckingCompanyResponseTOTierEnum,
} from "@/services/client/generated";
import { toRefs } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  company: TruckingCompanyResponseTO;
  loading: boolean;
}>();

const emit = defineEmits<{
  (e: "update", updatedCompany: Partial<TruckingCompanyResponseTO>): void;
}>();

const { t } = useI18n();
const { company } = toRefs(props);

const tiers = [
  {
    title: TruckingCompanyResponseTOTierEnum.Free,
    value: TruckingCompanyResponseTOTierEnum.Free,
  },
  {
    title: TruckingCompanyResponseTOTierEnum.Premium,
    value: TruckingCompanyResponseTOTierEnum.Premium,
  },
];

const handleTierChange = (newTier: TruckingCompanyResponseTOTierEnum) => {
  emit("update", {
    tier: newTier,
  });
};
</script>

<template>
  <v-row>
    <v-col cols="4" md="5">
      <div class="text-left">
        <h3>{{ t("userManagement.linkedTrainOperators.title") }}</h3>
        <p>{{ t("userManagement.linkedTrainOperators.description") }}</p>
      </div>
    </v-col>

    <v-col cols="8" md="7">
      <v-card class="pa-5" :loading="loading" :disabled="loading">
        <div class="d-flex font-weight-bold pb-3">
          {{
            t("userManagement.linkedTrainOperators.add_remove_train_operator")
          }}
        </div>
        <div class="d-flex align-center justify-center py-2">
          <v-row>
            <template v-if="user.trainOperatorIds?.length">
              <v-col
                v-for="operator in sortedUserTrainOperatorIds"
                :key="operator"
                cols="12"
              >
                <v-text-field
                  :model-value="getTrainOperatorNameById(operator)"
                  class="cursor-default"
                  readonly
                  variant="outlined"
                  hide-details="auto"
                  append-inner-icon="mdi-minus-box-outline"
                  @click:appendInner="removeTrainOperator(operator)"
                />
              </v-col>
            </template>
            <v-col cols="12">
              <v-autocomplete
                ref="acTrainRef"
                v-model="selectedTrain"
                :label="
                  t('userManagement.linkedTrainOperators.search_train_operator')
                "
                variant="outlined"
                :items="filteredTrainOperators"
                item-title="name"
                item-value="id"
                prepend-inner-icon="mdi-magnify"
                hide-details="auto"
                :no-data-text="t('no_data_available')"
                @update:model-value="addTrainOperator"
              />
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script lang="ts" setup>
import { ref, computed, defineProps, toRefs } from "vue";
import { useI18n } from "vue-i18n";
import {
  DimensionTO,
  UpdateWebappUserRequestTO,
  WebappUserResponseTO,
} from "@/services/client/generated";

const props = defineProps<{
  loading: boolean;
  user: UpdateWebappUserRequestTO;
  originalUser: WebappUserResponseTO;
  allTrainOperators: DimensionTO[];
}>();

const emit = defineEmits<{
  (event: "update", updatedData: Partial<UpdateWebappUserRequestTO>): void;
}>();

const { t } = useI18n();
const { originalUser, user } = toRefs(props);

if (!user.value.trainOperatorIds) {
  user.value.trainOperatorIds = (originalUser.value.trainOperators ?? []).map(
    operator => operator.id,
  );
}

const selectedTrain = ref<string>();
const acTrainRef = ref<HTMLElement | null>(null);
const blurEvent: Event = new Event("blur");

const filteredTrainOperators = computed(() =>
  (props.allTrainOperators ?? []).filter(
    operator =>
      operator.id !== undefined &&
      (user.value.trainOperatorIds ?? []).includes(operator.id) === false,
  ),
);

const sortedUserTrainOperatorIds = computed(() =>
  [...(user.value.trainOperatorIds ?? [])].sort((a, b) => a - b),
);

const addTrainOperator = (id: string) => {
  if (id === null) return;

  const operatorToAdd = props.allTrainOperators.find(
    operator => operator.id === Number(id),
  );

  if (operatorToAdd) {
    if (
      user.value.trainOperatorIds &&
      operatorToAdd.id !== undefined &&
      !user.value.trainOperatorIds.includes(operatorToAdd.id)
    ) {
      user.value.trainOperatorIds.push(operatorToAdd.id);
      emitUpdate();
    }
  }

  selectedTrain.value = undefined;
  acTrainRef.value?.dispatchEvent(blurEvent);
};

const removeTrainOperator = (id: number) => {
  user.value.trainOperatorIds = (user.value.trainOperatorIds ?? []).filter(
    operatorId => operatorId !== id,
  );
  emitUpdate();
};

const getTrainOperatorNameById = (id: number) => {
  const operator = props.allTrainOperators.find(operator => operator.id === id);
  return operator?.name ?? "";
};

const emitUpdate = () => {
  emit("update", {
    truckingCompanyIds: user.value.truckingCompanyIds,
  });
};
</script>

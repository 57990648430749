import { Ref, ref } from "vue";

export function useDialog<T = unknown, E = { code: string; message: string }>({
  onClose,
  onCancel,
  onOpen,
  onSubmit,
  noSubmitClose,
}: UseDialogParams<T> = {}): UseDialogReturn<T, E> {
  const isOpen = ref<boolean>(false);
  const isLoading = ref<boolean>(false);
  const param = ref<T>();
  const error = ref<E>();

  function open(_param?: T) {
    param.value = _param;
    isOpen.value = true;
    onOpen?.(param.value);
  }

  function submit() {
    error.value = undefined;
    if (!noSubmitClose) isOpen.value = false;
    onSubmit?.(param.value);
    if (!noSubmitClose) param.value = undefined;
  }

  function handleCloseDialog() {
    param.value = undefined;
    isLoading.value = false;
    error.value = undefined;
  }

  function cancel() {
    isOpen.value = false;
    onCancel?.(param.value);
    handleCloseDialog();
  }

  function close() {
    isOpen.value = false;
    onClose?.(param.value);
    handleCloseDialog();
  }

  function setIsLoading(value: boolean) {
    isLoading.value = value;
  }

  function setError(err: E) {
    error.value = err;
  }

  return {
    isOpen,
    isLoading,
    param,
    error,
    open,
    submit,
    cancel,
    close,
    setIsLoading,
    setError,
  };
}

export interface UseDialogParams<T = unknown> {
  onClose?: (param?: T) => void;
  onOpen?: (param?: T) => void;
  onSubmit?: (param?: T) => void;
  onCancel?: (param?: T) => void;
  noSubmitClose?: boolean;
}

export interface UseDialogReturn<
  T = unknown,
  E = { code: string; message: string },
> extends UseDialogParams<T> {
  param: Ref<T | undefined>;
  open: (param?: T) => void;
  isOpen: Ref<boolean>;
  isLoading: Ref<boolean>;
  submit: () => void;
  cancel: () => void;
  close: () => void;
  setIsLoading: (value: boolean) => void;
  setError: (error: E) => void;
  error: Ref<E | undefined>;
}

<template>
  <v-dialog v-model="modelValue" max-width="500" @click:outside="closeDialog">
    <v-card>
      <v-card-title class="text-h6 font-weight-bold">
        {{ t("truckingCompany.create_trucking_company") }}
      </v-card-title>

      <v-card-text>
        <p class="mb-4">
          {{ t("truckingCompany.create_trucking_company_text") }}
        </p>

        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="form.name"
              :label="`${t('truckingCompany.trucking_company_name')}*`"
              variant="outlined"
              hide-details="auto"
              aria-required="true"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="form.email"
              :label="`${t('truckingCompany.company_email_address')}*`"
              variant="outlined"
              hide-details="auto"
              aria-required="true"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="form.vatId"
              :label="`${t('truckingCompany.tax_id')}*`"
              variant="outlined"
              hide-details="auto"
              aria-required="true"
              :error-messages="
                vatIdError
                  ? [t('truckingCompany.trucking_vat_id_already_exists')]
                  : []
              "
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="form.streetAddress"
              :label="t('truckingCompany.street_no')"
              variant="outlined"
              hide-details="auto"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="form.city"
              :label="t('city')"
              variant="outlined"
              hide-details="auto"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="form.zipCode"
              :label="t('zip_code')"
              variant="outlined"
              hide-details="auto"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="form.country"
              :label="t('country')"
              variant="outlined"
              hide-details="auto"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="justify-end">
        <v-btn variant="text" color="primary" @click="closeDialog">
          {{ t("cancel") }}
        </v-btn>
        <v-btn
          color="primary"
          variant="flat"
          rounded="lg"
          class="pa-2"
          @click="confirm"
          :disabled="!isValid || vatIdError"
        >
          {{ t("truckingCompany.create_trucking_company") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { useTruckingCompaniesLogic } from "@/views/admin/trucking-companies/trucking-companies-logic";
import { ref, computed, toRefs, watch, onBeforeUnmount, reactive } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps({
  modelValue: Boolean,
});

const { t } = useI18n();
const { modelValue } = toRefs(props);

const emit = defineEmits<{
  (e: "update:modelValue", value: boolean): void;
}>();

const { createTruckingCompany } = useTruckingCompaniesLogic();

const form = reactive({
  name: "",
  email: "",
  vatId: "",
  streetAddress: "",
  city: "",
  zipCode: "",
  country: "",
});

const vatIdError = ref(false);
const previousVatId = ref("");
const vatIdThatCausedError = ref("");

const isValid = computed(
  () => form.name && form.vatId && form.email.includes("@"),
);

const resetData = () => {
  form.name = "";
  form.email = "";
  form.vatId = "";
  form.streetAddress = "";
  form.city = "";
  form.zipCode = "";
  form.country = "";
};

const closeDialog = () => {
  resetData();
  emit("update:modelValue", false);
};

const handleKeyDown = (event: KeyboardEvent) => {
  if (event.key === "Escape") {
    closeDialog();
  }
};

const confirm = async () => {
  const errorOccurred = await createTruckingCompany(form);

  if (errorOccurred === "trucking_vat_id_already_exists") {
    vatIdError.value = true;
    vatIdThatCausedError.value = form.vatId;
  } else {
    closeDialog();
  }
};

watch(
  () => form.vatId,
  newVatId => {
    if (vatIdError.value && newVatId !== previousVatId.value) {
      vatIdError.value = false;
    }

    if (vatIdThatCausedError.value && newVatId === vatIdThatCausedError.value) {
      vatIdError.value = true;
    }

    previousVatId.value = newVatId;
  },
);

watch(modelValue, newValue => {
  if (newValue) {
    document.addEventListener("keydown", handleKeyDown);
  } else {
    document.removeEventListener("keydown", handleKeyDown);
  }
});

onBeforeUnmount(() => {
  document.removeEventListener("keydown", handleKeyDown);
});
</script>

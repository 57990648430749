// Composables
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import { WebappUserRolesEnum } from "@/services/client/generated";
import { routesWithPrefix } from "@/router/router-utils";
import HomePage from "@/views/general/homepage/HomePage.vue";
import TerminalOperatorDashboard from "@/views/terminal-operator/dashboard/TerminalOperatorDashboard.vue";
import TerminalOperatorDeadlines from "@/views/terminal-operator/deadlines/TerminalOperatorDeadlines.vue";
// import TerminalOperatorSlotManagement from "@/views/terminal-operator/slot-management/TerminalOperatorSlotManagement.vue";
// import TerminalOperatorSettings from "@/views/terminal-operator/settings-page/TerminalOperatorSettings.vue";
// import TerminalOperatorBookingOverview from "@/views/terminal-operator/booking-overview/TerminalOperatorBookingOverview.vue";
import TrainOperatorBookings from "@/views/train-operator/bookings-page/TrainOperatorBookings.vue";
import ForwarderOperatorDashboard from "@/views/forwarder-operator/dashboard/ForwarderOperatorDashboard.vue";
import ForwarderOperatorTruckerManagement from "@/views/forwarder-operator/trucker-management/ForwarderOperatorTruckerManagement.vue";
import ForwarderOperatorBookingsOverview from "@/views/forwarder-operator/bookings-overview/ForwarderOperatorBookingsOverview.vue";
import GetStatusOverview from "@/views/forwarder-operator/get-status/GetStatusOverview.vue";
import GetStatusBatchOverview from "@/views/forwarder-operator/get-status-batch/GetStatusBatchOverview.vue";
// import SuccessView from "@/views/SuccessView.vue";
// import ContactView from "@/views/ContactView.vue";
// import ImpressumView from "@/views/ImpressumView.vue";
import NotFound from "@/views/general/not-found/NotFound.vue";
import UserManagement from "@/views/admin/user-management/UserManagement.vue";
import WebappUsers from "@/views/admin/webapp-user/WebappUsers.vue";
import WebappUserId from "@/views/admin/webapp-user/WebappUserId.vue";
import TruckingCompanies from "@/views/admin/trucking-companies/TruckingCompanies.vue";
import TruckingCompanyId from "@/views/admin/trucking-companies/TruckingCompanyId.vue";
import TerminalOperatorBlockList from "@/views/terminal-operator/block-list/TerminalOperatorBlockList.vue";
import LicenseManagement from "@/views/forwarder-operator/license-management/LicenseManagement.vue";

export default [
  {
    path: "/",
    component: DefaultLayout,
    children: [
      {
        path: "",
        name: "home",
        component: HomePage,
        meta: {
          title: "Home | CONROO Webapp",
          requiresAuth: true,
          roles: [],
        },
      },
      //terminal operator routes
      ...routesWithPrefix("/terminalOperator", [
        {
          path: "",
          name: "terminalOperatorDashboard",

          component: TerminalOperatorDashboard,
          meta: {
            title: "Dashboard | CONROO Terminal Webapp",
            requiresAuth: true,
            roles: [
              WebappUserRolesEnum.TerminalOperationsAdmin,
              WebappUserRolesEnum.TerminalOperationsRead,
              WebappUserRolesEnum.TerminalOperationsWrite,
            ],
            breadcrumb: "terminalOperatorDashboard",
          },
        },
        {
          path: "/blockList",
          name: "terminalOperatorBlockList",
          component: TerminalOperatorBlockList,
          meta: {
            title: "Blockliste | CONROO Terminal Webapp",
            requiresAuth: true,
            roles: [
              WebappUserRolesEnum.IspsTerminalOperationsRead,
              WebappUserRolesEnum.IspsTerminalOperationsWrite,
            ],
            breadcrumb: "blockList.title",
          },
        },
        {
          path: "/deadlines",
          name: "terminalOperatorDeadlines",
          component: TerminalOperatorDeadlines,
          meta: {
            title: "Annahmebeschränkungen | CONROO Terminal Webapp",
            requiresAuth: true,
            roles: [
              WebappUserRolesEnum.TerminalOperationsAdmin,
              WebappUserRolesEnum.TerminalOperationsRead,
              WebappUserRolesEnum.TerminalOperationsWrite,
            ],
            breadcrumb: "acceptanceRestrictions",
          },
        },
        // {
        //   path: "/slotManagement",
        //   name: "terminalOperatorSlotManagement",
        //   component: TerminalOperatorSlotManagement,
        //   meta: {
        //     title: "Slot Management | CONROO Terminal Webapp",
        //     requiresAuth: true,
        //     roles: [
        //       WebappUserRolesEnum.TerminalOperationsAdmin,
        //       WebappUserRolesEnum.TerminalOperationsRead,
        //       WebappUserRolesEnum.TerminalOperationsWrite,
        //     ],
        //     breadcrumb: "slotManagement.title",
        //   },
        // },
        // {
        //   path: "/settings",
        //   name: "terminalOperatorSettings",
        //   component: TerminalOperatorSettings,
        //   meta: {
        //     title: "Settings | CONROO Terminal Webapp",
        //     requiresAuth: true,
        //     roles: [
        //       WebappUserRolesEnum.TerminalOperationsAdmin,
        //       WebappUserRolesEnum.TerminalOperationsRead,
        //       WebappUserRolesEnum.TerminalOperationsWrite,
        //     ],
        //     breadcrumb: "settings",
        //   },
        // },
        /**
         *? This is a temporary solution to hide the booking data page for terminal operators
         *? The service will be accessible in the future when it will be available
         *? per company.
         * with @Fabian, 14-10-2024
         **/
        // {
        //   path: "/bookingOverview",
        //   name: "terminalOperatorBookingOverview",
        //   component: TerminalOperatorBookingOverview,
        //   meta: {
        //     title: "Buchungsübersicht | CONROO Terminal Webapp",
        //     requiresAuth: true,
        //     roles: [
        //       WebappUserRolesEnum.ConrooWrite,
        //       WebappUserRolesEnum.ConrooRead,
        //       WebappUserRolesEnum.ConrooAdmin,
        //       WebappUserRolesEnum.TerminalOperationsAdmin,
        //       WebappUserRolesEnum.TerminalOperationsRead,
        //       WebappUserRolesEnum.TerminalOperationsWrite,
        //     ],
        //   },
        // },
      ]),
      ...routesWithPrefix("/admin", [
        {
          path: "/gatePassUsers",
          name: "gatePassUsers",
          component: UserManagement,
          meta: {
            title: "Gate-Pass-Benutzer | CONROO Admin Webapp",
            requiresAuth: true,
            roles: [
              WebappUserRolesEnum.ConrooWrite,
              WebappUserRolesEnum.ConrooRead,
              WebappUserRolesEnum.ConrooAdmin,
            ],
            breadcrumb: "Gate Pass Users",
          },
        },
        {
          path: "/webappUsers",
          name: "webappUsers",
          component: WebappUsers,
          meta: {
            title: "Webapp-Benutzer | CONROO Admin Webapp",
            requiresAuth: true,
            roles: [
              WebappUserRolesEnum.ConrooWrite,
              WebappUserRolesEnum.ConrooRead,
              WebappUserRolesEnum.ConrooAdmin,
            ],
            breadcrumb: "userManagement.webapp_users",
          },
        },
        {
          path: "/webappUsers/:id",
          name: "webappUserId",
          component: WebappUserId,
          props: true,
          meta: {
            title: "Webapp-Benutzer Details | CONROO Admin Webapp",
            requiresAuth: true,
            roles: [
              WebappUserRolesEnum.ConrooWrite,
              WebappUserRolesEnum.ConrooRead,
              WebappUserRolesEnum.ConrooAdmin,
            ],
            breadcrumb: "userManagement.webapp_users",
          },
        },
        {
          path: "/truckingCompanies",
          name: "truckingCompanies",
          component: TruckingCompanies,
          meta: {
            title: "Speditionen | CONROO Admin Webapp",
            requiresAuth: true,
            roles: [
              WebappUserRolesEnum.ConrooWrite,
              WebappUserRolesEnum.ConrooRead,
              WebappUserRolesEnum.ConrooAdmin,
            ],
            breadcrumb: "truckingCompany.title",
          },
        },
        {
          path: "/truckingCompanies/:id",
          name: "truckingCompanyId",
          component: TruckingCompanyId,
          props: true,
          meta: {
            title: "Spedition Details | CONROO Admin Webapp",
            requiresAuth: true,
            roles: [
              WebappUserRolesEnum.ConrooWrite,
              WebappUserRolesEnum.ConrooRead,
              WebappUserRolesEnum.ConrooAdmin,
            ],
            breadcrumb: "truckingCompany.title",
          },
        },
      ]),
      {
        path: "trainOperator",
        name: "trainOperatorBookings",
        component: TrainOperatorBookings,
        meta: {
          title: "Buchungsübersicht | CONROO Zugoperateure Webapp",
          requiresAuth: true,
          roles: [
            WebappUserRolesEnum.TrainOperatorRead,
            WebappUserRolesEnum.TrainOperatorWrite,
          ],
          breadcrumb: "train_operator",
        },
      },
      ...routesWithPrefix("/forwarderOperator", [
        {
          path: "",
          name: "forwarderOperatorDashboard",
          component: ForwarderOperatorDashboard,
          meta: {
            title: "Dashboard | CONROO Speditionen Webapp",
            requiresAuth: true,
            roles: [
              WebappUserRolesEnum.ForwarderWrite,
              WebappUserRolesEnum.ForwarderRead,
            ],
            breadcrumb: "trainOperatorDashboard",
          },
        },
        {
          path: "/truckerManagement",
          name: "forwarderOperatorTruckerManagement",
          component: ForwarderOperatorTruckerManagement,
          meta: {
            title: "Benutzerverwaltung | CONROO Speditionen Webapp",
            requiresAuth: true,
            roles: [WebappUserRolesEnum.ForwarderWrite],
            breadcrumb: "truckerManagement",
          },
        },

        {
          path: "/licenseManagement",
          name: "forwarderOperatorLicenseManagement",
          component: LicenseManagement,
          meta: {
            title: "Benutzerverwaltung | CONROO Speditionen Webapp",
            requiresAuth: true,
            roles: [
              WebappUserRolesEnum.ConrooWrite,
              WebappUserRolesEnum.ConrooRead,
              WebappUserRolesEnum.ConrooAdmin,
              WebappUserRolesEnum.ForwarderWrite,
            ],
            breadcrumb: "license_management",
          },
        },

        {
          path: "/bookingsOverview",
          name: "forwarderOperatorBookingsOverview",
          component: ForwarderOperatorBookingsOverview,
          meta: {
            title: "Buchungsübersicht | CONROO Speditionen Webapp",
            requiresAuth: true,
            roles: [
              WebappUserRolesEnum.ForwarderWrite,
              WebappUserRolesEnum.ForwarderRead,
            ],
            breadcrumb: "bookingOverview",
          },
        },

        {
          path: "/statusRequest",
          name: "statusRequest",
          component: GetStatusOverview,
          meta: {
            title: "Statusanfrage & Buchen | CONROO Speditionen Webapp",
            requiresAuth: true,
            roles: [
              WebappUserRolesEnum.ForwarderWrite,
              WebappUserRolesEnum.ForwarderRead,
            ],
            breadcrumb: "statusRequest",
          },
        },

        {
          path: "/batchStatusRequest?file=:fileName&terminalId=:terminalIdInput&statusRequest=:getStatusRequestStringified",
          name: "batchStatusRequest",
          component: GetStatusBatchOverview,
          props: r => r.params,
          meta: {
            title: "Mehrfache Statusabfrage | CONROO Speditionen Webapp",
            requiresAuth: true,
            roles: [
              WebappUserRolesEnum.ForwarderWrite,
              WebappUserRolesEnum.ForwarderRead,
            ],
          },
        },
      ]),
      {
        path: "/:pathMatch(.*)*",
        name: "page-not-found-404",
        component: NotFound,
        meta: {
          title: "Seite nicht gefunden",
          requiresAuth: false,
          roles: [],
        },
      },
    ],
  },
];

import { webappUserTruckingCompanyClient } from "@/services/client/configs/services";
import {
  CreateTruckingCompanyRequestTO,
  UpdateTruckingCompanyRequestTO,
} from "@/services/client/generated";
import snackbarType from "@/store/interfaces/snackbar-type";
import { useAuthStore } from "@/store/useAuthStore";
import { useSnackbarStore } from "@/store/useSnackbarStore";
import { useTruckingCompanyStore } from "@/store/useTruckingCompanyStore";
import { getErrorByTypeOrDefault } from "@/utils/error-utils";
import { ref } from "vue";
import { useI18n } from "vue-i18n";

export function useTruckingCompaniesLogic() {
  const authStore = useAuthStore();
  const snackbarStore = useSnackbarStore();
  const {
    updateTruckingCompanies,
    updateSpecificTruckingCompany,
    addTruckingCompany,
  } = useTruckingCompanyStore();

  const loading = ref(false);
  const { t } = useI18n();

  async function fetchTruckingCompanies() {
    const user = authStore.authUser;
    if (!user) {
      snackbarStore.showSnackbar({
        text: t("auth/user-not-found"),
        snackbarType: snackbarType.ERROR,
      });
      return;
    }

    try {
      loading.value = true;
      const { data: truckingCompanies } =
        await webappUserTruckingCompanyClient.listTruckingCompany(user.userId);

      updateTruckingCompanies(truckingCompanies);
    } catch (e) {
      const errorText = t(getErrorByTypeOrDefault(e));
      snackbarStore.showSnackbar({
        text: errorText,
        snackbarType: snackbarType.ERROR,
      });
      throw e;
    } finally {
      loading.value = false;
    }
  }

  async function createTruckingCompany(
    newCompany: CreateTruckingCompanyRequestTO,
  ) {
    const user = authStore.getUser();
    if (!user) {
      snackbarStore.showSnackbar({
        text: t("auth/user-not-found"),
        snackbarType: snackbarType.ERROR,
      });
      return;
    }
    try {
      loading.value = true;
      const { data: addedCompany } =
        await webappUserTruckingCompanyClient.createTruckingCompany(
          user.userId,
          newCompany,
        );
      addTruckingCompany(addedCompany);
      snackbarStore.showSnackbar({
        text: t("truckingCompany.company_created_successfully"),
        snackbarType: snackbarType.SUCCESS,
      });
      return null;
    } catch (e) {
      const errorType = getErrorByTypeOrDefault(e);
      if (errorType === "trucking_vat_id_already_exists") {
        return "trucking_vat_id_already_exists";
      }

      const errorText = t(`truckingCompany.${errorType}`);
      snackbarStore.showSnackbar({
        text: errorText,
        snackbarType: snackbarType.ERROR,
      });
      return errorType;
    } finally {
      loading.value = false;
    }
  }

  async function updateTruckingCompany(
    toBeUpdatedTruckingCompany: UpdateTruckingCompanyRequestTO,
  ) {
    const user = authStore.authUser;
    if (!user) {
      snackbarStore.showSnackbar({
        text: t("auth/user-not-found"),
        snackbarType: snackbarType.ERROR,
      });
      return;
    }
    try {
      loading.value = true;
      const { data: updatedCompany } =
        await webappUserTruckingCompanyClient.updateTruckingCompany(
          user.userId,
          toBeUpdatedTruckingCompany.id,
          toBeUpdatedTruckingCompany,
        );

      updateSpecificTruckingCompany(updatedCompany);

      snackbarStore.showSnackbar({
        text: t("truckingCompany.company_updated_successfully"),
        snackbarType: snackbarType.SUCCESS,
      });
      return updatedCompany;
    } catch (e) {
      const errorText = t(getErrorByTypeOrDefault(e));
      snackbarStore.showSnackbar({
        text: errorText,
        snackbarType: snackbarType.ERROR,
      });
      throw e;
    } finally {
      loading.value = false;
    }
  }

  return {
    fetchTruckingCompanies,
    createTruckingCompany,
    updateTruckingCompany,
    loading,
  };
}

<template>
  <v-card elevation="0" min-width="500">
    <v-card-title class="pa-5">
      <v-row v-for="(filterGroup, index) in filterGroups" :key="index">
        <v-col cols="12" align-self="center">
          {{ filterGroup.title }}
        </v-col>
        <v-col
          v-for="(item, itemIndex) in filterGroup.items"
          :key="itemIndex"
          cols="6"
          align-self="center"
        >
          <TheDropdown
            :label="item.label"
            item-title="display"
            item-value="id"
            :type="item.label"
            :translate="true"
            :outlined="true"
            :hide-details="true"
            :density="'comfortable'"
            :items="item.options"
            :default-value="getSelectedValue(item.label)"
            return-object
            @change-item="value => updateFilter(value)"
          />
        </v-col>
      </v-row>
    </v-card-title>

    <v-card-actions>
      <v-col cols="12" class="text-right">
        <v-btn
          data-testid="test-bookings-filter-apply-button"
          @click="emitFilters"
          color="primary"
        >
          {{ $t("apply_filters") }}
        </v-btn>
      </v-col>
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import { reactive, watch } from "vue";
import TheDropdown from "@/components/TheDropdown.vue";
import { FilterGroup } from "@/internal-models/filter";

const props = defineProps<{
  filterGroups: FilterGroup[];
  appliedFilters: {
    label: string;
    value: string | { id: string; display: string };
    displayValue?: string;
  }[];
}>();

const emit = defineEmits(["update:filters", "apply"]);

const internalFilters = reactive<
  Record<string, { id: string; display: string; i18nKey: string }>
>({});

const getSelectedValue = (key: string) => {
  const existing = props.appliedFilters.find(f => f.label === key);
  return existing ? existing.value : null;
};

const updateFilter = (selected: {
  label: string;
  value: { id: string; display: string; i18nKey: string };
}) => {
  if (selected.value === null) {
    delete internalFilters[selected.label];
  } else {
    internalFilters[selected.label] = selected.value;
  }
};

const emitFilters = () => {
  const filters = Object.keys(internalFilters).map(label => ({
    label,
    value: internalFilters[label],
  }));
  emit("update:filters", filters);
  emit("apply");
};

watch(
  () => props.appliedFilters,
  newFilters => {
    for (const key in internalFilters) {
      delete internalFilters[key];
    }

    newFilters.forEach(f => {
      if (typeof f.value === "object" && f.value !== null) {
        internalFilters[f.label] = f.value as {
          id: string;
          display: string;
          i18nKey: string;
        };
      }
    });
  },
  { immediate: true, deep: true },
);
</script>

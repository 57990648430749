<template>
  <TheDialog
    :dialog="dialog"
    max-width="600px"
    :title="t('takeover_license')"
    :submit-text="t('takeover')"
    :cancel-text="t('cancel')"
    @onSubmit="handleSubmit"
  >
    <template #text>
      {{
        t("takeover_license_desc", {
          value: license?.truckerName,
        })
      }}
      <div>
        <TheAlert
          class="mt-4"
          v-if="errorMessage"
          type="error"
          :text="errorMessage"
        />
      </div>
    </template>
  </TheDialog>
</template>
<script setup lang="ts">
import TheDialog from "@/components/dialogs/TheDialog.vue";
import { UseDialogReturn } from "@/composables/useDialog";
import { useI18n } from "vue-i18n";
import { License, TakeoverLicenseError } from "@/models/license-management";
import { computed } from "vue";
import TheAlert from "@/components/TheAlert.vue";
import { getTranslatedErrorMessage } from "@/utils/translations-utils";

interface Props {
  dialog: UseDialogReturn<License>;
}

const emit = defineEmits(["onSearch", "onSubmit"]);
const props = defineProps<Props>();
const { t } = useI18n();

const license = computed(() => props.dialog.param.value);

const errorMessage = computed<TakeoverLicenseError["code"] | undefined>(() => {
  const error = props.dialog.error;

  const codeErrorMap: Record<TakeoverLicenseError["code"], string> = {
    NOT_BELONG_ORGANIZATION: t("license_not_belong_organisation"),
    license_not_found: t("license_not_found", {
      value: license.value,
    }),
  };

  if (error.value)
    return (
      codeErrorMap[error.value?.code] ??
      getTranslatedErrorMessage(t, error.value?.code)
    );
  return undefined;
});

function handleSubmit() {
  if (license.value) {
    emit("onSubmit", license.value);
  }
}
</script>

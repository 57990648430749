<template>
  <div>
    <TheMainHeader
      class="text-left pl-10 pt-10"
      :text="terminalStore.terminal?.name ?? t('missing')"
    >
      <span class="bg-greyish pa-2 rounded-lg">
        <v-icon icon="custom:DisturbFilled" :height="24" :width="24" />
      </span>
      <span class="text-h4 font-weight-medium text-primary ml-2">
        {{ $t("blockList.title") }}
      </span>
    </TheMainHeader>

    <div>
      <v-container fluid class="px-10">
        <VirtualTable
          :items="truckers"
          :headers="headers"
          :item-key="'id'"
          :button-label="t('blockList.add_trucker')"
          :button="true"
          :searchable="true"
          :search-text="t('blockList.search_for_id_or_full_name')"
          :custom-filter="filterByTruckerId"
          :pages-loaded="page"
          @button-click="addDialog = !addDialog"
          @load-more="handleLoadMore"
        >
          <template #item.name="{ value }">
            <span>{{ value.firstName }} {{ value.lastName }}</span>
          </template>
          <template #item.blockedDate="{ value }">
            <span>{{ formatDate(value.blockedDate ?? "") }}</span>
          </template>
          <template #item.actions="{ value }">
            <v-tooltip :text="$t('blockList.tooltip')" location="start">
              <template #activator="{ props }">
                <v-btn
                  v-bind="props"
                  icon="mdi-trash-can-outline"
                  variant="text"
                  color="red"
                  @click="openDeleteDialog(value)"
                />
              </template>
            </v-tooltip>
          </template>
        </VirtualTable>
      </v-container>

      <DeleteBlockListEntry
        v-if="selectedTrucker"
        v-model="deleteDialog"
        :selected-trucker="selectedTrucker"
        @delete-trucker="deleteTrucker"
        @cancel-delete="cancelDelete"
      />
      <AddTruckerToBlockList
        v-model="addDialog"
        :truckers="allTruckers"
        @block-trucker="addBlockListEntry($event)"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useTerminalOperatorBlockListLogic } from "./terminal-operator-block-list-logic";
import DeleteBlockListEntry from "./DeleteBlockListEntry.vue";
import { TerminalOperatorTruckerTO } from "@/services/client/generated";
import AddTruckerToBlockList from "./AddTruckerToBlockList.vue";
import { formatDate } from "@/utils/date-utils";
import VirtualTable from "@/components/virtual-table/VirtualTable.vue";
import { useForwarderStore } from "@/store/useForwarderStore";
import { useTerminalStore } from "@/store/useTerminalStore";
import TheMainHeader from "@/components/TheMainHeader.vue";

const { t } = useI18n();

const {
  truckers,
  page,
  size,
  addBlockListEntry,
  deleteBlockListEntry,
  getBlockListForFacility,
  handleLoadMore,
} = useTerminalOperatorBlockListLogic();

const deleteDialog = ref(false);
const addDialog = ref(false);
const allTruckers = ref<TerminalOperatorTruckerTO[]>([]);
const selectedTrucker = ref<TerminalOperatorTruckerTO | null>(null);
const forwarderStore = useForwarderStore();
const terminalStore = useTerminalStore();

const openDeleteDialog = (item: TerminalOperatorTruckerTO) => {
  selectedTrucker.value = item;
  deleteDialog.value = true;
};

const deleteTrucker = async () => {
  if (selectedTrucker.value && selectedTrucker.value.id) {
    await deleteBlockListEntry(selectedTrucker.value.id);
    deleteDialog.value = false;
  }
};

const cancelDelete = () => {
  selectedTrucker.value = null;
  deleteDialog.value = false;
};

const headers = computed(
  () =>
    [
      {
        title: t("name"),
        value: "name",
        key: "name",
        align: "center",
        sortRaw: (
          a: TerminalOperatorTruckerTO,
          b: TerminalOperatorTruckerTO,
        ) => {
          const nameA = `${a.firstName} ${a.lastName}`.toLowerCase();
          const nameB = `${b.firstName} ${b.lastName}`.toLowerCase();
          return nameA.localeCompare(nameB);
        },
      },
      { title: t("id"), value: "id", align: "center" },
      {
        title: t("blockList.date_blocked"),
        value: "blockedDate",
        key: "blockedDate",
        align: "center",
        sort: (a: Date, b: Date) => {
          const dateA = new Date(a).getTime();
          const dateB = new Date(b).getTime();
          return dateA - dateB;
        },
      },
      { title: t("blockList.reason"), value: "blockedReason", align: "center" },
      { title: "", value: "actions", align: "center" },
    ] as const,
);

const filterByTruckerId = (
  _value: string,
  query: string,
  item?: { value: unknown; raw: TerminalOperatorTruckerTO },
): boolean => {
  if (!item?.raw.id && (!item?.raw.firstName || !item?.raw.lastName)) {
    return false;
  }

  const fullName = `${item.raw.firstName} ${item.raw.lastName}`.toLowerCase();
  const lowerQuery = query.toLowerCase();

  return (
    item.raw.id?.toString().includes(query) || fullName.includes(lowerQuery)
  );
};

watch(
  () => [terminalStore.terminal, forwarderStore.forwarder],
  async ([newTerminal, newForwarder], [oldTerminal, oldForwarder]) => {
    if (
      newTerminal?.id !== oldTerminal?.id ||
      newForwarder?.id !== oldForwarder?.id
    ) {
      page.value = 0;
      size.value = 25;
      await getBlockListForFacility();
    }
  },
);
</script>

import {
  DimensionTO,
  FacilityTO,
  WebappUserResponseTO,
} from "@/services/client/generated";
import { defineStore } from "pinia";
import { computed, ref } from "vue";

export const useWebAppUserStore = defineStore(
  "webappUserStore",
  () => {
    const webappUsers = ref<WebappUserResponseTO[]>([]);

    const allFacilities = ref<FacilityTO[]>([]);
    const allFacilityCompanies = ref<DimensionTO[]>([]);
    const allTrainOperators = ref<DimensionTO[]>([]);
    const allTruckingCompanies = ref<DimensionTO[]>([]);
    const allTruckingSystems = ref<DimensionTO[]>([]);

    const sortedWebappUsers = computed(() =>
      webappUsers.value.sort(
        (a, b) =>
          new Date(b.createdDate ?? 0).getTime() -
          new Date(a.createdDate ?? 0).getTime(),
      ),
    );

    const updateWebappUsers = (newWebappUsers: WebappUserResponseTO[]) => {
      webappUsers.value = newWebappUsers;
    };

    const addWebappUser = (newWebappUser: WebappUserResponseTO) => {
      webappUsers.value.push(newWebappUser);
    };

    const updateWebappUser = (updatedWebappUser: WebappUserResponseTO) => {
      const index = webappUsers.value.findIndex(
        user => user.id === updatedWebappUser.id,
      );
      if (index !== -1) {
        webappUsers.value[index] = updatedWebappUser;
      }
    };

    const updateModules = (
      facilities: FacilityTO[],
      facilityCompanies: DimensionTO[],
      trainOperators: DimensionTO[],
      truckingCompanies: DimensionTO[],
      truckingSystems: DimensionTO[],
    ) => {
      allFacilities.value = facilities;
      allFacilityCompanies.value = facilityCompanies;
      allTrainOperators.value = trainOperators;
      allTruckingCompanies.value = truckingCompanies;
      allTruckingSystems.value = truckingSystems;
    };

    return {
      sortedWebappUsers,
      allFacilities,
      allFacilityCompanies,
      allTrainOperators,
      allTruckingCompanies,
      allTruckingSystems,
      updateWebappUsers,
      updateWebappUser,
      updateModules,
      addWebappUser,
    };
  },
  {
    persist: true,
  },
);

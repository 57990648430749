<template>
  <div>
    <TheMainHeader class="text-left pl-10 pt-10" :text="t('conroo_admin')">
      <span class="bg-greyish pa-2 rounded-lg">
        <v-icon icon="custom:UserFilled" :height="24" :width="24" />
      </span>
      <span class="text-h4 font-weight-medium text-primary ml-2">
        {{ t(groupedItems[3]?.services?.[1]?.name ?? "missing") }}
      </span>
    </TheMainHeader>

    <div>
      <v-container fluid class="px-10">
        <VirtualTable
          :items="filteredUsers"
          :headers="headers"
          :loading="loading"
          :searchable="true"
          @load-more="allLoaded"
        >
          <template #custom-search>
            <v-row justify="space-between">
              <v-col cols="4" align-self="center">
                <v-text-field
                  v-model="searchQuery"
                  variant="outlined"
                  hide-details="auto"
                  :disabled="loading"
                  :label="t('search')"
                  :placeholder="t('userManagement.search_by_email_or_name')"
                  clearable
                />
              </v-col>
              <v-col cols="3" align-self="center">
                <div class="d-flex justify-end">
                  <v-btn
                    style="height: 3.5em"
                    color="primary"
                    append-icon="mdi-plus"
                    class="px-4"
                    rounded="lg"
                    :disabled="loading"
                    @click="dialog = true"
                    :aria-label="t('userManagement.create_webapp_user')"
                  >
                    {{ t("userManagement.create_webapp_user") }}
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </template>
          <template #item.emailVerified="{ value }">
            {{ getEmailVerified(value.emailVerificationStatus) }}
          </template>
          <template #item.createdAt="{ value }">
            {{ formatDate(value.createdDate) }}
          </template>
          <template #item.truckingCompany="{ value }">
            {{ getFirstForwarder(value.forwarders) }}
            <v-tooltip
              v-if="value.forwarders && value.forwarders.length > 1"
              location="bottom"
            >
              <template #activator="{ props }">
                <v-chip v-bind="props" class="cursor-default" size="small">
                  +{{ value.forwarders.length - 1 }}
                </v-chip>
              </template>
              <div
                v-for="(forwarder, index) in value.forwarders"
                :key="forwarder.id"
              >
                <span v-if="index !== 0">{{ forwarder.name }}</span>
              </div>
            </v-tooltip>
          </template>
          <template #item.facility="{ value }">
            {{ getFirstFacility(value.facilities) }}
            <v-tooltip
              v-if="value.facilities && value.facilities.length > 1"
              location="bottom"
            >
              <template #activator="{ props }">
                <v-chip v-bind="props" class="cursor-default" size="small">
                  +{{ value.facilities.length - 1 }}
                </v-chip>
              </template>
              <div
                v-for="(facility, index) in value.facilities"
                :key="facility.id"
              >
                <span v-if="index !== 0">{{ facility.name }}</span>
              </div>
            </v-tooltip>
          </template>
          <template #item.trainOperator="{ value }">
            {{ getFirstTrainOperator(value.trainOperators) }}
            <v-tooltip
              v-if="value.trainOperators && value.trainOperators.length > 1"
              location="bottom"
            >
              <template #activator="{ props }">
                <v-chip v-bind="props" class="cursor-default" size="small">
                  +{{ value.trainOperators.length - 1 }}
                </v-chip>
              </template>
              <div
                v-for="(train, index) in value.trainOperators"
                :key="train.id"
              >
                <span v-if="index !== 0">{{ train.name }}</span>
              </div>
            </v-tooltip>
          </template>
          <template #item.actions="{ value }">
            <v-btn
              @click="navigateToUser(value.id)"
              :icon="'mdi-pencil'"
              variant="text"
              color="primary"
            />
          </template>
        </VirtualTable>
      </v-container>
    </div>

    <CreateUserDialog v-model="dialog" />
  </div>
</template>

<script setup lang="ts">
import TheMainHeader from "@/components/TheMainHeader.vue";
import CreateUserDialog from "@/components/user-management/CreateUserDialog.vue";
import VirtualTable from "@/components/virtual-table/VirtualTable.vue";
import getSidebarItems from "@/constants/sidebar-items";
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useWebappUserLogic } from "./webapp-user-logic";
import { useWebAppUserStore } from "@/store/useWebAppUserStore";
import {
  Facility,
  Forwarder,
  TrainOperator,
  WebappUserResponseTO,
} from "@/services/client/generated";
import { useRouter } from "vue-router";
import { formatDate } from "@/utils/date-utils";
import { VuetifyHeader } from "@/internal-models/vuetify-header";

const { t } = useI18n();
const groupedItems = ref(getSidebarItems());
const webappUserStore = useWebAppUserStore();
const { fetchWebappUsers, fetchModules, loading } = useWebappUserLogic();

const dialog = ref(false);
const searchQuery = ref("");
const router = useRouter();

const USER_HEADERS: VuetifyHeader[] = [
  { title: t("first_name"), value: "firstName", align: "center" },
  { title: t("last_name"), value: "lastName", align: "center" },
  { title: t("email"), value: "email", align: "center" },
  { title: t("created_date"), value: "createdAt", align: "center" },
  { title: t("trucking_company"), value: "truckingCompany", align: "center" },
  { title: t("facility"), value: "facility", align: "center" },
  { title: t("train_operator"), value: "trainOperator", align: "center" },
  { title: t("actions"), value: "actions", sortable: false, align: "center" },
];
const headers = computed(() => USER_HEADERS);

const allLoaded = async (
  done: (status: "loading" | "error" | "empty" | "ok") => void,
) => {
  try {
    if (webappUserStore.sortedWebappUsers) {
      done("empty");
    } else {
      done("ok");
    }
  } catch (_) {
    done("error");
  }
};

const filterUserFields = (user: WebappUserResponseTO, searchQuery: string) => {
  return [user.firstName, user.lastName, user.email].some(field =>
    (field ?? "").toLowerCase().includes(searchQuery.toLowerCase()),
  );
};

const filteredUsers = computed(() => {
  return searchQuery.value
    ? webappUserStore.sortedWebappUsers.filter(user =>
        filterUserFields(user, searchQuery.value),
      )
    : webappUserStore.sortedWebappUsers;
});

const getEmailVerified = (verified: boolean | null) => {
  return verified ? t("verified") : t("not_verified");
};

const getFirstItem = <T extends { name: string }>(
  items: T[],
  defaultValue: string = "-",
): string => {
  return items?.length ? items[0].name : defaultValue;
};

const getFirstForwarder = (forwarders: Forwarder[]) => getFirstItem(forwarders);
const getFirstFacility = (facilities: Facility[]) => getFirstItem(facilities);
const getFirstTrainOperator = (trainOperators: TrainOperator[]) =>
  getFirstItem(trainOperators);

const navigateToUser = (userId: string) => {
  router.push({ name: "webappUserId", params: { id: userId } });
};

onMounted(async () => {
  await fetchWebappUsers();
  await fetchModules();
});
</script>

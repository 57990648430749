<template>
  <TheDialog
    :dialog="dialog"
    :title="
      claimedLicenses ? t('claim_license_successfully') : t('claim_license')
    "
    :submit-text="t('claim_license')"
    :cancel-text="t('cancel')"
    max-width="600px"
    :no-submit-btn="!!claimedLicenses?.length"
    @on-cancel="handleClose"
    @on-close="handleClose"
    @onSubmit="handleSubmit"
  >
    <template v-if="claimedLicenses" #text>
      <div>
        <div
          v-for="claimedLicense in claimedLicenses"
          :key="claimedLicense.licenseKey"
          class="border-b py-3 d-flex justify-space-between"
        >
          <TheText color="black" :text="claimedLicense.licenseKey" />
          <TheText
            v-if="claimedLicense.status === 'CLAIMED'"
            class="text-end"
            color="green"
            :text="t('claimed')"
          />
          <TheText
            v-else-if="claimedLicense.status === 'ALREADY_ASSIGNED'"
            class="text-end"
            color="red"
            :text="t('assigned_to', { name: claimedLicense.assignedTrucker })"
          />
        </div>
        <TheAlert class="mt-5" type="success">
          <template #text>
            <span v-if="claimedLicenses?.length === 1">{{
              t("you_have_claimed_one_license")
            }}</span>
            <span v-else>{{
              t("you_have_claimed_licenses", {
                value: claimedLicenses.length,
              })
            }}</span>
          </template>
        </TheAlert>
      </div>
    </template>

    <template v-else #text>
      {{ t("claim_license_description") }}
      <div class="mt-5">
        <v-text-field
          v-model="licenseOrIdValue"
          :rules="rules"
          :label="t('order_id_or_license_key')"
          :placeholder="t('enter_order_id_or_license_key')"
          variant="outlined"
        />
      </div>
      <div>
        <TheAlert v-if="errorMessage" type="error" :text="errorMessage" />
      </div>
    </template>
  </TheDialog>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import TheDialog from "@/components/dialogs/TheDialog.vue";
import { UseDialogReturn } from "@/composables/useDialog";
import { ruleLicenseOrOrderId } from "@/utils/vuetify-rules";
import TheAlert from "@/components/TheAlert.vue";
import { ref, computed } from "vue";
import { ClaimedLicense, ClaimLicenseError } from "@/models/license-management";
import TheText from "@/components/TheText.vue";
import { getTranslatedErrorMessage } from "@/utils/translations-utils";

interface Props {
  dialog: UseDialogReturn<null, ClaimLicenseError>;
  claimedLicenses?: Array<ClaimedLicense>;
}

const emit = defineEmits(["onSubmit"]);
const props = defineProps<Props>();
const { t } = useI18n();

const licenseOrIdValue = ref("");

const errorMessage = computed<string | undefined>(() => {
  const codeErrorMap: Record<ClaimLicenseError["code"], string> = {
    NOT_BELONG_ORGANIZATION: t("license_not_belong_organisation"),
    request_invalid: t("order_id_or_license_not_exist"),
    license_not_found: t("license_not_found", {
      value: licenseOrIdValue.value,
    }),
  };

  const error = props.dialog.error;

  if (error.value)
    return (
      codeErrorMap[error.value?.code] ??
      getTranslatedErrorMessage(t, error.value?.code)
    );
  return undefined;
});

const rules = [ruleLicenseOrOrderId(t("license_claim_invalid_format"))];

function handleClose() {
  licenseOrIdValue.value = "";
}

function handleSubmit() {
  emit("onSubmit", licenseOrIdValue.value);
}
</script>

<template>
  <v-breadcrumbs class="pl-0">
    <template #prepend>
      <v-icon icon="mdi-home" />
    </template>
    <span v-for="(item, index) in defaultBreadcrumbs" :key="item.text">
      <v-breadcrumbs-item :to="item.to" :disabled="item.disabled" exact>
        {{ item.text }}
      </v-breadcrumbs-item>

      <v-breadcrumbs-divider
        v-if="index !== defaultBreadcrumbs.length - 1"
        divider=">"
      />
    </span>
  </v-breadcrumbs>
</template>

<script setup lang="ts">
import { BreadcrumbItem } from "@/models/breadcrumbs";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";

const props = defineProps<{
  dynamicData?: Record<string, string>;
}>();

const route = useRoute();
const { t } = useI18n();

const defaultBreadcrumbs = computed<BreadcrumbItem[]>(() => {
  const breadcrumbs: BreadcrumbItem[] = [];

  route.matched.forEach((matchedRoute, index) => {
    if (!matchedRoute.meta.breadcrumb) return;

    const navigateToRoute = () => {
      if (matchedRoute.path.includes(`:id`)) {
        return matchedRoute.path.replace(`:id`, "");
      } else if (
        index < route.matched.length - 1 &&
        !matchedRoute.path.includes(":")
      ) {
        return route.fullPath;
      } else {
        return undefined;
      }
    };

    const isDisabled = () => {
      if (matchedRoute.path.includes(`:id`)) {
        return false;
      } else if (
        index < route.matched.length - 1 &&
        !matchedRoute.path.includes(":")
      ) {
        return false;
      } else {
        return true;
      }
    };

    breadcrumbs.push({
      text: t(matchedRoute.meta.breadcrumb as string),
      to: navigateToRoute(),
      disabled: isDisabled(),
    });

    Object.keys(route.params).forEach(param => {
      if (
        matchedRoute.path.includes(`:${param}`) &&
        props.dynamicData?.[param]
      ) {
        breadcrumbs.push({
          text: props.dynamicData[param],
          to: index < route.matched.length - 1 ? route.fullPath : undefined,
          disabled: index === route.matched.length - 1,
        });
      }
    });
  });

  breadcrumbs.unshift({ text: t("home"), to: "/" });

  return breadcrumbs;
});
</script>

<style scoped></style>

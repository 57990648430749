<template>
  <v-dialog max-width="90vw" max-height="800" min-height="200">
    <v-card>
      <v-card-title class="text-center text-h4">
        {{
          $t("userManagement.all_changes_to_user", {
            name: `${user.firstName} ${user.lastName}`,
          })
        }}
      </v-card-title>

      <div class="text-left px-10">
        {{ $t("userManagement.user_id", { id: user.userId }) }}
      </div>
      <v-card-text class="mt-8">
        <HistoryTable
          v-if="user.changes"
          @approve="sendApproval"
          :changes="user.changes"
        />
      </v-card-text>

      <v-card-actions>
        <v-btn @click="closeDialog" color="info" variant="tonal">
          {{ $t("close_dialog") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts" setup>
import { IspsTruckerTO } from "@/services/client/generated";
import HistoryTable from "@/components/user-management/HistoryTable.vue";
import { ref } from "vue";

defineProps<{
  user: IspsTruckerTO;
}>();

const dialog = ref(false);

const emit = defineEmits<{
  approve: [payload: { approve: boolean; id: number }];
  close: [close: boolean];
}>();

const sendApproval = (payload: { approve: boolean; id: number }) => {
  closeDialog();
  emit("approve", payload);
};

const closeDialog = () => {
  dialog.value = false;
  emit("close", true);
};
</script>

import { defineStore } from "pinia";
import { ref, computed } from "vue";
import {
  Forwarder,
  TruckerForForwarderTO,
} from "@/services/client/generated/api";
import {
  forwarderOperatorClient,
  forwarderTruckersClient,
} from "@/services/client/configs/services";

export const useForwarderStore = defineStore(
  "forwarderStore",
  () => {
    const forwarder = ref<Forwarder | null>(null);
    const forwarders = ref<Forwarder[] | null>(null);
    const truckersForForwarder = ref<TruckerForForwarderTO[] | null>(null);

    const forwarderData = computed(() => forwarder.value);
    const forwardersData = computed(() => forwarders.value);

    const setForwarder = (newForwarder: Forwarder | null) => {
      forwarder.value = newForwarder;
    };

    const setForwarders = (newForwarders: Forwarder[]) => {
      forwarders.value = newForwarders;
    };

    const setForwarderData = (
      newForwarder: Forwarder | null,
    ): Forwarder | null => {
      if (newForwarder) {
        setForwarder(newForwarder);
        return newForwarder;
      }
      return null;
    };

    const getForwarder = (): Forwarder | null => {
      if (forwarder.value) {
        return forwarder.value;
      }

      const savedForwarder = localStorage.getItem("forwarder");
      if (savedForwarder) {
        forwarder.value = JSON.parse(savedForwarder);
        return forwarder.value;
      }
      return null;
    };

    const getForwarders = async (userId: string): Promise<Forwarder[]> => {
      const response = await forwarderOperatorClient.getAllForwarders(userId);
      setForwarders(response.data);
      return response.data;
    };

    const getTruckersForForwarder = async ({
      userId,
      forwarderId,
      page,
      size,
      search,
      forAssignment,
    }: {
      userId: string;
      forwarderId: number;
      page?: number;
      size?: number;
      search?: string;
      forAssignment?: boolean;
    }) => {
      const result = await forwarderTruckersClient.getAllTruckersForForwarder(
        userId,
        forwarderId,
        {
          search,
          ...(forAssignment && { licenseAssignedToTrucker: "ASSIGNED" }),
        },
        page,
        size,
        { method: "POST" },
      );
      if (page === 0) {
        truckersForForwarder.value = result.data;
      } else {
        truckersForForwarder.value = [
          ...(truckersForForwarder.value ?? []),
          ...result.data,
        ];
      }
      return result.data;
    };

    function clearTruckersForForwarder() {
      truckersForForwarder.value = [];
    }

    async function assignLicense(payload: {
      userId: string;
      forwarderId: number;
      licenseUserId: string;
    }) {
      const result =
        await forwarderTruckersClient.assignCompanyLicenseToTrucker(
          payload.userId,
          payload.forwarderId,
          {
            licenseUserId: payload.licenseUserId,
          },
        );
      return result.data;
    }

    return {
      forwarder,
      forwarders,
      forwarderData,
      forwardersData,
      truckersForForwarder,
      setForwarder,
      setForwarders,
      setForwarderData,
      getForwarder,
      getForwarders,
      getTruckersForForwarder,
      clearTruckersForForwarder,
      assignLicense,
    };
  },
  {
    persist: true,
  },
);

<template>
  <TheDialog
    :dialog="dialog"
    :title="t('how_many_licenses')"
    :submit-text="t('purchase')"
    :cancel-text="t('cancel')"
    max-width="600px"
    @onSubmit="handleSubmit"
    @onCancel="handleCancel"
  >
    <template #text>
      <v-text-field
        v-model="sanitizedQuantity"
        min="1"
        :rules="rules"
        variant="outlined"
        type="number"
        :label="t('quantity')"
      />

      <TheAlert v-if="errorMessage" type="error" :text="errorMessage" />
    </template>
  </TheDialog>
</template>

<script setup lang="ts">
import TheDialog from "@/components/dialogs/TheDialog.vue";
import { UseDialogReturn } from "@/composables/useDialog";
import { useI18n } from "vue-i18n";
import { computed, ref } from "vue";
import { ruleRequired } from "@/utils/vuetify-rules";
import TheAlert from "@/components/TheAlert.vue";
import { getTranslatedErrorMessage } from "@/utils/translations-utils";

interface Props {
  dialog: UseDialogReturn;
}

const i18n = useI18n();

const rules = [ruleRequired(i18n.t("fieldRequired"))];

const emit = defineEmits(["onSubmit"]);
const props = defineProps<Props>();

const sanitizedQuantity = computed({
  get: () => quantity.value,
  set: val => {
    quantity.value = val.replace(/[^0-9]/g, "");
  },
});

const errorMessage = computed<string | undefined>(() => {
  const error = props.dialog.error;

  if (error.value) return getTranslatedErrorMessage(t, error.value?.code);
  return undefined;
});

const { t } = useI18n();
const quantity = ref("");

function handleSubmit() {
  emit("onSubmit", quantity.value);
}

function handleCancel() {
  quantity.value = "";
}
</script>

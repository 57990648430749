<template>
  <v-row>
    <v-col cols="4" md="5">
      <div class="text-left">
        <h3>{{ t("userManagement.roles.title") }}</h3>
        <p>
          {{ t("userManagement.roles.description") }}
        </p>
      </div>
    </v-col>

    <v-col cols="8" md="7">
      <v-card class="pa-5" :loading="loading" :disabled="loading">
        <div class="d-flex align-center justify-center py-2">
          <v-row>
            <v-col cols="12">
              <v-tooltip v-if="isTruckingCompanyDisabled" location="bottom">
                <template #activator="{ props: activatorProps }">
                  <div v-bind="activatorProps">
                    <v-select
                      :model-value="truckingCompany"
                      :items="formattedTruckingCompanyRoles"
                      item-title="text"
                      item-value="value"
                      :label="
                        t('userManagement.roles.select_trucking_company_role')
                      "
                      hide-details="auto"
                      variant="outlined"
                      :disabled="isTruckingCompanyDisabled"
                      @update:model-value="onTruckingCompanyChange"
                    />
                  </div>
                </template>
                <span>
                  {{ t("userManagement.roles.no_trucking_company_ids") }}
                </span>
              </v-tooltip>
              <v-select
                v-else
                :model-value="truckingCompany"
                :items="formattedTruckingCompanyRoles"
                item-title="text"
                item-value="value"
                :label="t('userManagement.roles.select_trucking_company_role')"
                hide-details="auto"
                variant="outlined"
                :disabled="isTruckingCompanyDisabled"
                @update:model-value="onTruckingCompanyChange"
              />
            </v-col>

            <v-col cols="12">
              <v-tooltip v-if="isFacilityOperatorDisabled" location="bottom">
                <template #activator="{ props: activatorProps }">
                  <div v-bind="activatorProps">
                    <v-select
                      :model-value="facilityOperator"
                      :items="formattedFacilityOperatorRoles"
                      item-title="text"
                      item-value="value"
                      :label="
                        t('userManagement.roles.select_facility_operator_role')
                      "
                      hide-details="auto"
                      variant="outlined"
                      :disabled="isFacilityOperatorDisabled"
                    />
                  </div>
                </template>
                <span>{{ t("userManagement.roles.no_facility_ids") }}</span>
              </v-tooltip>
              <v-select
                v-else
                :model-value="facilityOperator"
                :items="formattedFacilityOperatorRoles"
                item-title="text"
                item-value="value"
                :label="t('userManagement.roles.select_facility_operator_role')"
                hide-details="auto"
                variant="outlined"
                :disabled="isFacilityOperatorDisabled"
                @update:model-value="onFacilityOperatorChange"
              />
            </v-col>

            <v-col cols="12">
              <v-tooltip v-if="isTrainOperatorDisabled" location="bottom">
                <template #activator="{ props: activatorProps }">
                  <div v-bind="activatorProps">
                    <v-select
                      :model-value="trainOperator"
                      :items="formattedTrainOperatorRoles"
                      item-title="text"
                      item-value="value"
                      :label="
                        t('userManagement.roles.select_train_operator_role')
                      "
                      hide-details="auto"
                      variant="outlined"
                      :disabled="isTrainOperatorDisabled"
                    />
                  </div>
                </template>
                <span>
                  {{ t("userManagement.roles.no_train_operator_ids") }}
                </span>
              </v-tooltip>
              <v-select
                v-else
                :model-value="trainOperator"
                :items="formattedTrainOperatorRoles"
                item-title="text"
                item-value="value"
                :label="t('userManagement.roles.select_train_operator_role')"
                hide-details="auto"
                variant="outlined"
                :disabled="isTrainOperatorDisabled"
                @update:model-value="onTrainOperatorChange"
              />
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-col>
    <v-dialog v-model="confirmationDialog" max-width="500">
      <v-card>
        <v-card-title>
          {{ t("userManagement.roles.confirmation_title") }}
        </v-card-title>
        <v-card-text>
          <i18n-t
            keypath="userManagement.roles.confirmation_message"
            tag="label"
            for="module"
          >
            {{ getModuleMessage() }}
          </i18n-t>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="confirmationDialog = false">
            {{ t("cancel") }}
          </v-btn>
          <v-btn @click="confirmRoleChange" color="primary" variant="elevated">
            {{ t("confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script setup lang="ts">
import {
  UpdateWebappUserRequestTO,
  UpdateWebappUserRequestTOFacilityOperatorRoleEnum,
  UpdateWebappUserRequestTOTrainOperatorRoleEnum,
  UpdateWebappUserRequestTOTruckingCompanyRoleEnum,
  WebappUserResponseTORolesEnum,
  WebappUserResponseTO,
} from "@/services/client/generated";
import {
  defineProps,
  defineEmits,
  watch,
  toRefs,
  ref,
  onMounted,
  PropType,
  computed,
} from "vue";
import { useI18n } from "vue-i18n";
import { useWebappUserLogic } from "../webapp-user-logic";

const props = defineProps({
  loading: {
    type: Boolean,
    required: true,
  },
  originalUser: {
    type: Object as PropType<WebappUserResponseTO>,
    required: true,
  },
  user: {
    type: Object as PropType<UpdateWebappUserRequestTO>,
    required: true,
  },
  truckingCompanyIds: {
    type: Array<number>,
    required: true,
  },
  facilityIds: {
    type: Array<number>,
    required: true,
  },
  facilityCompanyIds: {
    type: Array<number>,
    required: true,
  },
  trainOperatorIds: {
    type: Array<number>,
    required: true,
  },
});

const emit = defineEmits<{
  (e: "update", updatedRoles: Partial<UpdateWebappUserRequestTO>): void;
}>();

const { t } = useI18n();
const {
  formattedFacilityOperatorRoles,
  formattedTrainOperatorRoles,
  formattedTruckingCompanyRoles,
} = useWebappUserLogic();

const { originalUser, user } = toRefs(props);

const truckingCompany = ref<UpdateWebappUserRequestTOTruckingCompanyRoleEnum>(
  UpdateWebappUserRequestTOTruckingCompanyRoleEnum.None,
);
const facilityOperator = ref<UpdateWebappUserRequestTOFacilityOperatorRoleEnum>(
  UpdateWebappUserRequestTOFacilityOperatorRoleEnum.None,
);
const trainOperator = ref<UpdateWebappUserRequestTOTrainOperatorRoleEnum>(
  UpdateWebappUserRequestTOTrainOperatorRoleEnum.None,
);

const confirmationDialog = ref(false);
const roleToRemove = ref<"trucking" | "facility" | "train">();

const getModuleMessage = () => {
  if (roleToRemove.value === "trucking") {
    return t("userManagement.roles.trucking_companies");
  } else if (roleToRemove.value === "facility") {
    return t("userManagement.roles.facilities");
  } else if (roleToRemove.value === "train") {
    return t("userManagement.roles.train_operators");
  }
};

const isTruckingCompanyDisabled = computed(
  () => !(user.value.truckingCompanyIds ?? []).length,
);

const isFacilityOperatorDisabled = computed(
  () =>
    !user.value.facilityCompanyIds?.length && !user.value.facilityIds?.length,
);

const isTrainOperatorDisabled = computed(
  () => !(user.value.trainOperatorIds ?? []).length,
);

const showConfirmationDialog = (role: "trucking" | "facility" | "train") => {
  roleToRemove.value = role;
  confirmationDialog.value = true;
};

const mapRole = (
  roles: WebappUserResponseTORolesEnum[],
  roleMapping: string[],
  defaultRole: string,
) => {
  if (!roles.length) {
    return defaultRole;
  }
  const setRoles = roles.find(role => {
    const roleStr = role.toString();
    return roleMapping.includes(roleStr);
  });

  return setRoles || defaultRole;
};

const onTruckingCompanyChange = (newRole: string) => {
  if (newRole === UpdateWebappUserRequestTOTruckingCompanyRoleEnum.None) {
    showConfirmationDialog("trucking");
  } else {
    emit("update", {
      truckingCompanyRole:
        newRole as UpdateWebappUserRequestTOTruckingCompanyRoleEnum,
    });
    truckingCompany.value =
      newRole as UpdateWebappUserRequestTOTruckingCompanyRoleEnum;
  }
};

const onFacilityOperatorChange = (newRole: string) => {
  if (newRole === UpdateWebappUserRequestTOFacilityOperatorRoleEnum.None) {
    showConfirmationDialog("facility");
  } else {
    emit("update", {
      facilityOperatorRole:
        newRole as UpdateWebappUserRequestTOFacilityOperatorRoleEnum,
    });
    facilityOperator.value =
      newRole as UpdateWebappUserRequestTOFacilityOperatorRoleEnum;
  }
};

const onTrainOperatorChange = (newRole: string) => {
  if (newRole === UpdateWebappUserRequestTOTrainOperatorRoleEnum.None) {
    showConfirmationDialog("train");
  } else {
    emit("update", {
      trainOperatorRole:
        newRole as UpdateWebappUserRequestTOTrainOperatorRoleEnum,
    });
    trainOperator.value =
      newRole as UpdateWebappUserRequestTOTrainOperatorRoleEnum;
  }
};

const confirmRoleChange = () => {
  if (roleToRemove.value === "trucking") {
    emit("update", {
      truckingCompanyRole:
        UpdateWebappUserRequestTOTruckingCompanyRoleEnum.None,
      truckingCompanyIds: [],
    });
  } else if (roleToRemove.value === "facility") {
    emit("update", {
      facilityOperatorRole:
        UpdateWebappUserRequestTOFacilityOperatorRoleEnum.None,
      facilityIds: [],
      facilityCompanyIds: [],
    });
  } else if (roleToRemove.value === "train") {
    emit("update", {
      trainOperatorRole: UpdateWebappUserRequestTOTrainOperatorRoleEnum.None,
      trainOperatorIds: [],
    });
  }
  confirmationDialog.value = false;
};

const assignTruckingCompanyRole = (user: UpdateWebappUserRequestTO) => {
  if (
    truckingCompany.value ===
      UpdateWebappUserRequestTOTruckingCompanyRoleEnum.None &&
    user.truckingCompanyIds?.length
  ) {
    truckingCompany.value = user.truckingCompanyIds?.length
      ? UpdateWebappUserRequestTOTruckingCompanyRoleEnum.ForwarderRead
      : UpdateWebappUserRequestTOTruckingCompanyRoleEnum.None;
  } else if (
    truckingCompany.value !==
      UpdateWebappUserRequestTOTruckingCompanyRoleEnum.None &&
    !user.truckingCompanyIds?.length
  ) {
    truckingCompany.value =
      UpdateWebappUserRequestTOTruckingCompanyRoleEnum.None;
  } else {
    truckingCompany.value = user.truckingCompanyRole
      ? user.truckingCompanyRole
      : UpdateWebappUserRequestTOTruckingCompanyRoleEnum.None;
  }
};

const assignFacilityOperatorRole = (user: UpdateWebappUserRequestTO) => {
  if (
    facilityOperator.value ===
      UpdateWebappUserRequestTOFacilityOperatorRoleEnum.None &&
    (user.facilityCompanyIds?.length || user.facilityIds?.length)
  ) {
    facilityOperator.value =
      user.facilityCompanyIds?.length || user.facilityIds?.length
        ? UpdateWebappUserRequestTOFacilityOperatorRoleEnum.TerminalOperationsRead
        : UpdateWebappUserRequestTOFacilityOperatorRoleEnum.None;
  } else if (
    facilityOperator.value !==
      UpdateWebappUserRequestTOFacilityOperatorRoleEnum.None &&
    !user.facilityCompanyIds?.length &&
    !user.facilityIds?.length
  ) {
    facilityOperator.value =
      UpdateWebappUserRequestTOFacilityOperatorRoleEnum.None;
  }
};

const assignTrainOperatorRole = (user: UpdateWebappUserRequestTO) => {
  if (
    trainOperator.value ===
      UpdateWebappUserRequestTOTrainOperatorRoleEnum.None &&
    user.trainOperatorIds?.length
  ) {
    trainOperator.value = user.trainOperatorIds?.length
      ? UpdateWebappUserRequestTOTrainOperatorRoleEnum.TrainOperatorRead
      : UpdateWebappUserRequestTOTrainOperatorRoleEnum.None;
  } else if (
    trainOperator.value !==
      UpdateWebappUserRequestTOTrainOperatorRoleEnum.None &&
    !user.trainOperatorIds?.length
  ) {
    trainOperator.value = UpdateWebappUserRequestTOTrainOperatorRoleEnum.None;
  } else {
    trainOperator.value = user.trainOperatorRole
      ? user.trainOperatorRole
      : UpdateWebappUserRequestTOTrainOperatorRoleEnum.None;
  }
};

watch(
  () => props.truckingCompanyIds,
  () => {
    assignTruckingCompanyRole(props.user);
  },
  {
    deep: true,
  },
);

watch(
  () => [props.facilityIds, props.facilityCompanyIds],
  () => {
    assignFacilityOperatorRole(props.user);
  },
  {
    deep: true,
  },
);

watch(
  () => props.trainOperatorIds,
  () => {
    assignTrainOperatorRole(props.user);
  },
  {
    deep: true,
  },
);

watch(facilityOperator, (newRole, oldRole) => {
  if (newRole !== oldRole) {
    emit("update", {
      facilityOperatorRole:
        newRole as UpdateWebappUserRequestTOFacilityOperatorRoleEnum,
    });
  }
});
watch(truckingCompany, (newRole, oldRole) => {
  if (newRole !== oldRole) {
    emit("update", {
      truckingCompanyRole:
        newRole as UpdateWebappUserRequestTOTruckingCompanyRoleEnum,
    });
  }
});
watch(trainOperator, (newRole, oldRole) => {
  if (newRole !== oldRole) {
    emit("update", {
      trainOperatorRole:
        newRole as UpdateWebappUserRequestTOTrainOperatorRoleEnum,
    });
  }
});

onMounted(() => {
  if (originalUser.value && originalUser.value.roles) {
    const roles = originalUser.value.roles;

    truckingCompany.value = mapRole(
      roles,
      formattedTruckingCompanyRoles.map(company => company.value),
      UpdateWebappUserRequestTOTruckingCompanyRoleEnum.None,
    ) as UpdateWebappUserRequestTOTruckingCompanyRoleEnum;

    facilityOperator.value = mapRole(
      roles,
      formattedFacilityOperatorRoles.map(facility => facility.value),
      UpdateWebappUserRequestTOFacilityOperatorRoleEnum.None,
    ) as UpdateWebappUserRequestTOFacilityOperatorRoleEnum;

    trainOperator.value = mapRole(
      roles,
      formattedTrainOperatorRoles.map(train => train.value),
      UpdateWebappUserRequestTOTrainOperatorRoleEnum.None,
    ) as UpdateWebappUserRequestTOTrainOperatorRoleEnum;
  }
});
</script>

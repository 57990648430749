<template>
  <span :class="[vColorClass, vFontSize, vBoldClass]">
    <slot>
      {{ text }}
    </slot>
  </span>
</template>

<script setup lang="ts">
import { computed } from "vue";

interface Props {
  color?: string;
  text?: string;
  size?: "14" | "16";
  bold?: boolean;
}

const { color, text, bold, size = "16" } = defineProps<Props>();

// Add support for hex colors if needed;
// This only works for colors specified in Vuetify.ts
const vColorClass = computed(() => {
  if (!color || color.startsWith("#")) return "";
  return `text-${color}`;
});

const vFontSize = computed(() => {
  switch (size) {
    case "14":
      return "text-subtitle-2";
    case "16":
      return "text-body-1";
    default:
      return "";
  }
});

const vBoldClass = computed(() => {
  if (!bold) return "";
  return "font-weight-bold";
});
</script>

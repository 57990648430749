<template>
  <div v-if="company && originalCompany">
    <TheMainHeader
      class="text-left pl-10 pt-10"
      :text="t('conroo_admin')"
      :dynamic-data="{ id: originalCompany.name }"
    >
      <span class="bg-greyish pa-2 rounded-lg">
        <v-icon icon="custom:TruckIcon" :height="24" :width="24" />
      </span>
      <span class="text-h4 font-weight-medium text-primary ml-2">
        {{ originalCompany.name }}
      </span>
      <template #right-anchor>
        <div class="text-h4 text-primary pr-10">
          <v-btn
            class="mr-4"
            variant="text"
            append-icon="mdi-delete-outline"
            :disabled="!hasChanges || loading"
            @click="discardChanges"
          >
            {{ t("discard_changes") }}
          </v-btn>
          <v-btn
            append-icon="mdi-content-save-outline"
            variant="tonal"
            :disabled="!hasChanges || loading"
            @click="saveTruckingCompany"
          >
            {{ t("save_changes") }}
          </v-btn>
        </div>
      </template>
    </TheMainHeader>

    <div class="container py-16">
      <section class="mb-4">
        <GeneralInformationSection
          :company="company"
          :loading="loading"
          @update="updateCompany"
        />
      </section>
      <section>
        <TierSection
          :company="company"
          :loading="loading"
          @update="updateCompany"
        />
      </section>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from "vue";
import TheMainHeader from "@/components/TheMainHeader.vue";
import { useRoute, useRouter } from "vue-router";
import GeneralInformationSection from "./sections/GeneralInformationSection.vue";
import { useI18n } from "vue-i18n";
import TierSection from "./sections/TierSection.vue";
import {
  TruckingCompanyResponseTO,
  UpdateTruckingCompanyRequestTO,
} from "@/services/client/generated";
import { useTruckingCompanyStore } from "@/store/useTruckingCompanyStore";
import { useTruckingCompaniesLogic } from "./trucking-companies-logic";
import { merge } from "lodash";

const route = useRoute();
const router = useRouter();
const truckingCompanyStore = useTruckingCompanyStore();
const { loading, updateTruckingCompany } = useTruckingCompaniesLogic();

const { t } = useI18n();
const company = ref<UpdateTruckingCompanyRequestTO>();
const originalCompany = ref<TruckingCompanyResponseTO>();

const updateCompany = (
  updatedData: Partial<UpdateTruckingCompanyRequestTO>,
) => {
  if (company.value) {
    merge(company.value, updatedData);
  }
};

const hasFieldChanges = (fields: (keyof UpdateTruckingCompanyRequestTO)[]) => {
  return fields.some(field => {
    const companyField = company.value![field];
    const originalField =
      originalCompany.value![field as keyof TruckingCompanyResponseTO];
    return companyField !== originalField;
  });
};

const hasChanges = computed(() =>
  hasFieldChanges([
    "name",
    "email",
    "vatId",
    "streetAddress",
    "city",
    "zipCode",
    "country",
    "tier",
  ]),
);

const updateOriginalCompany = () => {
  originalCompany.value = truckingCompanyStore.sortedTruckingCompanies.find(
    u => u.id.toString() === route.params.id,
  );
  if (!originalCompany.value) {
    router.push("/admin/truckingCompanies");
  } else {
    company.value = {
      id: originalCompany.value.id,
      createdDate: originalCompany.value.createdDate,
      name: originalCompany.value.name,
      email: originalCompany.value.email,
      vatId: originalCompany.value.vatId,
      streetAddress: originalCompany.value.streetAddress,
      city: originalCompany.value.city,
      zipCode: originalCompany.value.zipCode,
      country: originalCompany.value.country,
      tier: originalCompany.value.tier,
    };
  }
};

const saveTruckingCompany = async () => {
  if (company.value && originalCompany.value) {
    const updatedCompany = await updateTruckingCompany(company.value);
    originalCompany.value = updatedCompany;
  }
};

const discardChanges = () => {
  if (company.value && originalCompany.value) {
    company.value = {
      id: originalCompany.value.id,
      createdDate: originalCompany.value.createdDate,
      name: originalCompany.value.name,
      email: originalCompany.value.email,
      vatId: originalCompany.value.vatId,
      streetAddress: originalCompany.value.streetAddress,
      city: originalCompany.value.city,
      zipCode: originalCompany.value.zipCode,
      country: originalCompany.value.country,
      tier: originalCompany.value.tier,
    };
  }
};

onMounted(() => {
  updateOriginalCompany();
});
</script>

<style scoped lang="css">
.container {
  max-width: 70vw;
  margin: 0 auto;
}
</style>

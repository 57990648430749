<template>
  <div class="navbar">
    <v-navigation-drawer :width="65" permanent>
      <div @click="router.push('/')" class="cursor-pointer">
        <v-img :src="ConrooLogo" :height="46" cover />
      </div>

      <v-divider class="mx-2" />

      <v-list
        variant="flat"
        class="pt-10"
        nav
        :items="navigation.groupedItems.value"
      >
        <ListItem
          v-for="(item, i) in navigation.groupedItems.value"
          v-show="navigation.hasPermission(item.roles)"
          @click="navigation.handleItemClick(item)"
          :item="item"
          :key="i"
        />
      </v-list>
      <template #append>
        <v-list nav>
          <LanguageSwitch />

          <v-list-item
            class="pa-0 pl-3"
            prepend-icon="mdi-help-circle-outline"
            base-color="primary"
            @click="navigation.goToSupport"
            variant="text"
          />

          <v-list-item
            @click="navigation.logOut"
            prepend-icon="mdi-logout"
            :title="$t('logout')"
            data-testid="test-logout"
            base-color="primary"
            class="pa-0 pl-3"
            variant="text"
          />
        </v-list>
      </template>
    </v-navigation-drawer>
    <v-navigation-drawer
      v-model="navigation.drawer.value"
      expand-on-hover
      :width="drawerWidth"
    >
      <template v-if="navigation.selectedItem.value">
        <div
          v-if="
            navigation.selectedItem.value.pageName ===
            PAGE_NAMES.TERMINAL_OPERATOR
          "
        >
          <p class="text-primary font-weight-medium pt-2">
            {{ $t("terminalOperator") }}
          </p>
          <div>
            <TerminalDropdown
              v-if="
                navigation.hasPermission(
                  navigation.groupedItems.value[0].roles,
                ) ||
                navigation.hasPermission(navigation.groupedItems.value[1].roles)
              "
              class=""
              data-testid="test-TerminalDropdown"
            />
          </div>
          <div>
            <p class="text-primary font-weight-medium pt-2 pb-4">
              {{ $t("services") }}
            </p>
            <v-list
              v-for="service in navigation.groupedItems.value[0].services"
              :key="service.name"
              class="pa-1"
            >
              <v-list-item
                base-color="primary"
                active-class="active"
                class="d-flex list-item mx-3 rounded-lg cursor-pointer"
                :to="service.pagePath"
                :replace="true"
              >
                <template #prepend>
                  <div class="mr-2">
                    <v-icon :icon="navigation.getIcon(service)"></v-icon>
                  </div>
                </template>
                <v-list-item-title
                  class="font-weight-medium text-white"
                  :class="serviceTitleClass(service.pagePath)"
                >
                  {{ t(service.name) }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </div>
        </div>
        <div
          v-else-if="
            navigation.selectedItem.value.pageName === PAGE_NAMES.TRAIN_OPERATOR
          "
        >
          <p class="text-primary font-weight-medium pt-2">
            {{ $t("trainOperator") }}
          </p>
          <div>
            <TerminalDropdown
              v-if="
                navigation.hasPermission(
                  navigation.groupedItems.value[0].roles,
                ) ||
                navigation.hasPermission(navigation.groupedItems.value[1].roles)
              "
              data-testid="test-TerminalDropdown"
            />
          </div>

          <div>
            <p class="text-primary font-weight-medium pt-2 pb-4">
              {{ $t("services") }}
            </p>
            <v-list
              v-for="service in navigation.groupedItems.value[1].services"
              :key="service.name"
              class="pa-1"
            >
              <v-list-item
                base-color="primary"
                active-class="active"
                class="d-flex list-item mx-3 rounded-lg cursor-pointer"
                :to="service.pagePath"
                :replace="true"
              >
                <template #prepend>
                  <div class="mr-2">
                    <v-icon :icon="navigation.getIcon(service)"></v-icon>
                  </div>
                </template>
                <v-list-item-title
                  class="font-weight-medium"
                  :class="serviceTitleClass(service.pagePath)"
                >
                  {{ t(service.name) }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </div>
        </div>
        <div
          v-else-if="
            navigation.selectedItem.value.pageName ===
            PAGE_NAMES.FORWARDER_OPERATOR
          "
        >
          <p class="text-primary font-weight-medium pt-2">
            {{ $t("forwarderOperator") }}
          </p>
          <div>
            <ForwarderDropdown
              v-if="
                navigation.hasPermission(navigation.groupedItems.value[2].roles)
              "
              data-testid="test-ForwarderDropdown"
              class="mt-6"
            />
          </div>
          <div>
            <p class="text-primary font-weight-medium pt-2 pb-4">
              {{ $t("services") }}
            </p>
            <v-list
              v-for="service in navigation.groupedItems.value[2].services"
              :key="service.name"
              class="pa-1"
            >
              <v-list-item
                base-color="primary"
                active-class="active"
                class="d-flex list-item mx-3 rounded-lg cursor-pointer"
                :to="service.pagePath"
                :replace="true"
              >
                <template #prepend>
                  <div class="mr-2">
                    <v-icon :icon="navigation.getIcon(service)"></v-icon>
                  </div>
                </template>
                <v-list-item-title
                  class="font-weight-medium"
                  :class="serviceTitleClass(service.pagePath)"
                >
                  {{ t(service.name) }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </div>
        </div>
        <div
          v-else-if="
            navigation.selectedItem.value.pageName === PAGE_NAMES.ADMIN
          "
        >
          <p class="text-primary text-h5 font-weight-medium pt-2">
            {{ $t("admin") }}
          </p>
          <p class="text-primary font-weight-medium pt-2 pb-4">
            {{ $t("services") }}
          </p>
          <div>
            <v-list
              v-for="service in navigation.groupedItems.value[3].services"
              :key="service.name"
              class="pa-1"
            >
              <v-list-item
                base-color="primary"
                active-class="active"
                class="d-flex list-item mx-3 rounded-lg cursor-pointer"
                :to="service.pagePath"
                :replace="true"
              >
                <template #prepend>
                  <div class="mr-2">
                    <v-icon :icon="navigation.getIcon(service)"></v-icon>
                  </div>
                </template>
                <v-list-item-title
                  class="font-weight-medium"
                  :class="serviceTitleClass(service.pagePath)"
                >
                  {{ t(service.name) }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </div>
        </div>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script setup lang="ts">
import useNavigationLogic from "@/layouts/navigation-bar/navbar-logic";
import ConrooLogo from "@/assets/main/conroo-logo.svg";
import ListItem from "@/layouts/navigation-bar/NavigationListItem.vue";
import TerminalDropdown from "@/layouts/navigation-bar/TerminalDropdown.vue";
import ForwarderDropdown from "@/layouts/navigation-bar/ForwarderDropdown.vue";
import LanguageSwitch from "@/components/LanguageSwitch.vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import PAGE_NAMES from "@/internal-models/enums/page-names";
import { computed } from "vue";

const router = useRouter();
const { t } = useI18n();
const navigation = useNavigationLogic();

const serviceTitleClass = (pagePath: string) =>
  navigation.isActiveRoute(pagePath) ? "text-white" : "text-primary";

const drawerWidth = computed(() => {
  if (!navigation.drawer.value) {
    return 260;
  }

  let width = 260;

  const longestTextLength = navigation.groupedItems.value[0].services?.reduce(
    (max, service) => Math.max(max, t(service.name).length),
    0,
  );

  if (longestTextLength && longestTextLength > 18) {
    width = 260 + (longestTextLength + 5);
  }

  return width.toString();
});
</script>

<style lang="css">
.list-item {
  background-color: rgb(var(--v-theme-greyish));
  padding: 0 0 0 1rem;

  &.active {
    background-color: rgb(var(--v-theme-primary));
  }
}

.drawer {
  min-width: 20vw;
}

.navbar {
  background-color: #f7f7f7;
}
</style>

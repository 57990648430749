<template>
  <v-dialog
    :model-value="dialog.isOpen.value"
    v-bind="optionals"
    :min-width="minWidth"
    :max-width="maxWidth"
    @update:model-value="handleClose"
  >
    <slot>
      <v-form ref="formRef" @submit.prevent>
        <v-card>
          <v-card-title class="font-weight-bold px-6 py-4">
            <slot name="title">
              {{ title }}
            </slot>
          </v-card-title>
          <v-card-text v-if="text || $slots.text" class="px-6 pb-0 pt-0">
            <slot name="text">
              {{ text }}
            </slot>
          </v-card-text>
          <v-card-actions class="px-6 py-4">
            <slot name="actions">
              <v-spacer></v-spacer>
              <slot name="cancelBtn">
                <TheButton
                  v-if="cancelText"
                  class="text-none"
                  :block="false"
                  size="default"
                  variant="plain"
                  :elevation="0"
                  :title="cancelText"
                  @click="handleCancel"
                />
              </slot>

              <slot v-if="!noSubmitBtn" name="submitBtn">
                <TheButton
                  v-if="submitText"
                  type="submit"
                  class="text-none"
                  :loading="dialog.isLoading.value"
                  :block="false"
                  size="default"
                  :title="submitText"
                  @click="handleSubmit"
                />
              </slot>
            </slot>
          </v-card-actions>
        </v-card>
      </v-form>
    </slot>
  </v-dialog>
</template>

<script setup lang="ts" generic="T, E">
import { computed, ref, watch } from "vue";
import { UseDialogReturn } from "@/composables/useDialog";
import TheButton from "@/components/TheButton.vue";
import { VForm } from "vuetify/components";

interface Props {
  dialog: UseDialogReturn<T, E>;
  autoWidth?: boolean;
  title?: string;
  text?: string;
  cancelText?: string;
  submitText?: string;
  minWidth?: string;
  maxWidth?: string;
  noSubmitBtn?: boolean;
}

const emit = defineEmits<{
  (e: "onSubmit", data?: T): void;
  (e: "onCancel"): void;
  (e: "onClose"): void;
}>();
const props = defineProps<Props>();

const formRef = ref<VForm | null>(null);
const optionals = computed(() => (props.autoWidth ? { width: "auto" } : {}));

watch(
  () => props.dialog.isOpen.value,
  (newValue, oldValue) => {
    if (!newValue && oldValue) handleClose();
  },
);

function handleCancel() {
  props.dialog.cancel();
  emit("onCancel");
}

function handleClose() {
  props.dialog.close();
  emit("onClose");
}

async function handleSubmit() {
  const res = await formRef.value?.validate();
  if (!res?.valid) {
    return;
  }
  props.dialog.submit();
  emit("onSubmit", props.dialog.param.value);
}
</script>

<template>
  <router-view id="app" />
</template>

<script setup lang="ts">
import { watch } from "vue";
import { useTerminalStore } from "@/store/useTerminalStore";
import { useAuthStore } from "./store/useAuthStore";
import Intercom, { hide, shutdown } from "@intercom/messenger-js-sdk";
import CryptoJs from "crypto-js";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";

const terminalStore = useTerminalStore();
const authStore = useAuthStore();
const { locale } = useI18n();

const userIdentifier = authStore.userData?.id?.toString() || "";
const hash = CryptoJs.HmacSHA256(
  userIdentifier,
  import.meta.env.VITE_INTERCOM_SECRET_KEY,
).toString(CryptoJs.enc.Hex);
const route = useRoute();

const initializeIntercom = () => {
  if (authStore.authUser && authStore.authUser.userId) {
    Intercom({
      api_base: import.meta.env.VITE_INTERCOM_API_BASE,
      app_id: import.meta.env.VITE_INTERCOM_APP_ID,
      user_hash: hash,
      user_id: authStore.userData?.id,
      name: authStore.userData?.firstName + " " + authStore.userData?.lastName,
      email: authStore.userData?.email,
      language_override: locale.value,
    });
  } else {
    hide();
    shutdown();
    Intercom({
      app_id: import.meta.env.VITE_INTERCOM_APP_ID,
    });
  }
};

initializeIntercom();

watch(
  () => authStore.authUser?.userId,
  async user => {
    if (user) {
      await terminalStore.fetchTerminals();
    }
  },
);

watch(
  () => route.path,
  () => {
    initializeIntercom();
  },
);

watch(
  () => locale.value,
  () => {
    initializeIntercom();
  },
);
</script>

<style>
/*TODO: import this in index.html*/
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  padding: 0;
  margin: 0;
  height: 100vh;
  width: 100vw;
  hyphens: auto;
}
</style>

<template>
  <v-dialog max-width="600">
    <v-card>
      <v-card-title class="text-center">
        <v-row justify="center" align="center">
          <v-col>
            <span class="text-xl md:text-2xl">
              {{ $t("userManagement.delete_user") }}
            </span>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text>
        <v-row justify="center" align="center">
          <v-col cols="12">
            {{ $t("userManagement.delete_user_warning") }}
          </v-col>
          <v-textarea variant="outlined" :label="$t('userManagement.notes')" />
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn @click="confirmDelete" color="success" variant="flat">
          {{ $t("confirm") }}
        </v-btn>
        <v-btn @click="closeDialog">
          {{ $t("cancel") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts" setup>
import { ref } from "vue";

const dialog = ref(false);

const emit = defineEmits<{
  (e: "confirm"): void;
  (e: "close"): void;
}>();

const confirmDelete = () => {
  emit("confirm");
  closeDialog();
};

const closeDialog = () => {
  dialog.value = false;
  emit("close");
};
</script>

<template>
  <v-dialog max-width="500" data-testid="test-confirmModal">
    <v-card>
      <v-card-title class="text-center">
        {{
          $t("userManagement.reset_idnow_user", {
            name: `${user.firstName} ${user.lastName}`,
          })
        }}
      </v-card-title>
      <v-card-text>
        <div class="text-xl md:text-2xl mb-5">
          {{ $t("userManagement.reset_id") }}
        </div>
        <v-textarea variant="outlined" :label="$t('userManagement.notes')" />
      </v-card-text>
      <v-card-actions class="justify-between">
        <v-btn @click="sendSubmit">
          {{ $t("submit") }}
        </v-btn>
        <v-btn @click="closeDialog">
          {{ $t("close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts" setup>
import { IspsTruckerTO } from "@/services/client/generated";
import { PropType } from "vue";

const props = defineProps({
  user: {
    type: Object as PropType<IspsTruckerTO>,
    required: true,
  },
});

const emit = defineEmits<{
  submit: [payload: { id: string }];
  close: [close: boolean];
}>();

const sendSubmit = () => {
  closeDialog();
  if (props.user.userId) {
    emit("submit", { id: props.user.userId });
  }
};

const closeDialog = () => {
  emit("close", true);
};
</script>

<template>
  <v-row>
    <v-col cols="4" md="5">
      <div class="text-left">
        <h3>{{ t("userManagement.linkedFacilities.title") }}</h3>
        <p>{{ t("userManagement.linkedFacilities.description") }}</p>
      </div>
    </v-col>

    <v-col cols="8" md="7">
      <v-card class="pa-5" :loading="loading" :disabled="loading">
        <div class="d-flex font-weight-bold pb-3">
          {{ t("userManagement.linkedFacilities.add_remove_facility_company") }}
        </div>
        <div class="d-flex align-center justify-center py-2">
          <v-row>
            <template v-if="user.facilityCompanyIds?.length">
              <v-col
                v-for="company in sortedFacilityCompanyIds"
                :key="company"
                cols="12"
              >
                <v-text-field
                  :model-value="getFacilityCompanyNameById(company)"
                  class="cursor-default"
                  readonly
                  variant="outlined"
                  hide-details="auto"
                  append-inner-icon="mdi-minus-box-outline"
                  @click:appendInner="removeFacilityCompany(company)"
                />
              </v-col>
            </template>
            <v-col cols="12">
              <v-autocomplete
                ref="acFacilityCompanyRef"
                v-model="selectedFacilityCompany"
                :label="
                  t('userManagement.linkedFacilities.search_facility_company')
                "
                variant="outlined"
                :items="filteredFacilityCompanies"
                item-title="name"
                item-value="id"
                prepend-inner-icon="mdi-magnify"
                hide-details="auto"
                :no-data-text="t('no_data_available')"
                @update:model-value="addFacilityCompany"
              />
            </v-col>
          </v-row>
        </div>

        <div class="d-flex font-weight-bold py-3">
          {{ t("userManagement.linkedFacilities.add_remove_facility") }}
        </div>
        <div class="d-flex align-center justify-center py-2">
          <v-row>
            <template v-if="user.facilityIds?.length">
              <v-col
                v-for="facility in sortedFacilityIds"
                :key="facility"
                cols="12"
              >
                <v-text-field
                  :model-value="getFacilityNameById(facility)"
                  class="cursor-default"
                  readonly
                  variant="outlined"
                  hide-details="auto"
                  append-inner-icon="mdi-minus-box-outline"
                  @click:appendInner="removeFacility(facility)"
                />
              </v-col>
            </template>
            <v-col cols="12">
              <v-autocomplete
                ref="acFacilityRef"
                v-model="selectedFacility"
                :label="t('userManagement.linkedFacilities.search_facility')"
                variant="outlined"
                :items="filteredFacilities"
                item-title="name"
                item-value="id"
                prepend-inner-icon="mdi-magnify"
                hide-details="auto"
                :no-data-text="t('no_data_available')"
                @update:model-value="addFacility"
              />
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script lang="ts" setup>
import { ref, computed, defineProps, toRefs } from "vue";
import { useI18n } from "vue-i18n";
import {
  DimensionTO,
  FacilityTO,
  UpdateWebappUserRequestTO,
  WebappUserResponseTO,
} from "@/services/client/generated/api";

const props = defineProps<{
  loading: boolean;
  user: UpdateWebappUserRequestTO;
  originalUser: WebappUserResponseTO;
  allFacilityCompanies: DimensionTO[];
  allFacilities?: FacilityTO[];
}>();

const emit = defineEmits<{
  (event: "update", updatedData: Partial<UpdateWebappUserRequestTO>): void;
}>();

const { t } = useI18n();
const { originalUser, user } = toRefs(props);

const selectedFacilityCompany = ref<string>();
const selectedFacility = ref<string>();
const acFacilityCompanyRef = ref<HTMLElement | null>(null);
const acFacilityRef = ref<HTMLElement | null>(null);
const blurEvent: Event = new Event("blur");

const sortedFacilityCompanyIds = computed(() => [
  ...(user.value.facilityCompanyIds ?? []).sort((a, b) => a - b),
]);

const sortedFacilityIds = computed(() => [
  ...(user.value.facilityIds ?? []).sort((a, b) => a - b),
]);

const filteredFacilityCompanies = computed(() =>
  props.allFacilityCompanies.filter(
    company =>
      !(props.originalUser.facilities ?? []).some(
        fc => fc.facilityId === company.id,
      ),
  ),
);

const filteredFacilities = computed(() =>
  props.allFacilities?.filter(
    facility => !(props.user.facilityIds ?? []).some(f => f === facility.id),
  ),
);

const addFacilityCompany = (id: string) => {
  if (!id) return;

  const companyToAdd = props.allFacilityCompanies.find(
    company => company.id === Number(id),
  );

  if (companyToAdd) {
    if (!user.value.facilityCompanyIds) {
      user.value.facilityCompanyIds = [];
    }

    if (
      companyToAdd.id !== undefined &&
      !user.value.facilityCompanyIds.includes(companyToAdd.id)
    ) {
      user.value.facilityCompanyIds.push(companyToAdd.id);
      emitUpdate();
    }

    props.allFacilities
      ?.filter(facility => facility.facilityCompanyId === Number(id))
      .forEach(facility => {
        if (
          facility.id !== undefined &&
          user.value.facilityIds &&
          !user.value.facilityIds.includes(facility.id)
        ) {
          user.value.facilityIds.push(facility.id);
        }
      });
  }

  selectedFacilityCompany.value = undefined;
  acFacilityCompanyRef.value?.dispatchEvent(blurEvent);
};

const removeFacilityCompany = (id: number) => {
  user.value.facilityCompanyIds = (user.value.facilityCompanyIds || []).filter(
    companyId => companyId !== id,
  );
  emitUpdate();
};

const addFacility = (id: string) => {
  if (!id) return;

  const facilityToAdd = props.allFacilities?.find(
    facility => facility.id === Number(id),
  );

  if (facilityToAdd) {
    if (!user.value.facilityIds) {
      user.value.facilityIds = [];
    }

    if (
      facilityToAdd.id !== undefined &&
      !user.value.facilityIds.includes(facilityToAdd.id)
    ) {
      user.value.facilityIds.push(facilityToAdd.id);
      emitUpdate();
    }
  }

  selectedFacility.value = undefined;
  acFacilityRef.value?.dispatchEvent(blurEvent);
};

const removeFacility = (id: number) => {
  user.value.facilityIds = (user.value.facilityIds || []).filter(
    facilityId => facilityId !== id,
  );
  emitUpdate();
};

const getFacilityCompanyNameById = (id: number) => {
  const company = props.allFacilityCompanies.find(company => company.id === id);
  return company ? company.name : "";
};

const getFacilityNameById = (id: number) => {
  const facility = props.allFacilities?.find(facility => facility.id === id);
  return facility ? facility.name : "";
};

const emitUpdate = () => {
  emit("update", {
    facilityCompanyIds: user.value.facilityCompanyIds,
    facilityIds: user.value.facilityIds,
  });
};

if (!user.value.facilityCompanyIds) {
  user.value.facilityCompanyIds = Array.from(
    new Set(
      (originalUser.value.facilities ?? [])
        .map(fc => fc.company)
        .filter((id): id is number => id !== undefined),
    ),
  );
}

if (!user.value.facilityIds) {
  user.value.facilityIds = (originalUser.value.facilities ?? []).map(
    f => f.facilityId,
  );
}
</script>

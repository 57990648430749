<template>
  <div>
    <TheMainHeader
      class="text-left pl-10 pa-10"
      :text="terminalStore.terminal?.name ?? t('missing')"
    >
      <span class="bg-greyish pa-2 rounded-lg">
        <v-icon icon="custom:DisturbFilled" :height="24" :width="24" />
      </span>
      <span class="text-h4 font-weight-medium text-primary ml-2">
        {{ t("acceptanceRestrictions") }}
      </span>
    </TheMainHeader>

    <div>
      <v-container fluid class="px-10">
        <v-tabs v-model="tab" align-tabs="center" grow>
          <v-tab href="#acceptance">
            <div class="text-h5 text-primary font-weight-medium">
              {{ t("acceptanceDeadline") }}
            </div>
          </v-tab>
          <v-tab href="#oneToOne">
            <div
              class="text-h5 text-primary font-weight-medium"
              data-testid="test-onetoOneRestriction"
            >
              {{ t("oneToOneRestriction") }}
            </div>
          </v-tab>
        </v-tabs>

        <v-window v-model="tab">
          <v-window-item :key="1" value="acceptance">
            <TerminalOperatorAcceptanceDeadline />
          </v-window-item>

          <v-window-item :key="2" value="oneToOne">
            <TerminalOperatorOneToOneRestriction />
          </v-window-item>
        </v-window>
      </v-container>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import TerminalOperatorAcceptanceDeadline from "@/views/terminal-operator/acceptance-deadline/TerminalOperatorAcceptanceDeadline.vue";
import TheMainHeader from "@/components/TheMainHeader.vue";
import TerminalOperatorOneToOneRestriction from "@/views/terminal-operator/one-to-one-restriction/TerminalOperatorOneToOneRestriction.vue";
import { useTerminalStore } from "@/store/useTerminalStore";
import { useI18n } from "vue-i18n";

const terminalStore = useTerminalStore();
const tab = ref("acceptance");
const { t } = useI18n();
</script>

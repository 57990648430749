<template>
  <div>
    <TheMainHeader
      class="text-left pl-10 pt-10"
      :text="forwarderStore.forwarder?.name ?? $t('missing')"
    >
      <span class="bg-greyish pa-2 rounded-lg">
        <v-icon icon="custom:TimeFilled" :height="24" :width="24" />
      </span>
      <span class="text-h4 font-weight-medium text-primary ml-2">
        {{ $t("license_management") }}
      </span>
    </TheMainHeader>

    <div>
      <v-container fluid class="px-10">
        <v-card class="pa-4">
          <LicenseManagementTable
            :page="page"
            :licenses="licenses ?? []"
            @on-search="handleSearch"
            @on-filter="handleFilterClick"
            @onLoadMore="handleLoadMore"
            @on-claim-license="handleClaimLicenseClick"
            @on-purchase-license="handlePurchaseClick"
            @on-license-assign="handleAssignClick"
            @on-revoke="handleRevokeClick"
            @onTakeoverManagement="handleTakeoverClick"
            @onPaySetupFee="handlePaySetupFee"
            @on-stop-renewal="handleStopRenewal"
          />
          <LicenseManagementFiltersDialog
            :dialog="filterDialog"
            v-model="filter"
            @on-apply="handleFilterApplyClick"
          />
          <LicenseManagementClaimLicenseDialog
            :claimed-licenses="claimedLicenses"
            :dialog="claimLicenseDialog"
            @onSubmit="handleClaimLicenseSubmit"
          />
          <LicenseManagementPurchaseDialog
            :dialog="purchaseDialog"
            @on-submit="handlePurchaseSubmitClick"
          />
          <LicenseManagementAssignDialog
            :dialog="assignDialog"
            :truckers="truckersForForwarder"
            :truckers-loading="truckersLoading"
            @on-search="handleSearchTruckers"
            @on-load-more-truckers="handleLoadMoreTruckers"
            @onSubmit="handleAssignLicense"
          />
          <LicenseManagementRevokeDialog
            :dialog="revokeDialog"
            @onSubmit="handleRevokeSubmit"
          />

          <LicenseManagementTakeoverDialog
            :dialog="takeoverDialog"
            @onSubmit="handleTakeoverSubmit"
          />

          <LicenseManagementStopRenewalDialog
            :dialog="stopRenewalDialog"
            @onSubmit="handleStopRenewalSubmit"
          />
        </v-card>
      </v-container>
    </div>
  </div>
</template>

<script setup lang="ts">
import TheMainHeader from "@/components/TheMainHeader.vue";
import LicenseManagementTable from "@/components/license-management/LicenseManagementTable.vue";
import { useLicenseManagementLogic } from "@/views/forwarder-operator/license-management/license-management-logic";
import LicenseManagementPurchaseDialog from "@/components/license-management/LicenseManagementPurchaseDialog.vue";
import LicenseManagementAssignDialog from "@/components/license-management/LicenseManagementAssignDialog.vue";
import LicenseManagementFiltersDialog from "@/components/license-management/LicenseManagementFiltersDialog.vue";
import LicenseManagementClaimLicenseDialog from "@/components/license-management/LicenseManagementClaimLicenseDialog.vue";
import LicenseManagementRevokeDialog from "@/components/license-management/LicenseManagementRevokeDialog.vue";
import LicenseManagementTakeoverDialog from "@/components/license-management/LicenseManagementTakeoverDialog.vue";
import LicenseManagementStopRenewalDialog from "@/components/license-management/LicenseManagementStopRenewalDialog.vue";
import { useForwarderStore } from "@/store/useForwarderStore";

const forwarderStore = useForwarderStore();

const {
  page,
  licenses,
  truckersForForwarder,
  claimedLicenses,
  filterDialog,
  assignDialog,
  takeoverDialog,
  claimLicenseDialog,
  purchaseDialog,
  revokeDialog,
  stopRenewalDialog,
  filter,
  truckersLoading,
  handleFilterApplyClick,
  handleFilterClick,
  handleClaimLicenseClick,
  handlePurchaseClick,
  handleAssignClick,
  handleRevokeClick,
  handleRevokeSubmit,
  handleSearch,
  handleSearchTruckers,
  handleLoadMoreTruckers,
  handleLoadMore,
  handlePurchaseSubmitClick,
  handleClaimLicenseSubmit,
  handleAssignLicense,
  handleTakeoverClick,
  handleTakeoverSubmit,
  handlePaySetupFee,
  handleStopRenewal,
  handleStopRenewalSubmit,
} = useLicenseManagementLogic();
</script>

<template>
  <v-alert
    class="TheAlert"
    :color="customColor"
    :text="text"
    :title="title"
    :type="type"
  >
    <template #title>
      <slot name="title">
        <TheText class="font-weight-medium TheAlert--title" color="black">
          {{ title }}
        </TheText>
      </slot>
    </template>

    <template v-if="$slots.text" #text>
      <slot name="text" />
    </template>

    <template #prepend>
      <ErrorIcon v-if="type === 'error'" />
      <CheckCircleIcon v-if="type === 'success'" />
      <WarningIcon v-if="type === 'warning'" />
    </template>
  </v-alert>
</template>

<script setup lang="ts">
import ErrorIcon from "@/assets/icons/ErrorIcon.vue";
import { computed } from "vue";
import CheckCircleIcon from "@/assets/icons/CheckCircleIcon.vue";
import WarningIcon from "@/assets/icons/WarningIcon.vue";
import TheText from "@/components/TheText.vue";

interface Props {
  title?: string;
  text?: string;
  color?: string;
  type: "success" | "error" | "warning";
}

const props = defineProps<Props>();

const customColor = computed(() => {
  switch (props.type) {
    case "success":
      return "green-50";
    case "warning":
      return "yellow-50";
    case "error":
      return "red-50";
    default:
      return props.color;
  }
});
</script>

<style scoped lang="scss">
.TheAlert {
  font-size: 14px;

  &--title {
    font-size: 16px;
  }
}
</style>

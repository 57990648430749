import { dateService, DateService } from "@/services/business/date-service";
import { forwarderTruckersClient } from "@/services/client/configs/services";
import {
  ForwarderTruckersApiApi,
  TruckerForForwarderTO,
  UpdateTruckerForForwarderTO,
} from "@/services/client/generated";
import { useAuthStore } from "@/store/useAuthStore";
import { useForwarderStore } from "@/store/useForwarderStore";
import { useDialog } from "@/composables/useDialog";
import {
  License,
  LicenseManagementGenericError,
} from "@/models/license-management";
import { getLicenseError } from "@/utils/license-management/license-utils";
import { AxiosError } from "axios";

export class ForwarderTruckerManagementLogic {
  private static readonly TRUCKERS_PER_PAGE: number = 50;
  readonly assignLicenseDialog = useDialog<TruckerForForwarderTO>({
    noSubmitClose: true,
  });

  constructor(
    private authStore: ReturnType<typeof useAuthStore>,
    private forwarderStore: ReturnType<typeof useForwarderStore>,
    private dateService: DateService,
    private forwarderTruckerManagementService: ForwarderTruckersApiApi,
  ) {}

  async getTruckersForForwarder(
    page: number,
    search: string = "",
  ): Promise<TruckerForForwarderTO[]> {
    const userId: string = this.authStore.authUser?.userId ?? "";
    const forwarderId: number = this.forwarderStore.getForwarder()?.id ?? 0;

    if (!userId || !forwarderId) {
      throw new Error("User ID or Forwarder ID not found");
    }

    const response =
      await this.forwarderTruckerManagementService.getAllTruckersForForwarder(
        userId,
        forwarderId,
        {
          search,
        },
        page,
        ForwarderTruckerManagementLogic.TRUCKERS_PER_PAGE,
        {
          method: "POST",
        },
      );
    return response.data;
  }

  async approveTrucker(truckerId: string, approved: boolean): Promise<void> {
    const userId: string = this.authStore.authUser?.userId ?? "";
    const forwarderId: number = this.forwarderStore.getForwarder()?.id ?? 0;

    if (!userId || !forwarderId) {
      throw new Error("User ID or Forwarder ID not found");
    }

    await this.forwarderTruckerManagementService.approveTrucker(
      userId,
      forwarderId,
      truckerId,
      { approved },
    );
  }

  async updateTrucker(
    truckerId: string,
    updateTrucker: UpdateTruckerForForwarderTO,
  ): Promise<TruckerForForwarderTO> {
    const userId: string = this.authStore.authUser?.userId ?? "";
    const forwarderId: number = this.forwarderStore.getForwarder()?.id ?? 0;

    if (!userId || !forwarderId) {
      throw new Error("User ID or Forwarder ID not found");
    }

    return (
      await this.forwarderTruckerManagementService.updateTrucker(
        userId,
        forwarderId,
        truckerId,
        updateTrucker,
      )
    ).data;
  }

  async handleAssignLicenseSubmit(
    trucker: TruckerForForwarderTO,
  ): Promise<License | undefined> {
    try {
      this.assignLicenseDialog.setIsLoading(true);
      const userId: string = this.authStore?.authUser?.userId ?? "";
      const forwarderId: number = this.forwarderStore.getForwarder()?.id ?? 0;
      if (trucker.userId) {
        const result = await this.forwarderStore.assignLicense({
          userId,
          forwarderId,
          licenseUserId: trucker.userId,
        });
        this.assignLicenseDialog.close();
        return result;
      }
    } catch (error) {
      const assignLicenseError = getLicenseError(
        error as AxiosError<LicenseManagementGenericError>,
      );
      if (assignLicenseError) {
        this.assignLicenseDialog.setError(assignLicenseError);
      }
      throw error;
    } finally {
      this.assignLicenseDialog.setIsLoading(false);
    }
  }

  parseIsoIntoDate(date: string, locale: string): string {
    return this.dateService.parseReadableDateFormat(date, locale);
  }

  parseIsoIntoDateTime(date: string, locale: string): string {
    return this.dateService.parseReadableDateTimeFormat(date, locale);
  }

  handleAssignLicense(trucker: TruckerForForwarderTO) {
    this.assignLicenseDialog.open(trucker);
  }
}

let viewModel: ForwarderTruckerManagementLogic | null = null;

export const getViewModel = (): ForwarderTruckerManagementLogic => {
  if (!viewModel) {
    viewModel = new ForwarderTruckerManagementLogic(
      useAuthStore(),
      useForwarderStore(),
      dateService,
      forwarderTruckersClient,
    );
  }

  return viewModel;
};

<template>
  <v-row>
    <v-col cols="4" md="5">
      <div class="text-left">
        <h3>{{ t("truckingCompany.generalInformation.title") }}</h3>
        <p>{{ t("truckingCompany.generalInformation.description") }}</p>
      </div>
    </v-col>

    <v-col cols="8" md="7">
      <v-card :loading="loading" :disabled="loading" class="pa-5">
        <div class="d-flex align-center justify-center py-2">
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="company.name"
                :label="`${t('truckingCompany.trucking_company_name')}*`"
                variant="outlined"
                hide-details="auto"
                aria-required="true"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="company.email"
                :label="`${t('truckingCompany.company_email_address')}*`"
                variant="outlined"
                hide-details="auto"
                aria-required="true"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="company.vatId"
                :label="`${t('truckingCompany.tax_id')}*`"
                variant="outlined"
                hide-details="auto"
                aria-required="true"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="company.streetAddress"
                :label="t('truckingCompany.street_no')"
                variant="outlined"
                hide-details="auto"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="company.city"
                :label="t('city')"
                variant="outlined"
                hide-details="auto"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="company.zipCode"
                :label="t('zip_code')"
                variant="outlined"
                hide-details="auto"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="company.country"
                :label="t('country')"
                variant="outlined"
                hide-details="auto"
              />
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import {
  TruckingCompanyResponseTO,
  UpdateTruckingCompanyRequestTO,
} from "@/services/client/generated";
import { ref, toRefs, watch } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  company: UpdateTruckingCompanyRequestTO;
  loading: boolean;
}>();

const emit = defineEmits<{
  (e: "update", updatedCompany: Partial<TruckingCompanyResponseTO>): void;
}>();

const { t } = useI18n();
const { company } = toRefs(props);

const localCompany = ref({ ...company.value });

watch(
  localCompany,
  newVal => {
    emit("update", newVal);
  },
  { deep: true },
);
</script>

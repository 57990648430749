<template>
  <TheDialog
    :dialog="dialog"
    max-width="600px"
    :title="t('stop_renewal_title')"
    :cancel-text="t('keep_subscription')"
    :submit-text="t('stop_renewal')"
    @onSubmit="handleSubmit"
  >
    <template #text>
      <TheText class="d-block mb-4">
        {{ t("stop_renewal_subtitle") }}
      </TheText>

      <div class="border-b py-3 d-flex justify-space-between">
        <TheText color="neutral-900">
          {{ t("license_key") }}
        </TheText>

        <TheText color="black" bold>
          {{ license?.licenseKey }}
        </TheText>
      </div>

      <div class="border-b py-3 d-flex justify-space-between">
        <TheText color="neutral-900">
          {{ t("license_owner") }}
        </TheText>

        <TheText color="black" bold>
          {{ license?.forwarder }}
        </TheText>
      </div>

      <div class="border-b py-3 d-flex justify-space-between">
        <TheText color="neutral-900">
          {{ t("assigned_to") }}
        </TheText>

        <TheText color="black" bold>
          {{ license?.truckerName ?? "-" }}
        </TheText>
      </div>

      <div class="border-b py-3 d-flex justify-space-between">
        <TheText color="neutral-900">
          {{ t("expiration_date") }}
        </TheText>

        <TheText color="black" bold>
          {{ formatDate(license?.expirationDate, "MM/dd/yyyy") }}
        </TheText>
      </div>

      <TheText class="d-block my-4">
        {{ t("stop_renewal_description") }}
      </TheText>

      <TheText class="d-block my-4">
        {{ t("stop_renewal_are_you_sure") }}
      </TheText>

      <TheAlert
        v-if="license?.truckerName"
        type="warning"
        :title="t('license_already_in_use')"
        :text="t('assign_trucker_info')"
      />

      <TheAlert
        v-if="errorMessage"
        class="mt-5"
        type="error"
        :text="errorMessage"
      />
    </template>
  </TheDialog>
</template>

<script setup lang="ts">
import TheDialog from "@/components/dialogs/TheDialog.vue";
import { UseDialogReturn } from "@/composables/useDialog";
import TheText from "@/components/TheText.vue";
import { useI18n } from "vue-i18n";
import { License } from "@/models/license-management";
import { computed } from "vue";
import { formatDate } from "@/utils/date-utils";
import TheAlert from "@/components/TheAlert.vue";
import { getTranslatedErrorMessage } from "@/utils/translations-utils";

interface Props {
  dialog: UseDialogReturn<License>;
}

const emit = defineEmits<{
  (e: "onSubmit", value: License): void;
}>();
const props = defineProps<Props>();
const { t } = useI18n();

const license = computed(() => props.dialog.param.value);

const errorMessage = computed<string | undefined>(() => {
  const error = props.dialog.error;

  if (error.value) return getTranslatedErrorMessage(t, error.value?.code);
  return undefined;
});

function handleSubmit() {
  if (license.value) {
    emit("onSubmit", license.value);
  }
}
</script>

import {
  ispsAdminUserClient,
  ispsLicensesClient,
} from "@/services/client/configs/services";
import {
  IspsTruckerChangeTO,
  IspsTruckerChangeTODocumentStatusEnum,
  IspsTruckerChangeTOTerrorStatusEnum,
  IspsTruckerTO,
} from "@/services/client/generated";
import SnackbarType from "@/store/interfaces/snackbar-type";
import { useAuthStore } from "@/store/useAuthStore";
import { useSnackbarStore } from "@/store/useSnackbarStore";
import { getErrorByTypeOrDefault } from "@/utils/error-utils";
import { computed, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";

export function useUserManagementLogic() {
  const authStore = useAuthStore();
  const page = ref(0);
  const loading = ref(false);
  const { t } = useI18n();
  const totalItems = ref(0);
  const itemsPerPage = ref(20);

  const users = reactive<{ data: IspsTruckerTO[] }>({ data: [] });
  const terrorStates = computed(() => [
    {
      id: IspsTruckerChangeTOTerrorStatusEnum.Ok,
      display: t("userManagement.terror_state.ok"),
    },
    {
      id: IspsTruckerChangeTOTerrorStatusEnum.Warning,
      display: t("userManagement.terror_state.warning"),
    },
    {
      id: IspsTruckerChangeTOTerrorStatusEnum.RedAlert,
      display: t("userManagement.terror_state.red_alert"),
    },
    {
      id: IspsTruckerChangeTOTerrorStatusEnum.ManuallyApproved,
      display: t("userManagement.terror_state.manually_approved"),
    },
    {
      id: IspsTruckerChangeTOTerrorStatusEnum.ManuallyDenied,
      display: t("userManagement.terror_state.manually_denied"),
    },
  ]);

  const documentStates = computed(() => [
    {
      id: IspsTruckerChangeTODocumentStatusEnum.Accepted,
      display: t("userManagement.document_state.accepted"),
    },
    {
      id: IspsTruckerChangeTODocumentStatusEnum.Pending,
      display: t("userManagement.document_state.pending"),
    },
    {
      id: IspsTruckerChangeTODocumentStatusEnum.Denied,
      display: t("userManagement.document_state.denied"),
    },
    {
      id: IspsTruckerChangeTODocumentStatusEnum.Warn,
      display: t("userManagement.document_state.warn"),
    },
    {
      id: IspsTruckerChangeTODocumentStatusEnum.DeniedPreliminary,
      display: t("userManagement.document_state.denied_preliminary"),
    },
    {
      id: IspsTruckerChangeTODocumentStatusEnum.ManuallyApproved,
      display: t("userManagement.document_state.manually_approved"),
    },
    {
      id: IspsTruckerChangeTODocumentStatusEnum.ManuallyDenied,
      display: t("userManagement.document_state.manually_denied"),
    },
    {
      id: IspsTruckerChangeTODocumentStatusEnum.Failed,
      display: t("userManagement.document_state.failed"),
    },
  ]);

  const admin = authStore.getUser()?.userData;
  const adminId = admin?.id;
  const snackbarStore = useSnackbarStore();

  const searchPayload: {
    userId: string | undefined;
    warningState: IspsTruckerChangeTODocumentStatusEnum | undefined;
    terrorState: IspsTruckerChangeTOTerrorStatusEnum | undefined;
  } = reactive({
    userId: undefined,
    warningState: undefined,
    terrorState: undefined,
  });

  const fetchTruckers = async ({
    page,
    itemsPerPage,
    _sortBy,
  }: {
    page: number;
    itemsPerPage: number;
    _sortBy: Array<{ key: string; order: "asc" | "desc" }>;
  }): Promise<{ items: IspsTruckerTO[]; total: number }> => {
    if (adminId == null) {
      return { items: [], total: 0 };
    }

    try {
      const res = await ispsAdminUserClient.getIspsTruckers(
        adminId,
        searchPayload.warningState,
        searchPayload.terrorState,
        searchPayload.userId,
        page,
        itemsPerPage,
      );

      return {
        items: res.data,
        total: 500,
      };
    } catch (_) {
      return { items: [], total: 0 };
    }
  };

  const addUserRevision = async (payload: {
    userId: string;
    data: IspsTruckerChangeTO;
  }) => {
    if (!adminId) {
      return;
    }
    await ispsAdminUserClient.ispsTruckerLog(
      adminId,
      payload.userId,
      payload.data,
    );
  };

  const resetIDNow = async (payload: string) => {
    if (adminId == null) {
      return;
    }
    try {
      await ispsAdminUserClient.deleteTruckerIdentifications(adminId, payload);
      snackbarStore.showSnackbar({
        text: t("userManagement.idnow_reset_success"),
        snackbarType: SnackbarType.SUCCESS,
      });
    } catch (e) {
      const errorText = t(
        getErrorByTypeOrDefault(e, "userManagement.error_resetting_idnow"),
      );
      snackbarStore.showSnackbar({
        text: errorText,
        snackbarType: SnackbarType.ERROR,
      });
      throw e;
    }
  };

  const approveUser = async (payload: {
    userId: string;
    entryId: number;
    approve: boolean;
  }) => {
    const { userId, entryId, approve } = payload;
    //TODO: ISSUE WITH 204 - 200 fix in backend
    try {
      if (adminId == null) {
        return;
      }
      await ispsAdminUserClient.patchIspsTrucker(adminId, userId, entryId, {
        approved: approve,
      });
    } catch (e) {
      const errorText = t(getErrorByTypeOrDefault(e));
      snackbarStore.showSnackbar({
        text: errorText,
        snackbarType: SnackbarType.ERROR,
      });

      throw e;
    }
  };

  const migrateLicense = async (payload: {
    userId: string;
    newUserId: string;
    licenseId: number;
  }) => {
    try {
      if (adminId == null) {
        return;
      }
      await ispsLicensesClient.migrateISPSLicenseToNewTrucker(
        adminId,
        payload.licenseId.toString(),
        {
          oldTruckerId: payload.userId,
          newTruckerId: payload.newUserId,
        },
      );
      snackbarStore.showSnackbar({
        text: "License migrated successfully.",
        snackbarType: SnackbarType.SUCCESS,
      });

      page.value = 0;
    } catch (e) {
      const errorText = t(
        getErrorByTypeOrDefault(e, "userManagement.error_migrating_license"),
      );
      snackbarStore.showSnackbar({
        text: errorText,
        snackbarType: SnackbarType.ERROR,
      });
      throw e;
    }
  };

  const deleteUser = async (payload: { userId: string }) => {
    try {
      if (adminId == null) {
        return;
      }
      await ispsAdminUserClient.deleteTruckerRequest(adminId, payload.userId);
      snackbarStore.showSnackbar({
        text: t("userManagement.delete_request_success"),
        snackbarType: SnackbarType.SUCCESS,
      });

      page.value = 0;
    } catch (e) {
      const errorText = t(
        getErrorByTypeOrDefault(e, "userManagement.delete_request_error"),
      );
      snackbarStore.showSnackbar({
        text: errorText,
        snackbarType: SnackbarType.ERROR,
      });
      throw e;
    }
  };

  const fetchPage = async ({
    page: newPage,
    itemsPerPage,
    sortBy,
  }: {
    page: number;
    itemsPerPage: number;
    sortBy: Array<{ key: string; order: "asc" | "desc" }>;
  }) => {
    if (loading.value) return;

    loading.value = true;

    try {
      const { items, total } = await fetchTruckers({
        page: newPage - 1,
        itemsPerPage,
        _sortBy: sortBy,
      });

      users.data = items;
      totalItems.value = total;
    } catch (_) {
      users.data = [];
    } finally {
      loading.value = false;
    }
  };

  return {
    authStore,
    adminId,
    users,
    loading,
    page,
    searchPayload,
    totalItems,
    itemsPerPage,
    terrorStates,
    documentStates,
    fetchPage,
    deleteUser,
    fetchTruckers,
    approveUser,
    addUserRevision,
    migrateLicense,
    resetIDNow,
  };
}

<template>
  <v-dialog max-width="700px">
    <template #default="{ isActive }">
      <v-card>
        <v-card-title class="text-center text-h5 font-weight-bold">
          {{
            $t("userManagement.additional_info.description", {
              name: `${user?.firstName} ${user?.lastName}`,
            })
          }}
        </v-card-title>

        <v-card-text>
          <v-row class="mb-4">
            <v-col cols="6" class="font-weight-medium">
              {{ $t("birthday") }}
            </v-col>
            <v-col cols="6">{{ getFormattedDate(getBirthday()) }}</v-col>

            <v-col cols="6" class="font-weight-medium">
              {{ $t("userManagement.verification_date") }}
            </v-col>
            <v-col cols="6">{{
              getFormattedDate(getVerificationDate())
            }}</v-col>

            <v-col cols="6" class="font-weight-medium">
              {{ $t("last_edited_date") }}
            </v-col>
            <v-col cols="6">{{ getFormattedDate(getLastEditedDate()) }}</v-col>

            <v-col cols="6" class="font-weight-medium">
              {{ $t("phone_number") }}
            </v-col>
            <v-col cols="6">{{ user?.phoneNumber || "-" }}</v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="justify-end">
          <v-btn color="primary" @click="isActive.value = false">
            {{ $t("close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script setup lang="ts">
import { IspsTruckerTO } from "@/services/client/generated";
import { dateService } from "@/services/business/date-service";

const props = defineProps<{
  user: IspsTruckerTO | null;
}>();

const getBirthday = (): string | null => {
  return props.user?.truckerIdentification?.length
    ? (props.user.truckerIdentification[
        props.user.truckerIdentification.length - 1
      ].dateOfBirth ?? null)
    : null;
};

const getVerificationDate = (): string | null => {
  return props.user?.truckerIdentification?.length
    ? (props.user.truckerIdentification[
        props.user.truckerIdentification.length - 1
      ].verified_date ?? null)
    : null;
};

const getLastEditedDate = (): string | null => {
  const lastChange =
    props.user?.changes?.[props.user.changes.length - 1]?.createdDate;
  return lastChange ?? null;
};

const getFormattedDate = (date: string | null): string => {
  return date ? dateService.convertTimeToLocalReadable(new Date(date)) : "-";
};
</script>

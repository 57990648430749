import { useSnackbarStore } from "@/store/useSnackbarStore";
import SnackbarType from "@/store/interfaces/snackbar-type";

export function useSnackbar() {
  const snackbarStore = useSnackbarStore();

  async function showSuccessMessage(message: string) {
    await snackbarStore.showSnackbar({
      text: message,
      snackbarType: SnackbarType.SUCCESS,
    });
  }

  async function showErrorMessage(message: string) {
    await snackbarStore.showSnackbar({
      text: message,
      snackbarType: SnackbarType.ERROR,
    });
  }

  return {
    snackbarStore,
    showErrorMessage,
    showSuccessMessage,
  };
}

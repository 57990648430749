<template>
  <TheDialog
    :dialog="dialog"
    max-width="600px"
    :title="t('assign_license')"
    :submit-text="t('assign_license')"
    :cancel-text="t('cancel')"
    @onSubmit="handleSubmit"
  >
    <template #text>
      {{
        t("assign_license_to_trucker", {
          value: `${trucker?.firstName} ${trucker?.lastName}`,
        })
      }}
      <div>
        <TheAlert
          class="mt-4"
          v-if="errorMessage"
          type="error"
          :text="errorMessage"
        />
      </div>
    </template>
  </TheDialog>
</template>
<script setup lang="ts">
import TheDialog from "@/components/dialogs/TheDialog.vue";
import { UseDialogReturn } from "@/composables/useDialog";
import { useI18n } from "vue-i18n";
import { LicenseManagementGenericError } from "@/models/license-management";
import { computed } from "vue";
import TheAlert from "@/components/TheAlert.vue";
import { getTranslatedErrorMessage } from "@/utils/translations-utils";
import { TruckerForForwarderTO } from "@/services/client/generated";

interface Props {
  dialog: UseDialogReturn<TruckerForForwarderTO>;
}

const emit = defineEmits(["onSubmit"]);
const props = defineProps<Props>();
const { t } = useI18n();

const trucker = computed(() => props.dialog.param.value);

const errorMessage = computed<
  LicenseManagementGenericError["code"] | undefined
>(() => {
  const error = props.dialog.error;

  if (error.value) return getTranslatedErrorMessage(t, error.value?.code);
  return undefined;
});

function handleSubmit() {
  if (trucker.value) {
    emit("onSubmit", trucker.value);
  }
}
</script>

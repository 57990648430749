<template>
  <div>
    <TheMainHeader class="text-left pl-10 pt-10" :text="t('conroo_admin')">
      <span class="bg-greyish pa-2 rounded-lg">
        <v-icon icon="custom:TruckIcon" :height="24" :width="24" />
      </span>
      <span class="text-h4 font-weight-medium text-primary ml-2">
        {{ t("truckingCompany.title") }}
      </span>
    </TheMainHeader>

    <div>
      <v-container fluid class="px-10">
        <VirtualTable
          :items="filteredTruckingCompanies"
          :headers="headers"
          :searchable="true"
          @load-more="allLoaded"
        >
          <template #custom-search>
            <v-row justify="space-between">
              <v-col cols="4" align-self="center">
                <v-text-field
                  v-model="searchQuery"
                  variant="outlined"
                  :label="t('search')"
                  :placeholder="t('truckingCompany.search_by_name_or_tax_id')"
                  clearable
                />
              </v-col>
              <v-col align-self="center">
                <div class="d-flex justify-end">
                  <v-btn
                    style="height: 3em"
                    color="primary"
                    append-icon="mdi-plus"
                    class="px-4"
                    rounded="lg"
                    @click="dialog = true"
                  >
                    {{ t("truckingCompany.create_trucking_company") }}
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </template>
          <template #item.name="{ value }">
            {{ value.name ?? "-" }}
          </template>
          <template #item.email="{ value }">
            {{ value.email ?? "-" }}
          </template>
          <template #item.vatId="{ value }">
            {{ value.vatId ?? "-" }}
          </template>
          <template #item.createdAt="{ value }">
            {{ value.createdDate ? formatDate(value.createdDate) : "-" }}
          </template>
          <template #item.street="{ value }">
            {{ value.street ?? "-" }}
          </template>
          <template #item.cityZipCode="{ value }">
            {{ formatCityZipCode(value.city, value.zipCode) }}
          </template>
          <template #item.country="{ value }">
            {{ getCountryName(value.country) }}
          </template>
          <template #item.truckingSystem="{ value }">
            {{ value.truckingSystem ?? "-" }}
          </template>
          <template #item.actions="{ value }">
            <v-btn
              @click="navigateTo(value.id)"
              :icon="'mdi-pencil'"
              variant="text"
              color="primary"
            />
          </template>
        </VirtualTable>
      </v-container>
    </div>

    <CreateTruckerCompanyDialog v-model="dialog" />
  </div>
</template>

<script setup lang="ts">
import TheMainHeader from "@/components/TheMainHeader.vue";
import VirtualTable from "@/components/virtual-table/VirtualTable.vue";
import CreateTruckerCompanyDialog from "@/components/trucker-company/CreateTruckerCompanyDialog.vue";
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useTruckingCompaniesLogic } from "./trucking-companies-logic";
import { useRouter } from "vue-router";
import { useTruckingCompanyStore } from "../../../store/useTruckingCompanyStore";
import { formatDate } from "@/utils/date-utils";
import { VuetifyHeader } from "@/internal-models/vuetify-header";

const { t } = useI18n();
const router = useRouter();
const truckingCompanyStore = useTruckingCompanyStore();
const { fetchTruckingCompanies } = useTruckingCompaniesLogic();

const searchQuery = ref("");
const dialog = ref(false);
const TRUCKING_COMPANY_HEADERS: VuetifyHeader[] = [
  {
    title: t("truckingCompany.company_name"),
    value: "name",
    align: "center",
  },
  {
    title: t("truckingCompany.company_email"),
    value: "email",
    align: "center",
  },
  {
    title: t("truckingCompany.tax_id"),
    value: "vatId",
    align: "center",
  },
  {
    title: t("created_date"),
    value: "createdAt",
    align: "center",
  },
  {
    title: t("street"),
    value: "street",
    align: "center",
  },
  {
    title: t("city_zip_code"),
    value: "cityZipCode",
    align: "center",
  },
  {
    title: t("country"),
    value: "country",
    align: "center",
  },
  {
    title: t("truckingCompany.tms_system"),
    value: "truckingSystem",
    align: "center",
  },
  {
    title: t("actions"),
    value: "actions",
    sortable: false,
    align: "center",
  },
];

const headers = computed(() => TRUCKING_COMPANY_HEADERS);
const allLoaded = async (
  done: (status: "loading" | "error" | "empty" | "ok") => void,
) => {
  try {
    if (truckingCompanyStore.sortedTruckingCompanies) {
      done("empty");
    } else {
      done("ok");
    }
  } catch (_) {
    done("error");
  }
};
const filteredTruckingCompanies = computed(() => {
  if (!searchQuery.value) {
    return truckingCompanyStore.sortedTruckingCompanies;
  }
  return truckingCompanyStore.sortedTruckingCompanies.filter(company =>
    [company.name, company.vatId].some(field => {
      if (field) {
        return field.toLowerCase().includes(searchQuery.value.toLowerCase());
      }
    }),
  );
});

const formatCityZipCode = (city: string, zipCode: string) => {
  if (!city && !zipCode) {
    return "-";
  } else if (city && !zipCode) {
    return city;
  } else if (!city && zipCode) {
    return zipCode;
  } else {
    return `${city} / ${zipCode}`;
  }
};

const getCountryName = (country: string | null) => {
  if (!country) {
    return "-";
  }
  return country ?? "-";
};

const navigateTo = (companyId: string) => {
  router.push({ name: "truckingCompanyId", params: { id: companyId } });
};

onMounted(async () => {
  await fetchTruckingCompanies();
});
</script>

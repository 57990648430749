import { Composer } from "vue-i18n";

export function getTranslatedErrorMessage(
  t: Composer["t"],
  messageKey: string,
  fallbackMessageKey: string = "unknownError",
): string {
  const translatedMessage = t(messageKey);
  return translatedMessage !== messageKey
    ? translatedMessage
    : t(fallbackMessageKey);
}

<template>
  <div>
    <TheMainHeader class="text-left pl-10 pt-10" :text="t('conroo_admin')">
      <span class="bg-greyish pa-2 rounded-lg">
        <v-icon icon="custom:UserFilled" :height="24" :width="24" />
      </span>
      <span class="text-h4 font-weight-medium text-primary ml-2">
        {{ t("userManagement.gatepass_title") }}
      </span>
    </TheMainHeader>

    <v-container fluid class="px-10">
      <VirtualTable
        :items="users.data"
        :row-props="getRowClass"
        :headers="headers"
        :items-per-page="itemsPerPage"
        :loading="loading"
        :total-items="totalItems"
        :mode="'paginated'"
        searchable
        filterable
        @update:options="fetchPage"
      >
        <template #custom-search>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="searchQuery"
                variant="outlined"
                hide-details="auto"
                :disabled="loading"
                :label="t('search')"
                :placeholder="t('userManagement.search_by_user_id')"
                clearable
              />
            </v-col>
          </v-row>
        </template>

        <template #filter>
          <FilterSection
            :filter-groups="filterGroups"
            :applied-filters="appliedFilters"
            @update:filters="updateAppliedFilters"
            @apply="applyFilter"
          />
        </template>

        <template #chips>
          <v-row class="pa-5" v-if="activeFilters.length > 0">
            <v-chip
              v-for="filter in activeFilters"
              :key="filter.label"
              class="ma-2"
              @click:close="removeFilter(filter.label)"
              closable
            >
              {{ t(filter.label) }}:
              {{ t(filter.displayValue || "missing") }}
            </v-chip>
          </v-row>
        </template>

        <template #item.icon="{ value }">
          <v-tooltip :text="getTooltipText(value)" location="bottom">
            <template #activator="{ props: activeProps }">
              <v-icon
                v-if="value.deletedDate"
                v-bind="activeProps"
                color="red"
                icon="mdi-delete-alert"
              />
              <v-icon
                v-else-if="
                  value.changes?.filter(
                    (e: IspsTruckerChangeTO) =>
                      e.state === IspsTruckerChangeTOStateEnum.Pending,
                  ).length !== 0
                "
                v-bind="activeProps"
                color="yellow"
                icon="mdi-alert-box"
              />
            </template>
          </v-tooltip>
        </template>

        <template #item.email="{ value }">
          {{ value.email ?? t("missing") }}
        </template>

        <template #item.terrorState="{ value }">
          {{ getTerrorState(value) }}
        </template>

        <template #item.documentState="{ value }">
          {{ getDocumentState(value) }}
        </template>

        <template #item.deletedAtDate="{ value }">
          {{ value.deletedDate ? formatDate(value.deletedDate) : "-" }}
        </template>

        <template #item.actions="{ value }">
          <v-row>
            <v-col class="d-flex justify-space-between">
              <v-tooltip location="bottom" :text="getEditText(value)">
                <template #activator="{ props: activatorProps }">
                  <div v-bind="activatorProps">
                    <v-btn
                      icon="mdi-pencil"
                      variant="text"
                      :disabled="hasChanges(value)"
                      @click="() => changeCurrentlyEditable(value.userId!)"
                    />
                  </div>
                </template>
              </v-tooltip>

              <v-tooltip
                location="bottom"
                :text="t('userManagement.show_user_history')"
              >
                <template #activator="{ props: activatorProps }">
                  <div v-bind="activatorProps">
                    <v-btn
                      icon="mdi-history"
                      variant="text"
                      @click="() => showUserHistory(value.userId!)"
                    />
                  </div>
                </template>
              </v-tooltip>

              <v-tooltip
                location="bottom"
                :text="t('userManagement.reset_idnow_tries')"
              >
                <template #activator="{ props: activatorProps }">
                  <div v-bind="activatorProps">
                    <v-btn
                      icon="mdi-refresh"
                      variant="text"
                      @click="() => showResetUser(value.userId!)"
                    />
                  </div>
                </template>
              </v-tooltip>

              <v-tooltip location="bottom" :text="getMigrationText(value)">
                <template #activator="{ props: activatorProps }">
                  <div v-bind="activatorProps">
                    <v-btn
                      icon="mdi-swap-horizontal"
                      variant="text"
                      :disabled="!value.ispsLicense"
                      @click="() => showMigrateLicense(value.userId!)"
                    />
                  </div>
                </template>
              </v-tooltip>

              <v-tooltip location="bottom" :text="getDeletedText(value)">
                <template #activator="{ props: activatorProps }">
                  <div v-bind="activatorProps">
                    <v-btn
                      icon="mdi-trash-can"
                      variant="text"
                      :disabled="isDeleteDisabled(value)"
                      @click="() => showDeleteDialog(value.userId!)"
                    />
                  </div>
                </template>
              </v-tooltip>

              <v-tooltip
                location="bottom"
                :text="t('userManagement.additional_info.title')"
              >
                <template #activator="{ props: activatorProps }">
                  <div v-bind="activatorProps">
                    <v-btn
                      icon="mdi-information"
                      variant="text"
                      @click="() => showInfoDialog(value.userId!)"
                    />
                  </div>
                </template>
              </v-tooltip>
            </v-col>
          </v-row>
        </template>
      </VirtualTable>
    </v-container>

    <UserEditDialog
      v-if="selectedUser"
      v-model="openEditUserDialog"
      :user="selectedUser"
      @close="() => (openEditUserDialog = !openEditUserDialog)"
      @submit="
        (updatedUser: IspsTruckerChangeTO) => {
          changeToSend = updatedUser;
          applyChanges();
        }
      "
    />

    <HistoryDialog
      v-if="selectedUser && selectedUser.changes"
      v-model="openHistoryDialog"
      :user="selectedUser"
      @approve="
        (t: any) =>
          selectedUser &&
          selectedUser.userId &&
          approveUser({
            userId: selectedUser.userId,
            entryId: t.id,
            approve: t.approve,
          })
      "
      @close="(t: any) => (openHistoryDialog = t.close)"
    />

    <ResetIdDialog
      v-if="selectedUser"
      v-model="openResetDialog"
      :user="selectedUser"
      @submit="(t: any) => resetIDNow(t.id)"
      @close="(t: any) => (openResetDialog = t.close)"
    />

    <MigrateLicenseDialog
      v-if="selectedUser"
      v-model="openMigrateLicenseDialog"
      @confirm="
        (newUserId: string) =>
          selectedUser &&
          selectedUser.userId &&
          migrateLicense({
            userId: selectedUser.userId,
            newUserId,
            licenseId: selectedUser?.ispsLicense?.id as number,
          })
      "
      @close="() => (openMigrateLicenseDialog = !openMigrateLicenseDialog)"
    />

    <DeleteUserDialog
      v-if="selectedUser"
      v-model="openDeleteDialog"
      @confirm="
        () =>
          selectedUser &&
          selectedUser.userId &&
          deleteUser({ userId: selectedUser.userId })
      "
      @close="() => (openDeleteDialog = !openDeleteDialog)"
    />

    <UserAdditionalInfo
      v-model="openAdditionalInfoDialog"
      :user="selectedUser"
    />
  </div>
</template>

<script setup lang="ts">
import { watch, ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import TheMainHeader from "@/components/TheMainHeader.vue";
import VirtualTable from "@/components/virtual-table/VirtualTable.vue";
import FilterSection from "@/components/virtual-table/FilterSection.vue";
import { useUserManagementLogic } from "./user-management-logic";
import {
  IspsTruckerChangeTO,
  IspsTruckerChangeTODocumentStatusEnum,
  IspsTruckerChangeTOStateEnum,
  IspsTruckerChangeTOTerrorStatusEnum,
  IspsTruckerTO,
  TruckerIdentificationTO,
  TruckerIdentificationTODocumentStatusEnum,
} from "@/services/client/generated";
import { getLastArrayElement } from "@/utils/object-util";
import HistoryDialog from "@/components/user-management/HistoryDialog.vue";
import MigrateLicenseDialog from "@/components/user-management/MigrateLicenseDialog.vue";
import DeleteUserDialog from "@/components/user-management/DeleteUserDialog.vue";
import { useSnackbarStore } from "@/store/useSnackbarStore";
import SnackbarType from "@/store/interfaces/snackbar-type";
import UserAdditionalInfo from "@/components/user-management/UserAdditionalInfo.vue";
import UserEditDialog from "@/components/user-management/UserEditDialog.vue";
import { formatDate } from "@/utils/date-utils";
import ResetIdDialog from "@/components/user-management/ResetIdDialog.vue";

const { t } = useI18n();
const snackBarStore = useSnackbarStore();
const {
  users,
  fetchPage,
  itemsPerPage,
  totalItems,
  searchPayload,
  terrorStates,
  documentStates,
  loading,
  addUserRevision,
  deleteUser,
  resetIDNow,
  migrateLicense,
  approveUser,
} = useUserManagementLogic();

const searchQuery = ref("");
const appliedFilters = ref<
  {
    label: string;
    value: string | { id: string; display: string };
    displayValue?: string;
  }[]
>([]);
const activeFilters = computed(() => appliedFilters.value);
const selectedUser = ref<IspsTruckerTO | null>(null);
const changeToSend = ref<IspsTruckerChangeTO>({
  documentStatus: undefined,
  terrorStatus: undefined,
});

const openHistoryDialog = ref(false);
const openEditUserDialog = ref(false);
const openResetDialog = ref(false);
const openMigrateLicenseDialog = ref(false);
const openDeleteDialog = ref(false);
const openAdditionalInfoDialog = ref(false);

const headers = computed(() => [
  {
    title: "",
    value: "icon",
    align: "center" as "center" | "start" | "end",
  },
  {
    title: t("user_id"),
    value: "userId",
    align: "center" as "center" | "start" | "end",
  },
  {
    title: t("email"),
    value: "email",
    align: "center" as "center" | "start" | "end",
  },
  {
    title: t("userManagement.terror_state.title"),
    value: "terrorState",
    align: "center" as "center" | "start" | "end",
  },
  {
    title: t("userManagement.document_state.title"),
    value: "documentState",
    align: "center" as "center" | "start" | "end",
    width: "200",
  },
  {
    title: t("first_name"),
    value: "firstName",
    align: "center" as "center" | "start" | "end",
    width: "150",
  },
  {
    title: t("last_name"),
    value: "lastName",
    align: "center" as "center" | "start" | "end",
    width: "150",
  },
  {
    title: t("userManagement.deleted_at"),
    value: "deletedAtDate",
    align: "center" as "center" | "start" | "end",
  },
  {
    title: t("actions"),
    value: "actions",
    align: "center" as "center" | "start" | "end",
    sortable: false,
  },
]);

const filterGroups = computed(() => [
  {
    title: t("userManagement.available_statuses"),
    items: [
      {
        key: "terrorState",
        label: "userManagement.terror_state.title",
        options: terrorStates.value.map(opt => ({
          id: opt.id,
          display: opt.display,
          i18nKey: t(`userManagement.terror_state.${opt.id.toLowerCase()}`),
        })),
      },
      {
        key: "documentState",
        label: "userManagement.document_state.title",
        options: documentStates.value.map(opt => ({
          id: opt.id,
          display: opt.display,
          i18nKey: t(`userManagement.document_state.${opt.id.toLowerCase()}`),
        })),
      },
    ],
  },
]);

const removeFilter = (label: string) => {
  appliedFilters.value = appliedFilters.value.filter(f => f.label !== label);
};

const changeCurrentlyEditable = (userId: string) => {
  openEditUserDialog.value = true;
  selectedUser.value = users.data.find(user => user.userId === userId) ?? null;
  changeToSend.value = {};
};

const updateSearchPayload = () => {
  const terrorFilter = appliedFilters.value.find(
    f => f.label === "terrorState",
  );
  const warningFilter = appliedFilters.value.find(
    f => f.label === "documentState",
  );
  searchPayload.terrorState =
    typeof terrorFilter?.value === "object"
      ? (terrorFilter.value.id as IspsTruckerChangeTOTerrorStatusEnum)
      : undefined;
  searchPayload.warningState =
    typeof warningFilter?.value === "object"
      ? (warningFilter.value.id as IspsTruckerChangeTODocumentStatusEnum)
      : undefined;
};

const applyChanges = () => {
  if (
    !changeToSend.value ||
    Object.keys(changeToSend.value).length === 0 ||
    !selectedUser.value ||
    !selectedUser.value.userId
  ) {
    snackBarStore.showSnackbar({
      text: "Can't add an empty change",
      snackbarType: SnackbarType.ERROR,
    });
    return;
  }
  const payload = {
    userId: selectedUser.value.userId,
    data: changeToSend.value,
  };
  addUserRevision(payload);
};

const isDeleteDisabled = (user: IspsTruckerTO): boolean => {
  return !!user.deletedDate?.length || hasChanges(user);
};

const showUserHistory = (userId: string) => {
  selectedUser.value = users.data.find(user => user.userId === userId) ?? null;
  openHistoryDialog.value = !openHistoryDialog.value;
};

const showMigrateLicense = (userId: string) => {
  selectedUser.value = users.data.find(user => user.userId === userId) ?? null;
  openMigrateLicenseDialog.value = !openMigrateLicenseDialog.value;
};

const showDeleteDialog = (userId: string) => {
  selectedUser.value = users.data.find(user => user.userId === userId) ?? null;
  openDeleteDialog.value = !openDeleteDialog.value;
};

const showInfoDialog = (userId: string) => {
  selectedUser.value = users.data.find(user => user.userId === userId) ?? null;
  openAdditionalInfoDialog.value = !openAdditionalInfoDialog.value;
};

const showResetUser = (userId: string) => {
  selectedUser.value = users.data.find(user => user.userId === userId) ?? null;
  openResetDialog.value = !openResetDialog.value;
};

const applyFilter = async () => {
  updateSearchPayload();
  await fetchPage({ page: 1, itemsPerPage: itemsPerPage.value, sortBy: [] });
};

const updateAppliedFilters = (
  filters: {
    label: string;
    value: { id: string; display: string; i18nKey: string };
  }[],
) => {
  appliedFilters.value = filters.map(f => ({
    ...f,
    displayValue: f.value.i18nKey,
  }));
};

const getTerrorState = (user: IspsTruckerTO) => {
  const terrorStatus = getLastArrayElement(
    user.truckerIdentification as TruckerIdentificationTO[],
  )?.terrorStatus;

  return terrorStatus
    ? t(
        `userManagement.terror_state.${
          getLastArrayElement(
            user.truckerIdentification as TruckerIdentificationTO[],
          )?.terrorStatus?.toLowerCase() ?? ""
        }`,
      )
    : t("not_set");
};

const getDocumentState = (user: IspsTruckerTO) => {
  const documentStatus = getLastArrayElement(
    user.truckerIdentification as TruckerIdentificationTO[],
  )?.documentStatus;

  return documentStatus
    ? t(
        `userManagement.document_state.${
          getLastArrayElement(
            user.truckerIdentification as TruckerIdentificationTO[],
          )?.documentStatus?.toLowerCase() ?? ""
        }`,
      )
    : t("not_set");
};

const getTooltipText = (user: IspsTruckerTO) => {
  if (user.deletedDate) return t("deleted");
  const pendingChanges = user.changes?.some(
    e => e.state === IspsTruckerChangeTOStateEnum.Pending,
  );
  return pendingChanges ? t("userManagement.pending_changes") : "";
};

const getDeletedText = (user: IspsTruckerTO) => {
  return user.deletedDate
    ? t("userManagement.user_deleted")
    : hasChanges(user)
      ? t("userManagement.pending_changes")
      : user.changes?.some((e: IspsTruckerChangeTO) => e.delete === true)
        ? t("userManagement.request_to_be_deleted")
        : t("userManagement.delete_user");
};

const getEditText = (user: IspsTruckerTO) =>
  user.deletedDate ? t("userManagement.user_deleted") : t("edit");

const getRowClass = (item: { item: IspsTruckerTO }) => {
  const user = item.item;
  const rowClass = {
    "bg-yellow-light": hasChanges(user) && !user.deletedDate,
    "bg-orange-400":
      getLastArrayElement(user.truckerIdentification!)?.documentStatus ===
      TruckerIdentificationTODocumentStatusEnum.Warn,
    "bg-red-lighten-3": !!user.deletedDate,
  };
  return { class: rowClass };
};

const getMigrationText = (user: IspsTruckerTO) =>
  user.deletedDate
    ? t("userManagement.user_deleted")
    : t("userManagement.migrate_license");

const hasChanges = (user: IspsTruckerTO) =>
  user.changes?.some(e => e.state === IspsTruckerChangeTOStateEnum.Pending) ??
  false;

watch(searchQuery, async newQuery => {
  if (newQuery.trim().length >= 3) {
    updateSearchPayload();
    await fetchPage({ page: 1, itemsPerPage: itemsPerPage.value, sortBy: [] });
  }
});
</script>

<template>
  <v-dialog v-model="modelValue" max-width="500" @click:outside="closeDialog">
    <v-card>
      <v-card-title class="text-h6 font-weight-bold">
        {{ t("userManagement.create_webapp_user") }}
      </v-card-title>

      <v-card-text>
        <p class="mb-4">
          {{ t("userManagement.create_webapp_user_text") }}
        </p>

        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="form.firstName"
              :label="`${t('first_name')}*`"
              aria-required="true"
              variant="outlined"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="form.lastName"
              :label="`${t('last_name')}*`"
              aria-required="true"
              variant="outlined"
            />
          </v-col>
        </v-row>

        <v-text-field
          v-model="form.email"
          :label="`${t('email')}*`"
          variant="outlined"
          aria-required="true"
          :error-messages="
            emailError ? [t('userManagement.email_already_exists')] : []
          "
        />
      </v-card-text>

      <v-card-actions class="justify-end">
        <v-btn variant="text" color="primary" @click="closeDialog">
          {{ t("cancel") }}
        </v-btn>
        <v-btn
          color="primary"
          variant="flat"
          rounded="lg"
          class="pa-2"
          @click="confirm"
          :disabled="!isValid || emailError"
        >
          {{ t("confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { useWebappUserLogic } from "@/views/admin/webapp-user/webapp-user-logic";
import {
  computed,
  defineProps,
  defineEmits,
  toRefs,
  watch,
  onBeforeUnmount,
  reactive,
  ref,
} from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps({
  modelValue: Boolean,
});

const { t } = useI18n();
const { modelValue } = toRefs(props);

const emit = defineEmits<{
  (e: "update:modelValue", value: boolean): void;
}>();

const { createWebappUser } = useWebappUserLogic();

const form = reactive({
  firstName: "",
  lastName: "",
  email: "",
});

const emailError = ref(false);
const previousEmail = ref("");
const emailThatCausedError = ref("");

const isValid = computed(
  () => form.firstName && form.lastName && form.email.includes("@"),
);

const resetData = () => {
  form.firstName = "";
  form.lastName = "";
  form.email = "";
};

const closeDialog = () => {
  resetData();
  emit("update:modelValue", false);
};

const handleKeyDown = (event: KeyboardEvent) => {
  if (event.key === "Escape") {
    closeDialog();
  }
};

const confirm = async () => {
  const errorOccurred = await createWebappUser(form);

  if (errorOccurred === "user_already_exists") {
    emailError.value = true;
    emailThatCausedError.value = form.email;
  } else {
    closeDialog();
  }
};

watch(
  () => form.email,
  newEmail => {
    if (emailError.value && newEmail !== previousEmail.value) {
      emailError.value = false;
    }

    if (emailThatCausedError.value && newEmail === emailThatCausedError.value) {
      emailError.value = true;
    }

    previousEmail.value = newEmail;
  },
);

watch(modelValue, newValue => {
  if (newValue) {
    document.addEventListener("keydown", handleKeyDown);
  } else {
    document.removeEventListener("keydown", handleKeyDown);
  }
});

onBeforeUnmount(() => {
  document.removeEventListener("keydown", handleKeyDown);
});
</script>

<template>
  <v-dialog max-width="700px">
    <v-card v-if="user && editableUser">
      <v-card-title class="text-center text-h5 font-weight-bold">
        {{
          $t("userManagement.edit_user", {
            name: `${user.firstName} ${user.lastName}`,
          })
        }}
      </v-card-title>

      <v-card-text>
        <v-row class="mb-4">
          <v-col cols="3" class="font-weight-medium" align-self="center">
            {{ $t("first_name") }}
          </v-col>
          <v-col cols="9">
            <v-text-field
              v-model="editableUser.firstName"
              hide-details
              variant="outlined"
            />
          </v-col>

          <v-col cols="3" class="font-weight-medium" align-self="center">
            {{ $t("last_name") }}
          </v-col>
          <v-col cols="9">
            <v-text-field
              v-model="editableUser.lastName"
              hide-details
              variant="outlined"
            />
          </v-col>

          <v-col cols="3" class="font-weight-medium" align-self="center">
            {{ $t("userManagement.terror_status") }}
          </v-col>
          <v-col cols="9">
            <v-select
              v-model="selectedTerrorStatus"
              :items="formattedTerrorStates"
              return-object
              hide-details
              variant="outlined"
            >
              <template #item="{ props: activatorProps, item }">
                <v-list-item
                  v-bind="activatorProps"
                  :disabled="item.raw.disabled"
                />
              </template>
            </v-select>
          </v-col>

          <v-col cols="3" class="font-weight-medium" align-self="center">
            {{ $t("userManagement.document_status") }}
          </v-col>
          <v-col cols="9">
            <v-select
              v-model="selectedDocumentStatus"
              :items="formattedDocumentStates"
              return-object
              hide-details
              variant="outlined"
            >
              <template #item="{ props: activatorProps, item }">
                <v-list-item
                  v-bind="activatorProps"
                  :disabled="item.raw.disabled"
                />
              </template>
            </v-select>
          </v-col>

          <v-col cols="3" class="font-weight-medium" align-self="center">
            {{ $t("userManagement.notes") }}
          </v-col>
          <v-col cols="9" class="font-weight-medium" align-self="center">
            <v-textarea variant="outlined" />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="justify-end">
        <v-btn color="primary" @click="cancelEdit">
          {{ $t("cancel") }}
        </v-btn>
        <v-btn color="primary" @click="submitEdit" :disabled="isSubmitDisabled">
          {{ $t("submit") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import {
  IspsTruckerChangeRequestTOTerrorStatusEnum,
  IspsTruckerChangeTODocumentStatusEnum,
  IspsTruckerTO,
  TruckerIdentificationTO,
} from "@/services/client/generated";
import { getLastArrayElement } from "@/utils/object-util";
import { computed, ref, watchEffect } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  user: IspsTruckerTO | null;
}>();

const emit = defineEmits<{
  (e: "submit", value: IspsTruckerTO): void;
  (e: "close"): void;
}>();

const { t } = useI18n();
const editableUser = ref<IspsTruckerTO | null>(
  JSON.parse(JSON.stringify(props.user)),
);

const validIdentificationStates: IspsTruckerChangeTODocumentStatusEnum[] = [
  IspsTruckerChangeTODocumentStatusEnum.ManuallyApproved,
  IspsTruckerChangeTODocumentStatusEnum.ManuallyDenied,
];

const validTerrorStates: IspsTruckerChangeRequestTOTerrorStatusEnum[] = [
  IspsTruckerChangeRequestTOTerrorStatusEnum.ManuallyApproved,
  IspsTruckerChangeRequestTOTerrorStatusEnum.ManuallyDenied,
];

const selectedTerrorStatus = computed({
  get() {
    const truckerIdentification = getLastArrayElement(
      editableUser.value?.truckerIdentification as TruckerIdentificationTO[],
    );

    const terrorStatus = truckerIdentification?.terrorStatus;

    if (!terrorStatus) {
      return {
        title: t("not_set"),
        value: undefined,
        disabled: true,
      };
    }

    return {
      title: t(`userManagement.terror_state.${terrorStatus.toLowerCase()}`),
      value: terrorStatus,
      disabled: false,
    };
  },
  set(newValue) {
    const truckerIdentification = getLastArrayElement(
      editableUser.value?.truckerIdentification as TruckerIdentificationTO[],
    );
    if (truckerIdentification) {
      truckerIdentification.terrorStatus = newValue.value ?? undefined;
    }
  },
});

const selectedDocumentStatus = computed({
  get() {
    const truckerIdentification = getLastArrayElement(
      editableUser.value?.truckerIdentification as TruckerIdentificationTO[],
    );

    const documentStatus = truckerIdentification?.documentStatus;

    if (!documentStatus) {
      return {
        title: t("not_set"),
        value: undefined,
        disabled: true,
      };
    }

    return {
      title: t(`userManagement.document_state.${documentStatus.toLowerCase()}`),
      value: documentStatus,
      disabled: false,
    };
  },
  set(newValue) {
    const truckerIdentification = getLastArrayElement(
      editableUser.value?.truckerIdentification as TruckerIdentificationTO[],
    );
    if (truckerIdentification) {
      truckerIdentification.documentStatus = newValue.value ?? undefined;
    }
  },
});

const formattedTerrorStates = computed(() => {
  const options: {
    title: string;
    value: IspsTruckerChangeRequestTOTerrorStatusEnum | undefined;
    disabled: boolean;
  }[] = validTerrorStates.map(state => ({
    title: t(`userManagement.terror_state.${state.toLowerCase()}`),
    value: state,
    disabled: false,
  }));

  if (editableUser.value) {
    const terrorStatus = getLastArrayElement(
      editableUser.value.truckerIdentification ?? [],
    )?.terrorStatus;
    if (
      terrorStatus ===
        IspsTruckerChangeRequestTOTerrorStatusEnum.ManuallyApproved ||
      terrorStatus === IspsTruckerChangeRequestTOTerrorStatusEnum.Ok
    ) {
      options.forEach(option => {
        if (
          option.value ===
            IspsTruckerChangeRequestTOTerrorStatusEnum.ManuallyApproved ||
          option.value === IspsTruckerChangeRequestTOTerrorStatusEnum.Ok
        ) {
          option.disabled = true;
        }
      });
    }
  }
  return options;
});

const formattedDocumentStates = computed(() => {
  const options: {
    title: string;
    value: IspsTruckerChangeTODocumentStatusEnum | undefined;
    disabled: boolean;
  }[] = validIdentificationStates.map(state => ({
    title: t(`userManagement.document_state.${state.toLowerCase()}`),
    value: state,
    disabled: false,
  }));

  if (editableUser.value) {
    const documentStatus = getLastArrayElement(
      editableUser.value.truckerIdentification ?? [],
    )?.documentStatus;
    if (
      documentStatus ===
        IspsTruckerChangeTODocumentStatusEnum.ManuallyApproved ||
      documentStatus === IspsTruckerChangeTODocumentStatusEnum.Accepted
    ) {
      options.forEach(option => {
        if (
          option.value ===
            IspsTruckerChangeTODocumentStatusEnum.ManuallyApproved ||
          option.value === IspsTruckerChangeTODocumentStatusEnum.Accepted
        ) {
          option.disabled = true;
        }
      });
    }
  }
  return options;
});

const isSubmitDisabled = computed(() => {
  if (!editableUser.value || !props.user) return true;
  return (
    editableUser.value.firstName === props.user.firstName &&
    editableUser.value.lastName === props.user.lastName &&
    getLastArrayElement(editableUser.value.truckerIdentification ?? [])
      ?.terrorStatus ===
      getLastArrayElement(props.user.truckerIdentification ?? [])
        ?.terrorStatus &&
    getLastArrayElement(editableUser.value.truckerIdentification ?? [])
      ?.documentStatus ===
      getLastArrayElement(props.user.truckerIdentification ?? [])
        ?.documentStatus
  );
});

const submitEdit = () => {
  if (!editableUser.value) {
    return;
  }
  emit("submit", editableUser.value);
};

const cancelEdit = () => {
  emit("close");
  editableUser.value = JSON.parse(JSON.stringify(props.user));
};

watchEffect(() => {
  editableUser.value = JSON.parse(JSON.stringify(props.user));
});
</script>

import { useLicenseManagementStore } from "@/store/useLicenseManagementStore";
import { computed, ref, watch, onBeforeUnmount } from "vue";
import {
  ClaimedLicense,
  ClaimLicenseError,
  License,
  LicenseActionError,
  LicenseFilter,
  TakeoverLicenseError,
} from "@/models/license-management";
import { useDialog } from "@/composables/useDialog";
import { VirtualTableLoadMoreStatus } from "@/models/virtual-table";
import { formatDate } from "@/utils/date-utils";
import { useAuthStore } from "@/store/useAuthStore";
import { useForwarderStore } from "@/store/useForwarderStore";
import { useForwarderOperatorStore } from "@/store/useForwarderOperatorStore";
import { usePurchaseStatus } from "@/composables/usePurchaseStatus";
import { useI18n } from "vue-i18n";
import {
  getClaimError,
  getLicenseError,
} from "@/utils/license-management/license-utils";
import { AxiosError } from "axios";
import { TruckerForForwarderTO } from "@/services/client/generated";
import { useLocalRoute } from "@/composables/useLocalRoute";
import { useSnackbar } from "@/composables/useSnackbar";

export function useLicenseManagementLogic() {
  const i18n = useI18n();
  const store = useLicenseManagementStore();
  const filterDialog = useDialog();
  const revokeDialog = useDialog<License>({ noSubmitClose: true });
  const purchaseDialog = useDialog({ noSubmitClose: true });
  const assignDialog = useDialog<License>({ noSubmitClose: true });
  const takeoverDialog = useDialog<License>({ noSubmitClose: true });
  const stopRenewalDialog = useDialog<License>({ noSubmitClose: true });
  const claimLicenseDialog = useDialog<null, TakeoverLicenseError>({
    noSubmitClose: true,
    onClose: handleCloseClaimDialog,
    onCancel: handleCloseClaimDialog,
  });
  const authStore = useAuthStore();
  const forwarderStore = useForwarderStore();
  const forwarderOperatorStore = useForwarderOperatorStore();
  const { showSuccessMessage, showErrorMessage } = useSnackbar();
  const { getOrigin, goToExternalRoute } = useLocalRoute();
  usePurchaseStatus({
    successPurchaseCallback: () =>
      showSuccessMessage(i18n.t("you_purchased_license")),
    successPaySetupFeeCallback: () =>
      showSuccessMessage(i18n.t("license_setup_fee_successful")),
  });

  const searchTerm = ref<string>("");
  const filter = ref<LicenseFilter>({
    status: [],
    managedBy: [],
    expiresBefore: null,
  });
  const page = ref<number>(0);
  const truckersPage = ref<number>(0);
  const infinityScrollControl = ref<
    ((status: VirtualTableLoadMoreStatus) => void) | null
  >(null);
  const truckersInfinityScrollControl = ref<
    ((status: VirtualTableLoadMoreStatus) => void) | null
  >(null);
  const truckersLoading = ref<boolean>(false);
  const claimedLicenses = ref<Array<ClaimedLicense>>();

  const licenses = computed(() => store.licenses);
  const truckersForForwarder = computed(
    () => forwarderStore.truckersForForwarder,
  );

  watch(
    () => forwarderStore.getForwarder()?.id,
    async () => {
      await getTruckersForForwarder();
    },
  );

  watch(
    () => forwarderStore.getForwarder()?.id,
    async () => {
      resetLicenseTable();
    },
  );

  async function fetchLicenses(
    tableControl?: ((status: VirtualTableLoadMoreStatus) => void) | null,
  ) {
    tableControl?.("loading");
    try {
      const userId: string = authStore.authUser?.userId ?? "";
      const forwarderId: number = forwarderStore.getForwarder()?.id ?? 0;
      const status = filter.value.status.map(status => status.value);
      const managedBy = filter.value.managedBy.map(
        managedBy => managedBy.value,
      );
      const expiresBefore = filter.value.expiresBefore
        ? formatDate(filter.value.expiresBefore.toISOString(), "yyyy-MM-dd")
        : undefined;
      const result = await store.fetchLicenses({
        userId,
        forwarderId,
        status,
        managedBy,
        page: page.value,
        searchTerm: searchTerm.value,
        expiresBefore,
      });

      if (result.length === 0) {
        tableControl?.("empty");
      } else {
        tableControl?.("ok");
      }
    } catch (err) {
      tableControl?.("error");
      throw err;
    }
  }

  async function resetLicenseTable() {
    page.value = 0;
    await fetchLicenses(infinityScrollControl.value);
    page.value = page.value + 1;
  }

  async function getTruckersForForwarder() {
    try {
      truckersInfinityScrollControl.value?.("loading");
      const userId: string = authStore.authUser?.userId ?? "";
      const forwarderId: number = forwarderStore.getForwarder()?.id ?? 0;
      const page = truckersPage.value;
      const result = await forwarderStore.getTruckersForForwarder({
        userId,
        forwarderId,
        page,
        size: 50,
        forAssignment: true,
      });
      if (result.length === 0) {
        truckersInfinityScrollControl.value?.("empty");
      } else {
        truckersInfinityScrollControl.value?.("ok");
      }
    } finally {
      truckersLoading.value = false;
    }
  }

  async function handleFilterApplyClick() {
    await resetLicenseTable();
  }

  async function handleSearch(value: string) {
    searchTerm.value = value;
    await resetLicenseTable();
  }

  async function handleSearchTruckers() {
    truckersPage.value = 0;
    await getTruckersForForwarder();
  }

  function handleFilterClick(): void {
    filterDialog.open();
  }

  function handleClaimLicenseClick() {
    claimLicenseDialog.open();
  }

  function handleAssignClick(license: License): void {
    truckersPage.value = 0;
    forwarderStore.clearTruckersForForwarder();
    assignDialog.open(license);
  }

  function handlePurchaseClick() {
    purchaseDialog.open();
  }

  async function handleLoadMore(
    done: (status: VirtualTableLoadMoreStatus) => void,
  ) {
    if (!infinityScrollControl.value) {
      infinityScrollControl.value = done;
    }
    await fetchLicenses(done);
    page.value = page.value + 1;
  }

  async function handleLoadMoreTruckers(
    done: (status: VirtualTableLoadMoreStatus) => void,
  ) {
    truckersInfinityScrollControl.value = done;
    await getTruckersForForwarder();
    truckersPage.value = truckersPage.value + 1;
  }

  async function handlePurchaseSubmitClick(quantity: number) {
    try {
      purchaseDialog.setIsLoading(true);
      const userId: string = authStore.authUser?.userId ?? "";
      const forwarderId: number = forwarderStore.getForwarder()?.id ?? 0;
      const email: string = authStore.authUser?.userData?.email ?? "";
      const response = await forwarderOperatorStore.purchaseLicenses({
        quantity: +quantity,
        userId,
        forwarderId,
        companyEmail: email,
        successUrl: `${getOrigin()}/forwarderOperator/licenseManagement?purchaseSuccess=true`,
      });
      if (response.data.url) goToExternalRoute(response.data.url);
    } catch (error) {
      const purchaseError = getLicenseError(
        error as AxiosError<LicenseActionError>,
      );
      if (purchaseError) {
        purchaseDialog.setError(purchaseError);
      }
    } finally {
      purchaseDialog.setIsLoading(false);
    }
  }

  async function handleClaimLicenseSubmit(value: string) {
    try {
      claimLicenseDialog.setIsLoading(true);
      const userId: string = authStore.authUser?.userId ?? "";
      const forwarderId: number = forwarderStore.getForwarder()?.id ?? 0;
      const companyEmail: string = authStore.authUser?.userData?.email ?? "";
      claimedLicenses.value = await store.claimLicenses({
        key: value,
        userId,
        forwarderId,
        companyEmail,
      });
      await resetLicenseTable();
    } catch (error) {
      const claimError = getClaimError(error as AxiosError<ClaimLicenseError>);
      if (claimError) {
        claimLicenseDialog.setError(claimError);
      }
      throw error;
    } finally {
      claimLicenseDialog.setIsLoading(false);
    }
  }

  async function handleAssignLicense(payload: {
    trucker: TruckerForForwarderTO;
    license: License;
  }) {
    try {
      assignDialog.setIsLoading(true);
      const userId: string = authStore.authUser?.userId ?? "";
      const forwarderId: number = forwarderStore.getForwarder()?.id ?? 0;
      const licenseId = payload.license.id;
      const truckerId = payload.trucker.userId;
      if (licenseId && truckerId)
        await store.triggerLicenseAction({
          userId,
          forwarderId,
          licenseId,
          action: "ASSIGN",
          truckerId,
        });
      assignDialog.close();
      await showSuccessMessage(i18n.t("license_successfully_assigned"));
    } catch (error) {
      const assignError = getLicenseError(
        error as AxiosError<LicenseActionError>,
      );
      if (assignError) {
        assignDialog.setError(assignError);
      }
      throw error;
    } finally {
      assignDialog.setIsLoading(false);
    }
  }

  function handleCloseClaimDialog() {
    claimedLicenses.value = undefined;
  }

  function handleRevokeClick(value: License) {
    revokeDialog.open(value);
  }

  async function handleRevokeSubmit(value: License) {
    try {
      const userId: string = authStore.authUser?.userId ?? "";
      const forwarderId: number = forwarderStore.getForwarder()?.id ?? 0;
      revokeDialog.setIsLoading(true);
      if (value.id)
        await store.triggerLicenseAction({
          userId,
          forwarderId,
          licenseId: value.id,
          action: "REVOKE",
        });
      revokeDialog.close();
      await showSuccessMessage(i18n.t("license_successfully_revoked"));
    } catch (error) {
      const revokeError = getLicenseError(
        error as AxiosError<LicenseActionError>,
      );
      if (revokeError) {
        revokeDialog.setError(revokeError);
      }
      throw error;
    } finally {
      revokeDialog.setIsLoading(false);
    }
  }

  function handleTakeoverClick(value: License) {
    takeoverDialog.open(value);
  }

  async function handleTakeoverSubmit(value: License) {
    try {
      const userId: string = authStore.authUser?.userId ?? "";
      const forwarderId: number = forwarderStore.getForwarder()?.id ?? 0;
      if (value.id) {
        takeoverDialog.setIsLoading(true);
        await store.takeoverLicense({
          userId,
          forwarderId,
          licenseId: value.id,
        });
        takeoverDialog.close();
        await showSuccessMessage(i18n.t("license_takeover_successful"));
      }
    } catch (error) {
      const takeoverError = getLicenseError(
        error as AxiosError<TakeoverLicenseError>,
      );
      if (takeoverError) {
        takeoverDialog.setError(takeoverError);
      }
    } finally {
      takeoverDialog.setIsLoading(false);
    }
  }

  async function handlePaySetupFee(value: License) {
    try {
      const userId: string = authStore.authUser?.userId ?? "";
      const forwarderId: number = forwarderStore.getForwarder()?.id ?? 0;
      if (value.licenseKey) {
        const response = await store.paySetupFee({
          userId,
          forwarderId,
          licenseKey: value.licenseKey,
          successUrl: `${getOrigin()}/forwarderOperator/licenseManagement?purchaseSuccess=true`,
        });
        if (response.url) goToExternalRoute(response.url);
      }
    } catch (error) {
      const paySetupFeeError = getLicenseError(
        error as AxiosError<TakeoverLicenseError>,
      );
      if (paySetupFeeError) {
        await showErrorMessage(i18n.t(paySetupFeeError.code));
      }
    }
  }

  function handleStopRenewal(value: License) {
    stopRenewalDialog.open(value);
  }

  async function handleStopRenewalSubmit(value: License) {
    try {
      const userId: string = authStore.authUser?.userId ?? "";
      const forwarderId: number = forwarderStore.getForwarder()?.id ?? 0;
      if (value.id) {
        stopRenewalDialog.setIsLoading(true);
        await store.stopRenewal({
          userId,
          forwarderId,
          licenseId: value.id,
        });
        stopRenewalDialog.close();
        await showSuccessMessage(i18n.t("license_renewal_stopped"));
      }
    } catch (error) {
      const stopRenewalError = getLicenseError(
        error as AxiosError<TakeoverLicenseError>,
      );
      if (stopRenewalError) {
        stopRenewalDialog.setError(stopRenewalError);
      }
    } finally {
      stopRenewalDialog.setIsLoading(false);
    }
  }

  onBeforeUnmount(() => {
    store.clearLicenses();
  });

  return {
    page,
    licenses,
    truckersForForwarder,
    claimedLicenses,
    loadingFlags: store.loadingFlags,
    truckersLoading,
    fetchLicenses: store.fetchLicenses,
    filter,
    filterDialog,
    purchaseDialog,
    assignDialog,
    takeoverDialog,
    claimLicenseDialog,
    revokeDialog,
    stopRenewalDialog,
    handleFilterClick,
    handleClaimLicenseClick,
    handlePurchaseClick,
    handleAssignClick,
    handleRevokeClick,
    handleRevokeSubmit,
    handleSearch,
    handleSearchTruckers,
    handleLoadMore,
    handleLoadMoreTruckers,
    handlePurchaseSubmitClick,
    handleClaimLicenseSubmit,
    handleFilterApplyClick,
    handleAssignLicense,
    handleTakeoverClick,
    handleTakeoverSubmit,
    handlePaySetupFee,
    handleStopRenewal,
    handleStopRenewalSubmit,
  };
}

<template>
  <TheDialog
    max-width="600px"
    :title="t('filtering_settings')"
    :submit-text="t('apply_filters')"
    :dialog="dialog"
    @onSubmit="handleApply"
  >
    <template #text>
      <div class="LicenseManagementFilters">
        <TheDropdown
          density="compact"
          :label="t('license_status')"
          :items="licenseStatusItems"
          :default-value="licenseStatus"
          :placeholder="t('select_status')"
          item-value="value"
          item-title="text"
          outlined
          multiple
          return-object
          @change-item="handleChangeLicenseStatus"
        >
          <template #selection="{ props: selectionProps }">
            <div v-if="selectionProps.index === 0">
              {{ licenseStatus.length }}
              <span class="text-lowercase">selected</span>
            </div>
          </template>
        </TheDropdown>

        <TheDropdown
          density="compact"
          :label="t('managed_by')"
          :items="managedByItems"
          :default-value="managedBy"
          :placeholder="t('select_managers')"
          item-value="value"
          item-title="text"
          outlined
          multiple
          return-object
          @change-item="handleChangeManagedBy"
        >
          <template #selection="{ props: selectionProps }">
            <div v-if="selectionProps.index === 0">
              {{ managedBy.length }}
              <span class="text-lowercase">selected</span>
            </div>
          </template>
        </TheDropdown>

        <TheDateInput v-model="expiresBefore" :label="t('expires_before')" />
      </div>
    </template>
  </TheDialog>
</template>

<script setup lang="ts">
import { computed, ref, watchEffect } from "vue";
import TheDropdown from "@/components/TheDropdown.vue";
import { useI18n } from "vue-i18n";
import {
  LicenseStatusItem,
  LicenseManagedByItem,
  LicenseFilter,
} from "@/models/license-management";
import TheDateInput from "@/components/TheDateInput.vue";
import TheDialog from "@/components/dialogs/TheDialog.vue";
import { UseDialogReturn } from "@/composables/useDialog";

interface Props {
  dialog: UseDialogReturn;
}
defineProps<Props>();
const emits = defineEmits(["onApply"]);
const { t } = useI18n();

const licenseStatusItems = computed<LicenseStatusItem[]>(() => {
  return [
    {
      text: t("unassigned"),
      value: "UNASSIGNED",
    },
    {
      text: t("setup_fee"),
      value: "UNASSIGNED_SETUP_FEE_REQUIRED",
    },
    {
      text: t("assigned"),
      value: "ASSIGNED",
    },
    {
      text: t("expired"),
      value: "EXPIRED",
    },
    {
      text: t("disabled"),
      value: "DISABLED",
    },
  ];
});
const managedByItems = computed<LicenseManagedByItem[]>(() => {
  return [
    {
      text: t("me"),
      value: "ME",
    },
    {
      text: t("others"),
      value: "OTHERS",
    },
  ];
});

const filter = defineModel<LicenseFilter>({ required: true });
const licenseStatus = ref<LicenseStatusItem[]>([]);
const managedBy = ref<LicenseManagedByItem[]>([]);
const expiresBefore = ref<Date | null>(null);

function handleChangeLicenseStatus({ value }: { value: LicenseStatusItem[] }) {
  licenseStatus.value = value;
}

function handleChangeManagedBy({ value }: { value: LicenseManagedByItem[] }) {
  managedBy.value = value;
}

function handleApply() {
  filter.value = {
    status: licenseStatus.value,
    managedBy: managedBy.value,
    expiresBefore: expiresBefore.value,
  };
  emits("onApply");
}

watchEffect(() => {
  licenseStatus.value = filter.value.status;
  managedBy.value = filter.value.managedBy;
  expiresBefore.value = filter.value.expiresBefore;
});
</script>

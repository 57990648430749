import {
  isLicense,
  isLicenseOrderId,
} from "@/utils/license-management/license-utils";

export const ruleRequired = (message: string) => (value: string) => {
  if (value) return true;
  return message;
};

export const ruleLicenseFormat = (message: string) => (value: string) => {
  return isLicense(value) || message;
};

export const ruleOrderIdFormat = (message: string) => (value: string) => {
  return isLicenseOrderId(value) || message;
};

export const ruleLicenseOrOrderId = (message: string) => (value: string) => {
  const licenseFormat = ruleLicenseFormat(message);
  const orderIdFormat = ruleOrderIdFormat(message);
  return (
    licenseFormat(value) === true || orderIdFormat(value) === true || message
  );
};

<template>
  <svg
    width="28"
    height="29"
    viewBox="0 0 28 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1461_1355)">
      <path
        d="M13.9999 2.73315C7.55992 2.73315 2.33325 7.95982 2.33325 14.3998C2.33325 20.8398 7.55992 26.0665 13.9999 26.0665C20.4399 26.0665 25.6666 20.8398 25.6666 14.3998C25.6666 7.95982 20.4399 2.73315 13.9999 2.73315ZM19.8333 15.5665H8.16659V13.2332H19.8333V15.5665Z"
        fill="#FDFDFD"
      />
    </g>
    <defs>
      <clipPath id="clip0_1461_1355">
        <rect
          width="28"
          height="28"
          fill="white"
          transform="translate(-0.000244141 0.399658)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
.cls-1 {
  fill: #14005c;
}
</style>

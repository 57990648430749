import { onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";

export function usePurchaseStatus({
  successPurchaseCallback,
  successPaySetupFeeCallback,
}: {
  successPurchaseCallback?: () => void;
  successPaySetupFeeCallback?: (key: string) => void;
}) {
  const route = useRoute();
  const router = useRouter();

  function removeFromQuery() {
    const query = { ...route.query };
    delete query.purchaseSuccess;
    delete query.successSetupFee;
    router.replace({ query });
  }

  onMounted(() => {
    if (route.query.purchaseSuccess === "true") {
      successPurchaseCallback?.();
    }

    if (route.query.successSetupFee) {
      successPaySetupFeeCallback?.(route.query.successSetupFee as string);
    }

    removeFromQuery();
  });
}
